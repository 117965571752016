import GeneralIcon from "../assets/general-icon.svg";
import GuideIcon from "../assets/guide-icon.svg";
import NewsIcon from "../assets/news-icon.svg";
import InvestigationIcon from "../assets/investigation-icon.svg";
import ProfileIcon from "../assets/profile-icon.svg";
import FeatureIcon from "../assets/feature-icon.svg";
import OpEdIcon from "../assets/op-ed-icon.svg";
import ExplanatoryIcon from "../assets/explanatory-icon.svg";

export const CATEGORY_LIST_ICON = [
  {
    id: 1,
    icon: GeneralIcon
  },
  {
    id: 2,
    icon: InvestigationIcon
  },
  {
    id: 3,
    icon: NewsIcon
  },
  {
    id: 4,
    icon: FeatureIcon
  },
  {
    id: 5,
    icon: OpEdIcon
  },
  {
    id: 6,
    icon: ProfileIcon
  },
  {
    id: 7,
    icon: ExplanatoryIcon
  },
  {
    id: 8,
    icon: GuideIcon
  }
];

export const TAB_LIST_DASHBOARD = [
  {
    id: 1,
    name: "Articles",
    value: "articles"
  },
  {
    id: 2,
    name: "Requests",
    value: "requests"
  },
  {
    id: 3,
    name: "Submissions",
    value: "submissions"
  },
  {
    id: 4,
    name: "Profile",
    value: "profile"
  }
];
