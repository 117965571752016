import { Helmet } from "react-helmet";
import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../../assets/arrow-left-white-icon.svg";

import { useEffect, useState } from "react";
import { fetchTopics } from "../../apis/article.api";

import ArticleContainer from "../../components/ArticleContainer";
import TopicContainer from "../../components/TopicContainer";
import { useGetListArticleByFilter } from "../../hooks/useGetListArticleByFilter";

const RecommendTopicsPage = () => {
  const [searchParams] = useSearchParams();
  const [topicLabels, setTopicLabels] = useState([]);
  const [currentTopicId, setCurrentTopicId] = useState(Number(searchParams.get("topicId")));

  const navigate = useNavigate();

  const getTotalTopic = (amount) => {
    return amount;
  };

  const getTopicNameById = (topicId) => {
    return topicLabels.find((data) => data.id === topicId)?.name;
  };

  const { loading, listArticle, total } = useGetListArticleByFilter({
    topic: currentTopicId
  });

  useEffect(() => {
    const fetchTopicLabels = async () => {
      try {
        const result = await fetchTopics();
        setTopicLabels(result);
      } catch (error) {
        console.log(error);
      }
    };
    fetchTopicLabels();
  }, []);

  return (
    <div>
      <Helmet>
        <title>{getTopicNameById(currentTopicId)}</title>
      </Helmet>
      <div className="sticky top-[40px] md:top-[70px] bg-white z-20 px-4 md:px-0 py-1 md:py-4">
        <TopicContainer
          topicLabels={topicLabels}
          currentTopicId={currentTopicId}
          setCurrentTopicId={setCurrentTopicId}
        />
      </div>
      <div className="w-full flex flex-start flex-col px-4 md:px-0 relative">
        <div className="flex sticky top-[102px] md:top-[156px] pt-2 md:pt-6 z-20 bg-white">
          <div
            className="p-1 md:p-2 rounded-[80px] h-fit bg-[#4CAF50] mt-1 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              className="max-w-5 max-h-5 min-w-5 min-h-5 md:max-w-10 md:max-h-10 md:min-w-10 md:min-h-10"
              src={ArrowLeftIcon}
              alt=""
            />
          </div>
          <div className="w-full mr-[28px] md:mr-[56px]">
            <p className="text-center text-[24px] md:text-[40px] font-[800] text-[#4CAF50]">
              {getTopicNameById(currentTopicId)}
            </p>
            <p className="text-sm md:text-[24px] text-center font-[700] text-[#808080] mt-2 md:mt-[18px]">
              {getTotalTopic(total)} Stories
            </p>
          </div>
        </div>
        <div className="max-w-[1072px] w-full mx-auto px-0 md:px-5 flex mt-[24px] md:mt-[60px]">
          <ArticleContainer loading={loading} listArticle={listArticle} isHiddenFeaturedArticles />
        </div>
      </div>
    </div>
  );
};

export default RecommendTopicsPage;
