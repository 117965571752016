import NoResultImg from "../../../assets/no-result.svg";
import Button from "../../Button";

const SearchNoResultComponent = ({
  result,
  typeSearchText,
  titleRedirect,
  titleBtn,
  handleChangeTab
}) => {
  return (
    <div className="flex flex-col gap-4 items-center">
      <img className="w-[240px] md:w-[360px]" src={NoResultImg} alt="" />
      <p className="font-[600] text-[24px] md:text-[28px] text-center">
        Oops! Looks like we don’t have any {typeSearchText} on{" "}
        <span className="text-[#4CAF50] font-[900] text-[28px]"> {result} </span> just yet.
      </p>
      <div className="flex flex-col items-center">
        <p className="text-[24px] md:text-[28px] text-center">
          Try searching for something else, or {titleRedirect}
        </p>
        <Button variant="secondary" className="w-fit mt-2" onClick={handleChangeTab}>
          {titleBtn}
        </Button>
      </div>
    </div>
  );
};

export default SearchNoResultComponent;
