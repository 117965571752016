import { useEffect, useRef } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { Textarea } from "@chakra-ui/react";
import { FieldHookForm } from "../hook-form/field-hook-form";

const TextAreaHookForm = (props) => {
  const {
    isOnKeyword,
    isOptional,
    description,
    label,
    name,
    fontSizeError,
    isHiddenBottomContent,
    bottomContentProps,
    isHiddenError,
    bottomRightContent,
    colorDes,
    ...rest
  } = props;
  const {
    formState: { errors },
    control
  } = useFormContext();

  const textareaRef = useRef(null);

  const adjustHeight = () => {
    textareaRef.current.style.height = "";
    const newHeight = Math.min(textareaRef.current.scrollHeight + 1, 120); // 32 55 78
    textareaRef.current.style.height = `${newHeight}px`;
  };

  useEffect(() => {
    adjustHeight();
  }, [control]);

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldHookForm
          name={name}
          description={description}
          label={label}
          isOptional={isOptional}
          fontSizeError={fontSizeError}
          isHiddenBottomContent={isHiddenBottomContent}
          bottomContentProps={bottomContentProps}
          isHiddenError={isHiddenError}
          bottomRightContent={bottomRightContent}
          colorDes={colorDes}
        >
          <Textarea
            {...field}
            ref={(e) => {
              textareaRef.current = e;
              field.ref(e);
            }}
            onChange={(e) => {
              adjustHeight();
              field.onChange(e);
            }}
            fontSize={{ base: "sm", "2xl": "md" }}
            p={"1.125rem 1.5rem"}
            rounded={"0.75rem"}
            minHeight={"unset"}
            resize={"none"}
            borderWidth={"px"}
            borderColor={errors?.[name] ? "other.200" : "light-gray"}
            color={"jet-black"}
            bg={"unset"}
            placeholder={{
              color: "dim-gray",
              fontSize: { base: "sm", "2xl": "md" }
            }}
            lineHeight={"1.25rem"}
            name={name}
            _hover={{
              borderColor: errors?.[name] ? "other.200 " : "inherit"
            }}
            _focus={{
              boxShadow: "none"
            }}
            _focusVisible={{
              borderColor: errors?.[name] ? "other.200" : "twygs-green",
              borderWidth: "1.5px"
            }}
            {...rest}
          />
        </FieldHookForm>
      )}
    ></Controller>
  );
};

TextAreaHookForm.displayName = "TextAreaHookForm";

export { TextAreaHookForm };
