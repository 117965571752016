/* eslint-disable no-unused-vars */
import React, { Fragment } from "react";
import { useTable } from "react-table";
import { Box, Spinner, Table, Tbody, Td, Text, Tfoot, Th, Thead, Tr } from "@chakra-ui/react";
import { ReactComponent as SortIcon } from "../../assets/sort-icon-table.svg";

const TableComponent = (props) => {
  const {
    data,
    columns,
    isLoading,
    headerHeight,
    fontsizeTable,
    haveFooter = true,
    handleSort,
    currentSort
  } = props;
  const { getTableProps, getTableBodyProps, headerGroups, footerGroups, rows, prepareRow } =
    useTable({
      columns,
      data
    });

  return (
    <Fragment>
      {isLoading && data?.length === 0 ? (
        <Box
          minH={"300px"}
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          bg={"white"}
        >
          <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="#4CAF50" size="xl" />
        </Box>
      ) : (
        <Fragment>
          <Table {...getTableProps()} position={"relative"}>
            <Thead bg={"white"} border={"0px"}>
              {headerGroups.map((headerGroup, index) => (
                <Tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, indexTr) => {
                    const isAsc = currentSort?.sortBy === column?.id && currentSort?.sort === "asc";
                    const rotation = isAsc ? "-180deg" : "0deg";
                    return (
                      <Th
                        {...column.getHeaderProps()}
                        key={indexTr}
                        height={headerHeight ?? "56px"}
                        textTransform={"unset"}
                        whiteSpace={"nowrap"}
                        border={0}
                        fontWeight={"800"}
                        fontSize={fontsizeTable ?? "1rem"}
                        p={"12px"}
                        _first={{
                          pl: "24px",
                          pr: "12px"
                        }}
                        _last={{
                          pr: "24px",
                          pl: "12px"
                        }}
                        bg={"anti-flash-lite"}
                        // minW={column?.minWidthCustom ?? "unset"}
                        w={column?.widthCustom ?? "unset"}
                      >
                        <Box
                          display={"flex"}
                          alignItems={"center"}
                          cursor={column?.isSortField ? "pointer" : "default"}
                          onClick={() => {
                            if (handleSort && column?.isSortField) {
                              handleSort(column?.id);
                            }
                          }}
                        >
                          <Text
                            fontSize={fontsizeTable ?? "0.75rem"}
                            color={"#333333"}
                            fontFamily={"Nunito"}
                            fontWeight={800}
                          >
                            {column.render("Header")}
                          </Text>
                          {column?.isSortField ? (
                            <SortIcon
                              className="ml-[8px] transition-all ease duration-300"
                              style={{ transform: `rotate(${rotation})` }}
                              alt=""
                            />
                          ) : null}
                        </Box>
                      </Th>
                    );
                  })}
                </Tr>
              ))}
            </Thead>
            {isLoading && data?.length > 0 ? (
              <Fragment>
                <Box
                  minH={"350px"}
                  h={`${data?.length * 52}px`}
                  display={"flex"}
                  alignItems={"center"}
                  justifyContent={"center"}
                  position={"absolute"}
                  left={"50%"}
                  transform={"translateX(-50%)"}
                  bg={"white"}
                  w={"100%"}
                >
                  <Spinner
                    thickness="4px"
                    speed="0.65s"
                    emptyColor="gray.200"
                    color="#4CAF50"
                    size="xl"
                  />
                </Box>
              </Fragment>
            ) : null}
            {!isLoading ? (
              <Fragment>
                <Tbody {...getTableBodyProps()}>
                  {rows.map((row, index) => {
                    prepareRow(row);
                    return (
                      <Tr
                        {...row.getRowProps()}
                        key={index}
                        background={row?.original?.isDelete ? "light.300" : "inherit"}
                      >
                        {row.cells.map((cell, indexTr) => (
                          <Td
                            {...cell.getCellProps()}
                            key={indexTr}
                            fontSize={fontsizeTable ?? "0.75rem"}
                            borderTop={"1px solid #E6E7EB"}
                            borderBottom={"1px solid #E6E7EB"}
                            _first={{
                              borderLeft: "0px",
                              pl: "24px",
                              pr: "12px"
                            }}
                            _last={{
                              borderRight: "0px",
                              pr: "24px",
                              pl: "12px"
                            }}
                            p={"16px 12px"}
                            fontWeight={cell?.column?.fontWeightCustom ?? "normal"}
                            minW={cell?.column?.minWidthCustom ?? "unset"}
                            maxW={cell?.column?.widthCustom ?? "unset"}
                          >
                            {cell.render("Cell")}
                          </Td>
                        ))}
                      </Tr>
                    );
                  })}
                </Tbody>
                {haveFooter ? (
                  <Tfoot>
                    {footerGroups.map((footerGroups, index) => (
                      <Tr {...footerGroups.getFooterGroupProps()} key={index}>
                        {footerGroups.headers.map((column, indexTr) => (
                          <Td
                            {...column.getFooterProps()}
                            key={indexTr}
                            height={"56px"}
                            textTransform={"unset"}
                            border={0}
                            borderBottom={"1px solid #EAECF0"}
                            fontSize={fontsizeTable ?? "0.75rem"}
                            fontWeight={indexTr === 0 ? "800" : "400"}
                            p={"16px 14px"}
                            bg={"anti-flash-lite"}
                          >
                            {column.render("Footer")}
                          </Td>
                        ))}
                      </Tr>
                    ))}
                  </Tfoot>
                ) : null}
              </Fragment>
            ) : null}
          </Table>
        </Fragment>
      )}
    </Fragment>
  );
};

export default TableComponent;
