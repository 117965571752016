import { Outlet } from "react-router-dom";
import HeaderComponent from "../../Header";
import Footer from "../../Footer";

const NewsletterLayout = () => {
  return (
    <div className="newsletter-layout max-w-[1920px] m-auto">
      <HeaderComponent />
      <div className="mt-[56px] md:mt-[92px] px-0 md:px-[50px] 2xl:px-[100px] mb-[80px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
};

export default NewsletterLayout;
