import { useNavigate, useSearchParams } from "react-router-dom";
import ArrowLeftIcon from "../../assets/arrow-left-white-icon.svg";
import { useEffect, useState } from "react";
import { fetchSearch } from "../../apis/article.api";
import CardArticle from "../../components/DiscoveryComponent/LeftContentComponent/components/card-article.component";
import GoogleAd from "../../components/GoogleAds";
import { Spinner } from "@chakra-ui/react";
import CardArticleLoading from "../../components/DiscoveryComponent/LeftContentComponent/components/card-article-loading.component";
import { followUser, unfollowUser } from "../../apis/discovery.api";
import { Helmet } from "react-helmet";
import { useAtomValue } from "jotai";
import { profileAtom } from "../../components/Settings/atom";
import CardAuthor from "../../components/DiscoveryComponent/RightContentComponent/components/card-author.component";
import SearchNoResultComponent from "../../components/SearchResult/NoResultComponent";

const resultSearchType = [
  {
    name: "Authors",
    value: "creators"
  },
  {
    name: "Articles",
    value: "articles"
  }
];

const SearchResult = () => {
  const [searchParams] = useSearchParams();
  const [searchResult, setSearchResult] = useState([]);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    entity: "creators",
    search: searchParams.get("q"),
    totalPage: 0
  });
  const [loading, setLoading] = useState(true);
  const [isSwitching, setIsSwitching] = useState(false);
  const [loadingAuthorId, setLoadingAuthorId] = useState(null);
  const navigate = useNavigate();
  const profile = useAtomValue(profileAtom);

  const handleLoadMore = () => {
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    if (scrolled >= scrollable - 200 && !loading && pagination.page < pagination.totalPage) {
      setLoading(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1
      }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleLoadMore);
    return () => {
      window.removeEventListener("scroll", handleLoadMore);
    };
  });

  const renderContentWithAds = () => {
    const contentWithAds = [];
    searchResult.forEach((item, index) => {
      contentWithAds.push(
        <CardArticle
          key={`article-${index}`}
          index={index}
          item={{
            ...item,
            nameCreator: `${item?.creator?.firstName} ${item?.creator?.lastName}`,
            imgUrl: item?.creator?.imgUrl
          }}
        />
      );
      if ((index + 1) % 4 === 0) {
        contentWithAds.push(
          <GoogleAd key={`ad-${index}`} slot="3329737237" className="my-custom-ad !z-10" />
        );
      }
    });
    return contentWithAds;
  };

  const handleFollowUser = async (id, isCurrentlyFollowing) => {
    try {
      setLoadingAuthorId(id);
      if (isCurrentlyFollowing) {
        await unfollowUser(id);
      } else {
        await followUser(id);
      }
      setSearchResult((prevResults) =>
        prevResults.map((user) =>
          user.id === id ? { ...user, isFollowing: !isCurrentlyFollowing } : user
        )
      );
    } catch (error) {
      console.error("Error updating follow status:", error);
    } finally {
      setLoadingAuthorId(null);
    }
  };

  const handleChangeTab = (item) => {
    setIsSwitching(true);
    setLoading(true);
    setPagination({ ...pagination, entity: item.value });
  };

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      search: searchParams.get("q") || "",
      page: 1
    }));
  }, [searchParams, pagination.entity]);

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const result = await fetchSearch(pagination, profile.id);
        if (result) {
          setSearchResult((prevList) =>
            pagination.page === 1 ? result.data : [...prevList, ...result.data]
          );
          setPagination((prevPagination) => ({
            ...prevPagination,
            totalPage: result.totalPage
          }));
        }
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
        setIsSwitching(false);
      }
    };

    if (pagination.search) fetchSearchResults();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, pagination.search, pagination.entity, profile.id]);

  return (
    <div>
      <Helmet>
        <title>Search Result</title>
      </Helmet>
      <div className="flex flex-start flex-col px-4 md:px-0">
        <div className="flex sticky top-[50px] md:top-[80px] pt-2 md:pt-10 z-20 bg-white">
          <div
            className="p-1 mt-1 2xl:p-2 rounded-[80px] h-fit bg-[#4CAF50] cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              className="max-w-5 max-h-5 min-w-5 min-h-5 md:max-w-10 md:max-h-10 md:min-w-10 md:min-h-10"
              src={ArrowLeftIcon}
              alt=""
            />
          </div>
          <p className="text-[24px] md:text-[48px] font-[600] w-full mx-auto pl-8 2xl:pl-0 max-w-[1090px] text-[#808080]">
            Results for <span className="text-[#4CAF50] font-[800]">{searchParams.get("q")}</span>
          </p>
        </div>

        <div className="w-full max-w-[1072px] mx-auto px-0 md:px-5">
          <div className="flex items-center gap-2 md:gap-4 pt-6 sticky top-[90px] md:top-[180px] bg-white z-20">
            {resultSearchType?.map((item, index) => (
              <p
                key={index}
                className={`rounded-[80px] cursor-pointer font-[800] text-sm px-[14px] py-[8.5px] ${
                  pagination.entity === item.value
                    ? "bg-[#204A22] text-[#EDF7EE]"
                    : "bg-[#C8E6C9] text-[#204A22]"
                }`}
                onClick={() => handleChangeTab(item)}
              >
                {item.name}
              </p>
            ))}
          </div>

          <div className="mt-6 md:mt-10 flex flex-col gap-5 md:gap-10">
            {isSwitching ? (
              <div className="flex justify-center items-center mt-10">
                <Spinner
                  thickness="4px"
                  speed="0.65s"
                  emptyColor="gray.200"
                  color="dark-green"
                  size="lg"
                />
              </div>
            ) : pagination.entity === "creators" ? (
              searchResult.length > 0 ? (
                searchResult.map((item) => (
                  <CardAuthor
                    key={item.id}
                    author={item}
                    handleFollowUser={handleFollowUser}
                    isLoading={loadingAuthorId === item.id}
                  />
                ))
              ) : (
                !loading && (
                  <SearchNoResultComponent
                    result={searchParams.get("q")}
                    titleBtn="Discover Articles"
                    typeSearchText="authors"
                    titleRedirect="check related articles"
                    handleChangeTab={() => setPagination({ ...pagination, entity: "articles" })}
                  />
                )
              )
            ) : (
              <div className="flex flex-col gap-[30px] 2xl:gap-[60px]">
                {renderContentWithAds()}
                {loading && [...Array(3)].map((_, i) => <CardArticleLoading key={i} index={i} />)}
                {searchResult.length === 0 && !loading && (
                  <SearchNoResultComponent
                    result={searchParams.get("q")}
                    titleBtn="Discover Authors"
                    typeSearchText="articles"
                    titleRedirect="discover expert authors"
                    handleChangeTab={() => setPagination({ ...pagination, entity: "creators" })}
                  />
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchResult;
