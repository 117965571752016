import AvatarIcon from "../../../assets/ava-icon.svg";
import { useNavigate } from "react-router-dom";

const UserCard = ({ user }) => {
  const navigate = useNavigate();

  function getDescription(text) {
    if (!text) return "No description";
    if (text.length > 60) {
      return text.slice(0, 60) + "...";
    }
    return text;
  }

  return (
    <div>
      <div className="grid grid-cols-[80px,auto] gap-[8px]">
        <img className="rounded-full w-[80px] h-[80px]" src={user?.imgUrl || AvatarIcon} alt="" />
        <div className="grid grid-rows-3">
          <div className="font-[600]">
            {user?.firstName} {user?.lastName}
          </div>
          <div className="italic">{getDescription(user?.description)}</div>
          <div
            onClick={() => {
              navigate(`/profile/${user?.id}`);
            }}
            className="text-[#00A375] font-[900]"
          >
            View profile
          </div>
        </div>
      </div>
      <div></div>
    </div>
  );
};

export default UserCard;
