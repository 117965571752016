import { Helmet } from "react-helmet";
import ChooseTemplateComponent from "../../components/CreateArticle/components/choose-template.component";
import WriterEditor from "../../routes/WriterEditor";
import { useEffect, useState } from "react";
import { useSetAtom } from "jotai";
import { articlePayloadAtom } from "../../components/CreateArticle/atom";

const CreateArticle = () => {
  const [step, setStep] = useState(1);
  const setArticlePayload = useSetAtom(articlePayloadAtom);

  useEffect(() => {
    const header = document.getElementById("writer-header");
    if (step === 4) {
      header.style.display = "none";
    } else {
      header.style.display = "flex";
    }
    return () => {
      header.style.display = "flex";
    };
  }, [step]);

  useEffect(() => {
    return () => setArticlePayload({});
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Helmet>
        <title>New Article</title>
      </Helmet>
      <div className={`w-full ${step === 4 ? "hidden" : ""} flex flex-col items-center`}>
        <div className="sticky top-[56px] md:top-[80px] bg-[#FAFAFA] px-[17.5px] pt-5 md:pt-10 pb-6 w-full text-center z-10">
          <h1 className="text-[32px] font-[800]">Write New Article</h1>
        </div>
        <div className="px-[17.5px] w-full bg-[#FAFAFA]">
          <ChooseTemplateComponent setStep={setStep} />
        </div>
      </div>
      {step === 4 && <WriterEditor />}
    </div>
  );
};

export default CreateArticle;
