export const initialStateArticle = {
  writingTypeId: 1,
  title: "",
  subTitle: "",
  description: "",
  imgUrl: "",
  nameCreator: "",
  content: {},
  contentLength: 0,
  topicIds: [],
  isEnableAISummaries: false,
  coverCloudKey: "",
  hashtags: [],
  organization: "",
  contributors: []
};
