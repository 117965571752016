export const UPLOAD_FILE_METADATA_DESC = {
  image: ["Supported file types: PNG, JPG, JPEG.", "(max. 800x400px , 1MB)"]
};

export const UPLOAD_FILE_EDITOR_DESC = {
  image: ["Supported file types: PNG, JPG, JPEG, GIF", "Maximum file size: 10MB"]
};

export const AI_SUMMARY_SUPPORT =
  "Enable AI summaries to automatically generate concise overviews of your article for quick reading.";

export const EARNING_TEXT = [
  "Earnings reflect the total amount you've made from published articles based on views and engagements.",
  "Paid upon reaching the USD 100 payment threshold."
];

export const EQUITY_PERCENTAGE_TOOLTIP = "Equity percentage tooltip is missing";
