import { useState } from "react";
import { Helmet } from "react-helmet";
import LeftContentComponent from "../../components/DiscoveryComponent/LeftContentComponent";
import RightContentComponent from "../../components/DiscoveryComponent/RightContentComponent";
import Cookies from "js-cookie";
import ListFilter from "../../components/DiscoveryComponent/LeftContentComponent/components/list-filter.component";
import { useGetListArticleByFilter } from "../../hooks/useGetListArticleByFilter";

const staging = process.env.REACT_APP_IS_STAGING;

const DiscoveryPage = () => {
  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  const [selected, setSelected] = useState({
    type: token ? "forYou" : "trending",
    topic: 1
  });

  const { loading, listArticle, setPagination } = useGetListArticleByFilter({
    type: selected.type,
    topic: selected.topic
  });

  return (
    <div>
      <Helmet>
        <title>Discovery</title>
      </Helmet>
      <ListFilter selected={selected} setSelected={setSelected} setPagination={setPagination} />

      <div className="w-full flex overflow-x-hidden">
        <div id="left-content" className="w-full md:w-[65%] 2xl:w-[70%] relative">
          <LeftContentComponent selected={selected} loading={loading} listArticle={listArticle} />
        </div>
        <div className="hidden md:!flex md:w-[35%] 2xl:w-[30%]">
          <div className="w-[2px] h-[100vh] bg-[#E6E7EB] fixed top-0 z-20 seperate-line"></div>
          <RightContentComponent selected={selected} />
        </div>
      </div>
    </div>
  );
};

export default DiscoveryPage;
