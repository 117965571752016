import React, { useEffect, useState } from "react";
import SearchIcon from "../../assets/search-icon.svg";
import CloseIcon from "../../assets/close-icon.svg";
import AvatarDefault from "../../assets/ava-icon.svg";
import TrendingIcon from "../../assets/trending-icon.svg";
import VerifiedIcon from "../../assets/verified-icon.svg";
import { Spinner } from "@chakra-ui/react";
import { fetchSearch } from "../../apis/article.api";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useAtom } from "jotai";
import { openSideBarAtom } from "./atom";

const HeaderActionComponent = ({ ...rest }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 3,
    search: ""
  });
  const [searchResult, setSearchResult] = useState({});
  const [debouncedValue] = useDebounce(pagination.search, 500);
  const [loading, setLoading] = useState(true);
  const [, setOpenSidebar] = useAtom(openSideBarAtom);

  const navigate = useNavigate();

  const handleInputChange = (e) => {
    const value = e.target.value;
    setPagination({
      ...pagination,
      search: value
    });
    if (value.length > 0) {
      setIsExpanded(true);
    } else {
      setIsExpanded(false);
    }
  };

  useEffect(() => {
    const fetchSearchResults = async () => {
      setLoading(true);
      try {
        const result = await fetchSearch(pagination);
        setSearchResult(result);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading(false);
      }
    };

    if (debouncedValue) {
      fetchSearchResults();
    }
  }, [debouncedValue]);

  const handleClickViewAll = () => {
    navigate(`/search?q=${pagination.search}`);
    setIsExpanded(false);
    setPagination({
      ...pagination,
      page: 1
    });
    setOpenSidebar(false);
  };

  return (
    <div className="relative">
      <div
        className="w-full max-w-[562px] truncate absolute top-[-22px] left-[50%] right-[50%] translate-x-[-50%] px-3"
        {...rest}
      >
        <div
          className={`transition-all duration-300 ease-in-out border border-[#4CAF50] bg-white rounded-[40px] py-2.5 px-5`}
        >
          {/* Search Input and Icons */}
          <div className="flex items-center overflow-hidden">
            <img src={SearchIcon} alt="Search" className="w-6 h-6" />
            <input
              className="flex-1 mx-4 font-semibold placeholder:text-sm border-none outline-none focus:outline-none"
              type="text"
              placeholder="Search for articles, authors, or topics"
              value={pagination.search}
              onChange={handleInputChange}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  handleClickViewAll();
                }
              }}
            />
            {pagination.search && (
              <img
                src={CloseIcon}
                alt="Close"
                className="w-6 h-6 cursor-pointer"
                onClick={() => {
                  setPagination({
                    ...pagination,
                    search: ""
                  });
                  setIsExpanded(false);
                }}
              />
            )}
          </div>

          {/* Accordion Content */}
          {isExpanded && (
            <div className="mt-4 transition-all duration-300 ease-in-out">
              {/* Loading Spinner */}
              {loading ? (
                <div className="flex justify-center py-4">
                  <Spinner size="md" color="#4CAF50" />
                </div>
              ) : (
                <>
                  {searchResult?.creatorsData?.data?.length > 0 ||
                  searchResult?.articleData?.data?.length > 0 ? (
                    <>
                      {/* Authors Section */}
                      <div>
                        <p className="text-sm font-[800] py-[10.5px] text-[#204A22]">Authors</p>
                        <ul>
                          {searchResult?.creatorsData?.data?.map((item) => (
                            <li
                              key={item.id}
                              className="py-2 px-2.5 rounded-[12px] hover:!bg-[#C8E6C999] cursor-pointer flex items-center gap-2 font-[800] text-sm hover:bg-gray-100"
                              onClick={() => navigate(`/authors/${item.id}`)}
                            >
                              <img
                                className="w-6 h-6 rounded-full"
                                src={item.imgUrl || AvatarDefault}
                                alt=""
                              />
                              {item.firstName} {item.lastName}
                              {item.isVerified && <img src={VerifiedIcon} alt="" />}
                            </li>
                          ))}
                        </ul>
                      </div>

                      {/* Articles Section */}
                      <div>
                        <p className="text-sm font-[800] py-[10.5px] text-[#204A22]">Articles</p>
                        <ul>
                          {searchResult?.articleData?.data?.map((item) => (
                            <li
                              key={item.id}
                              className="py-2 px-2.5 rounded-[12px] hover:!bg-[#C8E6C999] cursor-pointer flex items-center gap-2 font-[800] text-sm hover:bg-gray-100"
                            >
                              {item.isBreakingNews && <img src={TrendingIcon} alt="" />}
                              <p className="truncate">{item.title}</p>
                            </li>
                          ))}
                        </ul>
                      </div>
                      <p
                        className="text-sm font-[800] text-[#4CAF50] py-2 cursor-pointer"
                        onClick={handleClickViewAll}
                      >
                        View all results
                      </p>
                    </>
                  ) : (
                    <div className="py-2 px-2.5 whitespace-break-spaces">
                      <p className="text-sm font-[600]">
                        No results for{" "}
                        <span className="text-[#4CAF50] text-sm font-[800]">
                          “{pagination.search}”
                        </span>
                      </p>
                      <p className="text-[#808080] mt-2 text-sm font-[600]">
                        We couldn't find any matches, but don’t worry! Try different or broader
                        keywords.
                      </p>
                    </div>
                  )}
                </>
              )}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default HeaderActionComponent;
