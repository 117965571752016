import * as yup from "yup";

export const metadataSchema = yup.object().shape({
  title: yup.string().trim().required("Title is required").max(100, "Title maximum 100 characters"),
  note: yup.string().trim().required("Note is required").max(2000, "Note maximum 2000 characters"),
  content: yup.string().required("Content is required"),
  equityPercentage: yup
    .string()
    .trim()
    .required("Equity percentage is required")
    .matches(/^\d+(\.\d+)?$/, "Equity percentage must be a number")
    .test("is-in-range", "Equity percentage must be between 0 and 100", (value) => {
      const numValue = parseFloat(value);
      return numValue >= 0 && numValue <= 100;
    }),
  contentTypeIds: yup
    .array()
    .min(1, "Content type is required")
    .required("Content type is required")
});
