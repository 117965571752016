import { Box, Text } from "@chakra-ui/react";
import InformIcon from "../../../assets/inform-icon.svg";

const RequestCard = ({ request, showStatus = false }) => {
  return (
    <div>
      <div className="grid grid-rows-[auto,auto,auto] gap-[10px]">
        <div className="flex items-center gap-[5px]">
          <span className="text-[#4CAF50] text-[14px] flex items-center gap-[5px]">
            Proposed Equity
            <img className="w-[15px] h-[15px]" src={InformIcon} alt="" />:
          </span>
          <span className="text-[14px]">{request?.equityPercentage}%</span>
        </div>
        {showStatus ? (
          <div className="flex items-center gap-[5px]">
            <span className="text-[#4CAF50] text-[14px] flex items-center gap-[5px]">
              Request status:
            </span>
            <Box
              px={"12px"}
              rounded={"80px"}
              bgColor={request.status === "closed" ? "#4CAF50" : "#EDF7EE"}
              w={"fit-content"}
            >
              <Text
                color={request.status === "closed" ? "#FFFFFF" : "#204A22"}
                fontWeight={request.status === "closed" ? 800 : 600}
                fontSize={"xs"}
              >
                {request.status === "closed" ? "Closed" : "Ongoing"}
              </Text>
            </Box>
          </div>
        ) : (
          <></>
        )}
        <div className="font-[800] text-[18px]">{request?.title}</div>
        <div className="text-[16px] text-justify">{request?.description}</div>
      </div>
    </div>
  );
};

export default RequestCard;
