import React from "react";
import {
  Box,
  Button,
  Flex,
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import CloseModalIcon from "../../../assets/close-modal-icon.svg";

function ConfirmModal(props) {
  const {
    isOpen,
    onClick,
    Icon,
    title,
    desc,
    titleBtn,
    onClose,
    isLoading,
    titleBtnCancel,
    variant,
    type
  } = props;
  return (
    <>
      <Modal isOpen={isOpen} isCentered onClose={onClose}>
        <ModalOverlay />
        <ModalContent borderRadius={"1.5rem"} w={"407px"} p={"3rem 3.28rem"} bgColor={"light.200"}>
          <ModalHeader p={0} w={"100%"} m={0} maxWidth={"unset"}>
            <div className="flex w-full items-center justify-between pb-2 border-b border-[#D1C8C4]">
              <p className="text-xl font-[800]">{title}</p>
              <Image
                className="w-[40px] h-[40px] cursor-pointer"
                src={CloseModalIcon}
                onClick={onClose}
                alt=""
              />
            </div>
          </ModalHeader>
          <ModalBody p={0} mb={"35px"}>
            <Box w={"12.5rem"} h={"12.5rem"} p={"1.5625rem"} margin="auto">
              <img src={Icon} alt="" className="w-[9.375rem] h-[9.375rem] block"></img>
            </Box>
            <Flex flexDirection={"column"} gap={"10px"}>
              {type && (
                <Text
                  fontSize={"32px"}
                  color={"dark-green"}
                  fontWeight={"900"}
                  textAlign={"center"}
                >
                  Error
                </Text>
              )}
              <Text fontSize={"1.125rem"} textAlign={"center"}>
                {desc}
              </Text>
            </Flex>
          </ModalBody>
          <ModalFooter p="0" gap={"10px"}>
            <Button
              type="submit"
              w="100%"
              variant={"dark-green-solid"}
              onClick={onClose}
              height={"58px"}
              fontSize={"18px"}
              _focusVisible={{
                boxShadow: "none"
              }}
            >
              {titleBtnCancel ?? "Cancel"}
            </Button>
            <Button
              w="100%"
              variant={variant ?? "red"}
              onClick={onClick}
              height={"58px"}
              fontSize={"18px"}
              isLoading={isLoading}
              _focusVisible={{
                boxShadow: "none"
              }}
            >
              {titleBtn}
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default ConfirmModal;
