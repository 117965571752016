import React, { useState } from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Image,
  Text,
  Box
} from "@chakra-ui/react";
import CopyGreenIcon from "../../assets/copy-green-icon.svg";
import FacebookIcon from "../../assets/facebook-icon.svg";
import LinkedinIcon from "../../assets/linkedin-icon.svg";
import PinterestIcon from "../../assets/pinterest-icon.svg";
import TwitterIcon from "../../assets/twitter-icon.svg";
import WhatsappIcon from "../../assets/whatsapp-icon.svg";
import CloseModalIcon from "../../assets/close-modal-icon.svg";
import ShareImageModal from "../../assets/share-image-modal.svg";

import {
  FacebookShareButton,
  LinkedinShareButton,
  PinterestShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import Button from "../Button";

function ShareContentModal(props) {
  const { isOpen, onClick, title, shareUrl, titleBtn, onClose, media, bgColorOverlay } = props;

  const [isCopy, setIsCopy] = useState(false);

  const handleCopy = (value) => () => {
    if (!isCopy) {
      setIsCopy(true);
      setTimeout(() => {
        setIsCopy(false);
      }, 3000);
      if (value) navigator.clipboard.writeText(value);
    }
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay backgroundColor={bgColorOverlay} />
      <ModalContent p={"32px"} bgColor={"#FAFAFA"} rounded={"12px"}>
        <ModalHeader p={0} w={"100%"} m={0} maxWidth={"unset"}>
          <div className="flex w-full items-center justify-between pb-2 border-b border-[#D1C8C4]">
            <p className="text-xl font-[800]">{title}</p>
            <Image
              className="w-[40px] h-[40px] cursor-pointer"
              src={CloseModalIcon}
              onClick={onClick}
              alt=""
            />
          </div>
        </ModalHeader>
        <ModalBody p={0}>
          <div className="flex flex-col items-center mt-6 mb-4">
            <img className="w-[200px] h-[200px]" src={ShareImageModal} alt="" />
            <p className="text-[32px] font-[900] text-[#4CAF50] mt-3">Share your Article!</p>
            <div className="flex items-center gap-4 mt-6">
              <FacebookShareButton url={shareUrl} hashtag={"#twygs"}>
                <Image
                  _hover={{
                    opacity: 0.8
                  }}
                  src={FacebookIcon}
                  alt="icon"
                />
              </FacebookShareButton>
              <TwitterShareButton
                url={shareUrl}
                title={"I'm thrilled to share my latest Twyg with you! See what's new"}
              >
                <Image
                  _hover={{
                    opacity: 0.8
                  }}
                  src={TwitterIcon}
                  alt="icon"
                />
              </TwitterShareButton>
              <LinkedinShareButton url={shareUrl}>
                <Image
                  _hover={{
                    opacity: 0.8
                  }}
                  src={LinkedinIcon}
                  alt="icon"
                />
              </LinkedinShareButton>
              <WhatsappShareButton
                url={shareUrl}
                windowWidth={700}
                title={"I'm thrilled to share my latest Twyg with you! See what's new"}
                windowHeight={600}
                separator=" "
              >
                <Image
                  _hover={{
                    opacity: 0.8
                  }}
                  src={WhatsappIcon}
                  alt="icon"
                />
              </WhatsappShareButton>
              <PinterestShareButton
                description="twygs.io"
                media={
                  media ||
                  `${process.env.REACT_APP_NEWSLETTER_URL}static/media/default-thumnail.ec3e32b29325b9a52a3c.png`
                }
                url={shareUrl}
              >
                <Image
                  _hover={{
                    opacity: 0.8
                  }}
                  src={PinterestIcon}
                  alt="icon"
                />
              </PinterestShareButton>
            </div>
            <div className="py-4 pl-6 pr-4 mt-4 bg-[#F2F3F7] rounded-[12px] flex items-center justify-between gap-4 w-full">
              <Text
                isTruncated
                w={"100%"}
                maxW={"257px"}
                lineHeight={"30px"}
                fontSize={"md"}
                fontWeight={600}
              >
                {shareUrl}
              </Text>
              <Box
                display={"flex"}
                alignItems={"center"}
                columnGap={"8px"}
                onClick={handleCopy(shareUrl)}
                cursor="pointer"
              >
                <Text fontSize={"sm"} fontWeight={900} color={"#4CAF50"}>
                  {isCopy ? "Copied" : "Copy"}
                </Text>
                <Image src={CopyGreenIcon} alt="" />
              </Box>
            </div>
          </div>
        </ModalBody>
        <ModalFooter w="100%" p="0">
          <Button onClick={onClick} className="w-full h-[56px] text-lg">
            {titleBtn}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ShareContentModal;
