import { TAB_LIST_DASHBOARD } from "../../constants/article.constant";
import ArticlesComponent from "../../components/ManageArticles/articles";
import RequestComponent from "../../components/ManageArticles/requests";
import { useSearchParams } from "react-router-dom";
import { useEffect } from "react";
import ResponseComponent from "../../components/ManageArticles/submissions";

const ManageArticle = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectedTab = (value) => {
    setSearchParams({ tab: value });
  };

  useEffect(() => {
    if (!searchParams.get("tab")) {
      setSearchParams({ tab: "articles" });
    }
  }, [searchParams, setSearchParams]);

  return (
    <div className="px-5 md:px-[50px] 2xl:px-[100px] py-10">
      <div className="flex mb-6">
        {TAB_LIST_DASHBOARD.map((item) => (
          <div
            className="flex flex-col items-center w-[140px] gap-2 cursor-pointer"
            key={item.id}
            onClick={() => handleSelectedTab(item.value)}
          >
            <p
              className={`text-lg  ${searchParams.get("tab") === item.value ? "text-[#333333] font-[800]" : "text-[#808080] font-[600]"}`}
            >
              {item.name}
            </p>
            <div
              className={`h-[4px] w-full ${searchParams.get("tab") === item.value ? "bg-[#4CAF50]" : "bg-[#E6E7EB]"} rounded-[10px]`}
            ></div>
          </div>
        ))}
      </div>
      {searchParams.get("tab") === "articles" && <ArticlesComponent></ArticlesComponent>}
      {searchParams.get("tab") === "requests" && <RequestComponent></RequestComponent>}
      {searchParams.get("tab") === "submissions" && <ResponseComponent></ResponseComponent>}
    </div>
  );
};

export default ManageArticle;
