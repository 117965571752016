export const TYPE_IMAGES = ["jpeg", "jpg", "png", "gif", "svg"];
export const TYPE_IMAGES_THUMBNAIL = ["jpeg", "jpg", "png"];
export const ALLOWED_FILE_TYPES_EDITOR = [
  "image/png",
  "image/jpeg",
  "image/jpg",
  "image/gif",
  "image/svg+xml"
];

export const ALLOWED_FILE_TYPES_THUMBNAIL = ["image/png", "image/jpeg", "image/jpg"];

export const FILE_TYPES = {
  twygsImage: "image/png, image/jpeg, image/jpg",
  twygsImageLexicalEditor: "image/png, image/jpeg, image/jpg, image/gif",
  twygsVideo: "video/mp4, video/quicktime, video/m4v, video/3gp",
  twygsPdf: ".pdf,application/pdf",
  brandSafety: "text/csv",
  profile: "image/png, image/jpeg, image/jpg, image/gif, image/svg+xml"
};

export const MAX_FILE_SIZE_1 = 1000 * 1000; // 1MB in Bytes
export const MAX_FILE_SIZE_30 = 30 * 1000 * 1000; // 30MB in Bytes
export const MAX_FILE_SIZE_50 = 50 * 1000 * 1000; // 50MB in Bytes
export const MAX_FILE_SIZE_10 = 10 * 1000 * 1000; // 10MB in Bytes
export const MAX_FILE_SIZE_300 = 300 * 1000 * 1000; // 300MB in Bytes
export const MAX_FILE_SIZE_4000 = 4 * 1000 * 1000 * 1000; // 4GB in Bytes
export const MAX_FILE_RESIZE_IMAGE = 1000 * 1000; // 1MB in Bytes
export const MAX_VIDEO_DURATION_2H = 2 * 60 * 60; // 2 Hour in Seconds
export const CHUNK_SIZE = 50 * 1000 * 1000; // 50MB in Bytes

export const getContentType = (fileName) => {
  const extension = fileName.split(".").pop();
  if (!extension) {
    return;
  }
  if (TYPE_IMAGES.includes(extension)) {
    return "image/" + extension;
  } else return `application/${extension}`;
};

export const handleFileNameExtension = (fileName, type) => {
  if (!fileName || !type) return null;
  const name = String(fileName).substring(0, String(fileName).lastIndexOf("."));
  let extension = "";
  if (ALLOWED_FILE_TYPES_THUMBNAIL.includes(type)) {
    extension = String(type).split("/")[1] || "webp";
  }
  const str = `${name}.${extension}`;
  return str;
};

export const IMAGE_TYPE = {
  PNG: "image/png",
  JPEG: "image/jpeg",
  JPG: "image/jpg",
  GIF: "image/gif",
  WEBP: "image/webp"
};
