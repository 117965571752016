import axiosInstance from "./axios-instance";

export const postAssetUpload = async (data) => {
  try {
    const result = await axiosInstance.post(`articles/presigned-upload-asset`, data);
    return result.data.data;
  } catch (error) {
    console.error("Error uploading asset:", error);
    throw error;
  }
};

export const fetchListWrittingType = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/writing-types`
  );
  return result.data.data;
};

export const fetchListUser = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}user`);
  return result.data.data;
};

export const fetchListRole = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/roles`);
  return result.data.data;
};

export const publishArticle = async (data) => {
  const result = await axiosInstance.patch(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/publish`,
    data
  );
  return result.data.data;
};

export const saveAsDraft = async (data) => {
  const result = await axiosInstance.patch(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/save-draft`,
    data
  );
  return result.data.data;
};

export const fetchSearch = async (pagination, userId) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/search`, {
    params: { ...pagination, userId }
  });
  return result.data.data;
};

export const fetchTopics = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/topics`,
    {}
  );
  return result.data.data;
};

export const fetchArticles = async (params) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles`, {
    params
  });
  return result.data.data;
};

export const fetchListMyArticles = async (pagination) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/me`, {
    params: pagination
  });
  return result.data.data;
};

export const fetchArticleDetail = async (id, myView) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/${id}/detail`,
    { params: { myView } }
  );
  return result.data.data;
};

export const deleteArticle = async (id) => {
  try {
    const result = await axiosInstance.delete(`/articles/${id}`);
    return result.data.data;
  } catch (error) {
    console.error("Error deleting articles:", error);
    throw error;
  }
};

export const saveArticle = async (id) => {
  try {
    const result = await axiosInstance.post(`/articles/interaction/save`, {
      articleId: id
    });
    return result.data.data;
  } catch (error) {
    console.error("Error deleting requests:", error);
    throw error;
  }
};

export const unsaveArticle = async (id) => {
  try {
    const result = await axiosInstance.post(`/articles/interaction/unsave`, {
      articleId: id
    });
    return result.data.data;
  } catch (error) {
    console.error("Error deleting requests:", error);
    throw error;
  }
};

export const fetchListMediaOfContributor = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}responses/approved`
  );
  return result.data.data;
};
