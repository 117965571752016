import { useEffect, useState } from "react";
import NoArticleImg from "../../assets/no-article.svg";
import WriteIcon from "../../assets/write-icon.svg";
import Button from "../Button";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const EmptyArticleComponent = ({ categoryType }) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [mainDescription, setMainDescription] = useState("");
  const [subDescription, setSubDescription] = useState("");
  const [buttonNavigate, setButtonNavigate] = useState("/");
  const [buttonText, setButtonText] = useState("");

  useEffect(() => {
    const pathSegments = location.pathname.split("/").filter(Boolean);
    let type = categoryType || pathSegments[pathSegments.length - 1];

    switch (type) {
      case "forYou": {
        setMainDescription("No articles for you at the moment!");
        setSubDescription("Discover articles in other topics or be the first to contribute!");
        setButtonNavigate("/article/create");
        setButtonText("Write");
        break;
      }
      case "trending": {
        setMainDescription("No trending articles at the moment!");
        setSubDescription("Keep exploring or write something impactful to start the trend");
        setButtonNavigate("/article/create");
        setButtonText("Write");
        break;
      }
      case "breaking-news":
      case "breaking": {
        setMainDescription("No breaking news at the moment.");
        setSubDescription("Stay tuned for updates or start a trend with your own insights!");
        setButtonNavigate("/article/create");
        setButtonText("Write");
        break;
      }
      case "following": {
        setMainDescription("It looks like you haven’t followed any authors yet.");
        setSubDescription("Follow some featured voices to start seeing their content here!");
        setButtonNavigate("/featured-voices");
        setButtonText("Discover Featured Voices");
        break;
      }
      case "myReading": {
        setMainDescription(
          "It looks like you haven’t added any articles to your <b>Reading List</b> yet."
        );
        setSubDescription("Start exploring and add some favorites to stay updated!");
        setButtonNavigate(0);
        setButtonText("Find Articles to Read");
        break;
      }
      case "categories": {
        setMainDescription("No articles found in this category yet!");
        setSubDescription("Discover articles in other topics or be the first to contribute!");
        setButtonNavigate("/article/create");
        setButtonNavigate("Write");
        break;
      }
      default: {
        setMainDescription("No articles at the moment.");
        setSubDescription("Go back to discovery page!");
        setButtonNavigate("/");
        setButtonText("Find Articles to Read");
        break;
      }
    }
  }, [categoryType]);

  function handleChangeTab() {
    navigate(buttonNavigate);
  }

  return (
    <div className="flex flex-col gap-4 items-center">
      <img className="w-[240px] md:w-[400px]" src={NoArticleImg} alt="" />
      <p
        className="font-[600] text-[24px] md:text-[28px] text-center"
        dangerouslySetInnerHTML={{ __html: mainDescription }}
      />
      <p className="font-[400] text-[24px] md:text-[28px] text-center">{subDescription}</p>
      <div className="flex flex-col items-center">
        {buttonText === "Write" ? (
          <Button variant="primary" className="w-fit mt-2" onClick={handleChangeTab}>
            <p className="text-[18px]">{buttonText}</p>
            <img src={WriteIcon} alt="" className="ml-2" />
          </Button>
        ) : (
          <Button variant="secondary" className="w-fit mt-2" onClick={handleChangeTab}>
            <p className="text-[18px]">{buttonText}</p>
          </Button>
        )}
      </div>
    </div>
  );
};

export default EmptyArticleComponent;
