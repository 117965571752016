import { useEffect } from "react";
import { useState } from "react";
import {
  fetchBreakingNewArticles,
  fetchListArticle,
  fetchListFollowing,
  fetchListForYou,
  fetchListTrending,
  fetchMyReadingList
} from "../apis/discovery.api";
import { useSearchParams } from "react-router-dom";
import { useAtomValue } from "jotai";
import { profileAtom } from "../components/Settings/atom";

export const useGetListArticleByFilter = (params) => {
  const [loading, setLoading] = useState(true);
  const [listArticle, setListArticle] = useState([]);
  const [total, setTotal] = useState(0);
  const [searchParams] = useSearchParams();
  const profile = useAtomValue(profileAtom);

  const [pagination, setPagination] = useState({
    page: params.page || 1,
    limit: params.limit || 4,
    totalPage: 0
  });

  const handleLoadMore = () => {
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    if (scrolled >= scrollable - 300 && !loading && pagination.page < pagination.totalPage) {
      setLoading(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1
      }));
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleLoadMore);
    return () => {
      window.removeEventListener("scroll", handleLoadMore);
    };
  });

  useEffect(() => {
    const payload = {
      ...pagination,
      ...params,
      topic: undefined,
      topicIds: [params.topic]
    };

    const fetchArticle = async () => {
      setLoading(true);
      let result = [];
      switch (params.type) {
        case "myReading":
          result = await fetchMyReadingList(payload, profile?.id);
          break;
        case "trending":
          result = await fetchListTrending({ ...payload, topicIds: undefined }, profile?.id);
          break;
        case "breaking":
          result = await fetchBreakingNewArticles({ ...payload, topicIds: undefined }, profile?.id);
          break;
        case "forYou":
          result = await fetchListForYou({ ...payload, topicIds: undefined }, profile?.id);
          break;
        case "following":
          result = await fetchListFollowing({ ...payload, topicIds: undefined }, profile?.id);
          break;
        default:
          result = await fetchListArticle({ ...payload }, profile?.id);
          break;
      }
      if (result) {
        setListArticle((prevList) =>
          pagination.page === 1 ? result.data : [...prevList, ...result.data]
        );
        setPagination((prevPagination) => ({
          ...prevPagination,
          totalPage: result.totalPage
        }));
        setTotal(result?.totalCount);
      }
      setLoading(false);
    };
    fetchArticle();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.type, pagination.page, params.topic]);

  useEffect(() => {
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1
    }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchParams.get("topicId")]);

  return { loading, listArticle, handleLoadMore, total, setPagination };
};
