const TopicLoading = () => {
  return (
    <div>
      <div className="w-full flex flex-col gap-[24px] animate-pulse">
        <div className="w-[100%] h-[40px] bg-gray-300 rounded-full"></div>
        <div className="w-[90%] h-[40px] bg-gray-300 rounded-full"></div>
        <div className="w-[80%] h-[40px] bg-gray-300 rounded-full"></div>
      </div>
    </div>
  );
};

export default TopicLoading;
