export const SwitchTheme = {
  baseStyle: {},

  sizes: {
    sm: {
      h: "20px",
      w: "36px",
    },

    md: {
      h: "24px",
      w: "44px",
    },
  },

  variants: {
    dark: {
      track: {
        bg: "#EAECF0",
        _checked: {
          bg: "#084F4B",
          _focus: {
            bg: "#084F4B",
          },
          _hover: {
            bg: "#084F4B",
          },
        },
        _hover: {
          bg: "#EAECF0",
        },
        _focus: {
          bg: "#EAECF0",
        },
      },
    },
    light: {
      track: {
        bg: "#F9F5FF",
        _checked: {
          bg: "#E9D7FE",
          _focus: {
            boxShadow: "0px 0px 0px 4px #F4EBFF",
          },
          _hover: {
            bg: "#D6BBFB",
          },
        },
        _hover: {
          bg: "#F4EBF",
        },
        _focus: {
          boxShadow: "0px 0px 0px 4px #F4EBFF",
          bg: "#F9F5FF",
        },
      },
    },
    "light-green": {
      track: {
        bg: "#CCCCCC",
        _checked: {
          bg: "#4CAF50",
          _focus: {
            boxShadow: "0px 0px 0px 4px #EDF7EE",
          },
        },
      },
    },
  },

  defaultProps: {
    size: "md",
  },
};
