export const protectedRoutes = {
  HOME: "/home",
  PROFILE: "/profile",
  TWO_FACTOR_AUTHENTICATION: "/profile/two-factor-authentication",
  EARNINGS: "/earnings",
  MY_EARNINGS: "/earnings/my-earnings",
  PAYMENT_INFO: "/earnings/payments",
  ADD_PAYMENT: "/earnings/payments/add-payment",
  STUDIO: "/studio",
  CREATE_TWYGS: "/studio/create-twyg",
  CREATE_TWYGS_IMAGE: "/studio/create-twyg/image-twyg",
  CREATE_TWYGS_VIDEO: "/studio/create-twyg/video-twyg",
  CREATE_TWYGS_PDF: "/studio/create-twyg/pdf-twyg",
  MY_TWYGS: "/studio/my-twygs",
  MY_DRAFTS: "/studio/my-twygs/draft",
  EDIT_TWYGS: "/studio/my-twygs/edit-twyg",
  RECOMMEND_TOPICS: "/recommended-topics"
};

export const ROUTES = [
  {
    text: "Studio",
    path: "/studio",
    subtext: "Studio",
    endpoint: "/studio"
  }
];

export const CHILD_ROUTES = [
  {
    text: "Profile",
    path: "/profile/two-factor-authentication",
    subtext: "2FA",
    endpoint: "/two-factor-authentication",
    hiddenOnSidebar: true
  },
  {
    text: "My Earnings",
    path: "/earnings/my-earnings",
    subtext: "My Earnings",
    endpoint: "/my-earnings"
  },
  {
    text: "Payments",
    path: "/earnings/payments",
    subtext: "Payments",
    endpoint: "/payments"
  },
  {
    text: "Add payment method",
    path: "/earnings/payments/add-payment",
    subtext: "Add payment method",
    endpoint: "/add-payment",
    hiddenOnSidebar: true
  },
  {
    text: "Create Twygs",
    path: "/studio/create-twyg",
    subtext: "Create a Twyg",
    endpoint: "/create-twyg"
  },
  {
    text: "Create Twygs",
    path: "/studio/create-twyg/image-twyg",
    subtext: "Image Twyg",
    endpoint: "/image-twyg",
    hiddenOnSidebar: true
  },
  {
    text: "Create Twygs",
    path: "/studio/create-twyg/video-twyg",
    subtext: "Video Twyg",
    endpoint: "/video-twyg",
    hiddenOnSidebar: true
  },
  {
    text: "Create Twygs",
    path: "/studio/create-twyg/pdf-twyg",
    subtext: "PDF Twyg",
    endpoint: "/pdf-twyg",
    hiddenOnSidebar: true
  },
  {
    text: "My Twygs",
    path: "/studio/my-twygs",
    subtext: "My Twygs",
    endpoint: "/my-twygs"
  },
  {
    text: "My Twygs",
    path: "/studio/my-twygs/draft",
    subtext: "Draft",
    endpoint: "/draft",
    hiddenOnSidebar: true
  },
  {
    text: "My Twygs",
    path: "/studio/my-twygs/edit-twyg",
    subtext: "Edit Twyg",
    endpoint: "/edit-twyg",
    hiddenOnSidebar: true,
    disableBreadcrumbLink: true
  }
];
