import { FormProvider, useForm } from "react-hook-form";
import { InputHookForm } from "../../Common/Form/input-with-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataSchema } from "./schema";
import { Text, Tooltip } from "@chakra-ui/react";
import { MenuHookForm } from "../../Common/Form/menu-with-hook-form";
import { ReactComponent as DesTooltipIcon } from "../../../assets/des-tooltip-icon.svg";
import Button from "../../Button";
import { useEffect, useState } from "react";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { TextAreaHookForm } from "../../Common/Form/textarea-hook-form";
import {
  EQUITY_PERCENTAGE_TOOLTIP,
  UPLOAD_FILE_METADATA_DESC
} from "../../../constants/twygs.constant";
import FormInputCounter from "./InputCountCharacter/form-input-counter.component";
import { fetchRequestContentTypes } from "../../../apis/request.api";
import { useNavigate } from "react-router-dom";
import { useToast } from "@chakra-ui/react";
import ToastMessage from "../../ToastMessage";
import { useRef } from "react";
import {
  ALLOWED_FILE_TYPES_THUMBNAIL,
  FILE_TYPES,
  getContentType,
  handleFileNameExtension,
  MAX_FILE_SIZE_1
} from "../../../constants/image.constant";
import UploadIcon from "../../../assets/upload-green-icon.svg";
import TrashIcon from "../../../assets/bin-red-icon.svg";
import DropzoneTwygsComponent from "../../Common/dropzone-twygs.component";
import axios from "axios";
import { postAssetUpload } from "../../../apis/article.api";
import { createResponse } from "../../../apis/response.api";
import CreateResponseModal from "./create-response-modal.component";
import { FieldHookForm } from "../../Common/Form/hook-form/field-hook-form";

const ResponseEnterMetadataComponent = () => {
  const [error, setError] = useState("");
  const [selectedFile, setSelectedFile] = useState(null);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [listContentType, setListContentType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState("");
  const [fileImg, setFileImg] = useState("");
  const fileInputRef = useRef();
  const toast = useToast();

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(metadataSchema)
  });

  const handleCreateResponse = async (formValue) => {
    try {
      let filenameUpload = handleFileNameExtension(selectedFile?.name, selectedFile?.type);
      let contentCloudKey = [];

      const match = window?.location?.href?.match(/\/request\/([^\/]+)\//);
      const requestId = match ? match[1] : null;
      if (!requestId) throw new Error("Invalid request id");

      if (selectedFile && fileImg) {
        setLoading(true);
        contentCloudKey = await postAssetUpload({
          fileName: [filenameUpload]
        });
        const url = contentCloudKey?.[0]?.url;
        await axios.put(url, selectedFile, {
          headers: {
            "content-disposition": "inline",
            "Content-Type": getContentType(filenameUpload.toLowerCase()),
            "x-amz-acl": "public-read"
          }
        });
      }

      await createResponse({
        contentTypeIds: formValue.contentTypeIds.map((contentType) => contentType.id),
        note: formValue.note,
        equityPercentage: Number(formValue.equityPercentage),
        title: formValue.title,
        contentCloudKey: contentCloudKey[0]?.key,
        requestId
      }).then(() => {
        setModal("create-success");
      });
    } catch (error) {
      console.error(error);
      toast({
        render: () => (
          <ToastMessage
            title={"Create request failed"}
            isError={true}
            color="jet-black"
          ></ToastMessage>
        ),
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  const validateFiles = (file) => {
    let messageError = "";

    if (!ALLOWED_FILE_TYPES_THUMBNAIL.includes(file?.type)) {
      messageError = "Please ensure your file type is PNG, JPG or JPEG";
    }

    if (file?.size > MAX_FILE_SIZE_1) {
      messageError = "Please ensure your file size is less than 1MB";
    }
    return messageError;
  };

  const handleUploadImage = (file) => {
    setFileImg("");
    const newFile = file[0];
    setError(validateFiles(newFile));
    if (newFile && !validateFiles(newFile)) {
      setSelectedFile(newFile);
      const fakeUploadedUrl = URL.createObjectURL(newFile); // Replace with real upload logic
      setFileImg(fakeUploadedUrl);
    }
  };

  const handleClickReUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleReUpload = (value) => {
    handleUploadImage([value.target.files[0]]);
  };

  const handleClickRemove = () => {
    setSelectedFile("");
    setFileImg("");
    setError("");
  };

  const handleBackToSubmissionScreen = () => {
    navigate("/article/manage?tab=submissions");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicsRes = await fetchRequestContentTypes();
        if (topicsRes) setListContentType(topicsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  const customYupValidator = {
    handleFieldChange: (event, methods, fieldName) => {
      methods.clearErrors(fieldName);
      methods.setValue(fieldName, event.target.value);
      methods.trigger(fieldName);
    },
    handleFormSubmit: async (event) => {
      event.preventDefault();

      // custom yup submit form
      await Promise.all([
        methods.trigger("title"),
        methods.trigger("contentTypeIds"),
        methods.trigger("equityPercentage"),
        methods.trigger("note"),
        new Promise(async (resolve) => {
          let filenameUpload = handleFileNameExtension(selectedFile?.name, selectedFile?.type);
          if (!filenameUpload) {
            await methods.trigger("content");
            setError("Content is required");
            resolve(false);
          }
          resolve(true);
        })
      ]).then((values) => {
        if (values.every((value) => value === true)) handleCreateResponse(methods.getValues());
      });
    }
  };

  return (
    <div className="">
      <div>
        <FormProvider {...methods}>
          <form onSubmit={customYupValidator.handleFormSubmit}>
            <div className="flex flex-col gap-4 w-full">
              <FormInputCounter
                name="title"
                placeholder="ex.: Photo of a dog"
                label="Submission title"
                maxLength={100}
                component={InputHookForm}
              />
              <MenuHookForm
                name="contentTypeIds"
                label="Content Type"
                isShowCheckBox
                placeholder="Image, Video, Text"
                data={listContentType || []}
              />
              <FormInputCounter
                component={TextAreaHookForm}
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
                name="note"
                placeholder="Share any background information or context for your content..."
                label="Note"
                maxLength={2000}
                onChange={(event) => {
                  customYupValidator.handleFieldChange(event, methods, "note");
                }}
              />
              <div>
                <div className="mb-6">
                  <h1>Content</h1>
                  <p className="text-sm !text-[#808080]">This file will be viewed by the writer.</p>
                </div>
                <div>
                  {fileImg && !error ? (
                    <div>
                      <div className="flex items-center gap-2.5 text-sm mb-2">
                        <input
                          type="file"
                          id="file-input"
                          ref={fileInputRef}
                          onChange={handleReUpload}
                          hidden
                        />
                        <Button
                          variant="secondary"
                          className="!px-4"
                          onClick={(e) => handleClickReUpload(e)}
                        >
                          <img src={UploadIcon} className="mr-2" alt="Upload" />
                          Choose another file
                        </Button>
                        <Button variant="red-outline" className="!px-4" onClick={handleClickRemove}>
                          <img src={TrashIcon} className="mr-2" alt="Upload" />
                          Remove
                        </Button>
                      </div>

                      <img
                        src={fileImg}
                        className="md:max-w-[592px] md:min-w-[592px] md:max-h-[360px] md:min-h-[360px] rounded-[8px] object-cover"
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <FieldHookForm name="content" label="">
                      <DropzoneTwygsComponent
                        acceptFileTypes={FILE_TYPES.twygsImage}
                        isError={error}
                        className="!h-[214px] md:!h-[360px]"
                        title={
                          <p className="!text-[#808080] text-sm">
                            <span className="!text-[#00A375] font-[900]">Click to upload file</span>{" "}
                            or drag and drop
                          </p>
                        }
                        notMutiple
                        validateFiles={handleUploadImage}
                        desc={UPLOAD_FILE_METADATA_DESC.image.map((item, index) => (
                          <Text key={index} fontWeight="400" color="dim-gray" fontSize="xs">
                            {item}
                          </Text>
                        ))}
                      />
                    </FieldHookForm>
                  )}
                </div>
              </div>
              {/* Equitity Percentage */}
              <div>
                <div className="flex items-center mb-[12px]">
                  <p className="mr-1">Equity percentage</p>
                  <Tooltip
                    rounded="0.75rem"
                    label={EQUITY_PERCENTAGE_TOOLTIP}
                    placement={"top-start"}
                    zIndex={0}
                    bg="#E6E7EB"
                    p={{ base: "10px", "2xl": "1rem" }}
                    left={{ base: "100px", md: "0" }}
                    fontSize={"xs"}
                    color={"#333333"}
                    isOpen={isTooltipOpen}
                    transform={{
                      base: "translatex(-55%) !important",
                      md: "none !important"
                    }}
                  >
                    <DesTooltipIcon
                      cursor="pointer"
                      className="w-[20px] h-[20px] z-0"
                      alt=""
                      onMouseEnter={() => handleMouseEnter("description")}
                      onMouseLeave={() => handleMouseLeave("description")}
                      onClick={() => handleImageClick("description")}
                    />
                  </Tooltip>
                </div>
                <FormInputCounter
                  name="equityPercentage"
                  placeholder="ex: 2%, 5%..."
                  label=""
                  component={InputHookForm}
                  isShowRange={false}
                />
              </div>
            </div>
            <div className="bg-white flex flex-col gap-3 md:flex-row justify-end border-t-[1px] border-solid border-[#E6E7EB] fixed left-0 bottom-0 z-20 py-[15px] md:py-[20px] px-[15px] md:px-[40px] w-full">
              <Button variant="outline" onClick={handleBackToSubmissionScreen}>
                Discard
              </Button>
              <Button type="submit" isLoading={loading}>
                Submit
                <img src={ArrowRightIcon} alt="" className="ml-2" />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      {modal === "create-success" ? (
        <CreateResponseModal
          isOpen
          title="Response submitted"
          onClose={handleBackToSubmissionScreen}
          onSubmit={handleBackToSubmissionScreen}
          titleBtn="View My Submissions"
          minW="439px"
        />
      ) : null}
    </div>
  );
};

export default ResponseEnterMetadataComponent;
