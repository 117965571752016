import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import PaginationComponent from "../../Pagination";
import NoDataComponent from "../../NoData";
import TableComponent from "../../Table";
import InputSearchComponent from "../../Common/Form/input-search";
import { formatDate } from "../../../utils/helper.util";
import ChevronDownIcon from "../../../assets/chevron-down-green-icon.svg";
import EyeGreenIcon from "../../../assets/eye-light-green-icon.svg";
import EditIcon from "../../../assets/edit-icon.svg";
import EditDisabledIcon from "../../../assets/edit-disabled-icon.svg";
import RedirectIcon from "../../../assets/redirect-icon-upper.svg";
import DeleteIcon from "../../../assets/bin-red-icon.svg";
import PlusIcon from "../../../assets/plus-white-icon.svg";
import { useNavigate } from "react-router-dom";
import {
  deleteRequest,
  fetchListRequests,
  fetchRequestContentTypes
} from "../../../apis/request.api";
import ConfirmModal from "../../Modal/ConfirmModal";
import FailLoadImage from "../../../assets/failed-to-load.svg";

export const RequestTableComponent = () => {
  const navigate = useNavigate();
  const [modal, setModal] = useState("");
  const [selectedRequest, setSelectedRequest] = useState({});

  const [dataTable, setDataTable] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 4,
    page: 1,
    sortBy: undefined,
    sort: undefined,
    search: undefined,
    userId: JSON.parse(localStorage.getItem("user"))?.id
  });
  const [type, setType] = useState({
    id: undefined,
    value: ""
  });

  // list requests params
  const params = {
    ...pagination,
    contentTypeIds: [type.id]
  };
  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined)
  );

  const {
    data: getListRequests,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ["getListRequests", filteredParams],
    queryFn: () => fetchListRequests(filteredParams)
  });

  const { data: getListTopic } = useQuery({
    queryFn: fetchRequestContentTypes
  });

  const { mutateAsync: deleteRequestAsync } = useMutation({
    mutationFn: deleteRequest
  });

  const handleChangePage = (page) => {
    setDataTable(() => []);
    setPagination((pre) => {
      return {
        ...pre,
        page
      };
    });
  };

  const handleChangeSearch = (search) => {
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
        search: search
      };
    });
  };

  const handleChangeType = (id, name) => () => {
    setType({ id: id, value: name });
    setPagination((pre) => {
      return {
        ...pre,
        page: 1
      };
    });
  };

  const handleDeleteRequest = async (id) => {
    try {
      await deleteRequestAsync(id);
      setModal("");
      refetch();
    } catch (error) {
      console.error("Error deleting request:", error);
      throw error;
    }
  };

  const handleShowConfirmDeleteModal = (data) => {
    setSelectedRequest(data);
    setModal("remove-item");
  };

  const convertArticles = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box ml={"12px"}>
            <Box>
              <Text
                fontWeight={800}
                fontSize={"16px"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.title}
              </Text>
            </Box>
            <Text
              wordBreak={"break-word"}
              fontSize={"14px"}
              title={row?.description}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              sx={{
                display: "-webkit-inline-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical"
              }}
            >
              {row?.description}
            </Text>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertType = useCallback((row) => {
    return (
      <Box>
        {row?.contentTypes?.map((item) => (
          <Box
            key={item.id}
            px={"8px"}
            py={"4px"}
            rounded={"80px"}
            bgColor={"#F0EDEC"}
            w={"fit-content"}
            whiteSpace={"nowrap"}
            marginBottom={"6px"}
          >
            <Text title={item?.name} color="#6A4E42" fontWeight={800} fontSize={"xs"}>
              {item?.name}
            </Text>
          </Box>
        ))}
      </Box>
    );
  }, []);

  const convertStatus = useCallback((row) => {
    const isClosed = row.status === "closed";
    return (
      <Box p={"12px"} rounded={"80px"} bgColor={isClosed ? "#4CAF50" : "#EDF7EE"} w={"fit-content"}>
        <Text
          color={isClosed ? "#FFFFFF" : "#204A22"}
          fontWeight={isClosed ? 800 : 600}
          fontSize={"xs"}
        >
          {isClosed ? "Closed" : "Ongoing"}
        </Text>
      </Box>
    );
  }, []);

  const convertRequestLink = useCallback((row) => {
    return (
      <Box className="grid grid-cols-[auto,auto]">
        <a
          href={`${process.env.REACT_APP_NEWSLETTER_URL}request/${row.id}/detail`}
          target="_blank"
          rel="noreferrer"
          className="text-[#4CAF50] font-black whitespace-nowrap"
        >
          Open Link
        </a>
        <div className="flex items-end w-[20px]">
          <img src={RedirectIcon} alt="" className="" />
        </div>
      </Box>
    );
  }, []);

  const convertAction = useCallback((row) => {
    return (
      <Box display="flex" gap="12px" alignItems={"center"}>
        <img
          src={EyeGreenIcon}
          className="cursor-not-allowed p-[10px] hover:opacity-80"
          alt="icon"
          onClick={(event) => {
            if (true) return event.preventDefault();
            navigate(`/request/${row.id}`);
          }}
        />
        <img
          //row.status === "closed"
          src={EditIcon}
          className="cursor-not-allowed p-[10px] hover:opacity-80"
          alt="icon"
          onClick={(event) => {
            if (true) return event.preventDefault();
            navigate(`/article/edit?id=${row?.id}`);
          }}
        />
        <img
          src={DeleteIcon}
          className="p-[10px] cursor-pointer hover:opacity-80"
          alt="icon"
          onClick={() => handleShowConfirmDeleteModal(row)}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (sortName) => {
    let objSort = {
      sortBy: pagination.sortBy,
      sort: pagination.sort
    };
    if (sortName !== objSort.sortBy) objSort = { sortBy: sortName, sort: "asc" };
    else if (sortName === objSort.sortBy && objSort.sort === "asc")
      objSort = {
        ...objSort,
        sort: "desc"
      };
    else if (sortName === objSort.sortBy && objSort.sort === "desc")
      objSort = {
        ...objSort,
        sort: "asc"
      };
    setPagination({ ...pagination, ...objSort });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Requests",
        accessor: (row) => convertArticles(row),
        id: "title",
        isSortField: true,
        widthCustom: "calc(100% - 35.5rem)"
      },
      {
        Header: "Type",
        accessor: (row) => convertType(row),
        id: "requestTypes",
        widthCustom: "12rem",
        minWidthCustom: "12rem"
      },
      {
        Header: "Status",
        accessor: (row) => convertStatus(row),
        id: "isPublished",
        widthCustom: "7rem",
        minWidthCustom: "7rem"
      },
      {
        Header: "Request link",
        accessor: (row) => convertRequestLink(row),
        id: "requestLink",
        widthCustom: "5rem",
        isSortField: false,
        minWidthCustom: "5rem"
      },
      {
        Header: "Date Sent",
        accessor: (row) => <Text fontSize={"sm"}>{formatDate(row?.createdAt, true)}</Text>,
        id: "createdAt",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
        isSortField: true
      },
      {
        Header: "Actions",
        accessor: (row) => convertAction(row),
        widthCustom: "16rem",
        minWidthCustom: "16rem",
        id: "action"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertArticles, convertType, convertStatus, convertRequestLink, convertAction, handleSort]
  );

  useEffect(() => {
    if (getListRequests) {
      setDataTable(getListRequests?.data);
    }
  }, [getListRequests, type.id]);

  return (
    <Box
      w={"full"}
      border={"1px solid"}
      borderColor={"anti-flash-lite"}
      borderRadius={"12px"}
      shadow={"outline"}
    >
      <Box
        bg={"white"}
        p={"16px 24px"}
        display={"flex"}
        alignItems={"start"}
        minH={"72px"}
        borderTopRadius={"12px"}
        justifyContent={"space-between"}
        overflow={"auto"}
      >
        <Box
          display={"flex"}
          gap="12px"
          flexDirection={{ base: "column", md: "row" }}
          width={"full"}
          overflow={"auto"}
        >
          <InputSearchComponent
            setPaginationSearch={handleChangeSearch}
            placeholder={"Search requests"}
            setLoading={() => {}}
            width={{ base: "100%", md: "320px" }}
          />
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={
                <div className="ml-[16px]">
                  <img src={ChevronDownIcon} alt="icon" />
                </div>
              }
              height={"42px"}
              w={"fit-content"}
              variant={"growth-green-solid"}
              px={"18px"}
            >
              <Text
                color={"#4CAF50"}
                fontWeight={900}
                textAlign={"start"}
                fontSize={{ base: "sm", "2xl": "md" }}
              >
                {type.value === "" ? "Type" : type.value}
              </Text>
            </MenuButton>
            <MenuList
              p={"0.5rem"}
              borderRadius={"0.5rem"}
              minW={"130px"}
              shadow={"md"}
              border={"1px solid"}
              borderColor={"light-gray"}
              maxH={"300px"}
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#e6e7eb",
                  borderRadius: "30px"
                }
              }}
            >
              {[{ id: undefined, name: "All Types", desc: "" }, ...(getListTopic || [])]?.map(
                (item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeType(item.id, item.name)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block"
                        }
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
          <div
            onClick={() => navigate("/request/create")}
            className="md:ml-[auto] cursor-pointer h-[42px] flex items-center gap-2 bg-growth-green px-[20px] w-fit-content py-[12px] text-center !rounded-[8px] !text-white text-sm 2xl:text-base font-black hover:opacity-80"
          >
            <p>New Request</p>
            <img src={PlusIcon} alt="plus" />
          </div>
        </Box>
      </Box>
      <Box
        bg={"white"}
        rounded={"0 0 0.75rem 0.75rem"}
        display={"flex"}
        minH={"550px"}
        flexFlow={"column"}
        overflow={{ base: "auto", md: "hidden" }}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#e6e7eb",
            borderRadius: "30px"
          }
        }}
      >
        <TableComponent
          isLoading={isFetching}
          columns={columns}
          data={dataTable ?? []}
          headerHeight={"46px"}
          fontsizeTable={{ base: "sm", "2xl": "md" }}
          handleSort={handleSort}
          currentSort={{ sortBy: pagination.sortBy, sort: pagination.sort }}
          haveFooter={false}
        />
        {dataTable?.length === 0 && !isFetching ? (
          <Box minH={"500px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <NoDataComponent isFetching={isFetching} />
          </Box>
        ) : null}

        {getListRequests?.totalPage > 0 ? (
          <Box
            borderBottomRadius={"12px"}
            p={"1.25rem 1.5rem"}
            mt={"auto"}
            borderTop="1px solid"
            borderColor={"anti-flash-lite"}
            background={"light.100"}
          >
            <PaginationComponent
              currentPage={pagination.page}
              totalPages={getListRequests?.totalPage}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : null}
      </Box>
      {modal === "remove-item" ? (
        <ConfirmModal
          isOpen={true}
          Icon={FailLoadImage}
          title={"Delete Request?"}
          desc={"This action will permanently delete your request"}
          titleBtn={"Delete"}
          onClick={() => {
            handleDeleteRequest(selectedRequest?.id);
          }}
          isLoading={false}
          onClose={() => {
            setModal("");
          }}
        />
      ) : null}
    </Box>
  );
};
