import AvatarDefault from "../../../../assets/ava-icon.svg";
import VerifiedIcon from "../../../../assets/verified-icon.svg";
import { formatNumber } from "../../../../utils/helper.util";
import Button from "../../../Button";

const CardAuthor = (props) => {
  const { author, isHiddenInfo, handleFollowUser, isSmallText, isLoading } = props;
  return (
    <div className="flex items-start gap-4 @container">
      <img
        className="w-[40px] h-[40px] xl:w-[80px] xl:h-[80px] rounded-full"
        src={author?.imgUrl || AvatarDefault}
        alt=""
      />
      <div className="flex flex-col gap-2">
        <div className="flex items-center gap-4">
          <div className="flex items-center gap-1 max-w-[200px] md:max-w-full">
            <p
              className={`font-[600] ${isSmallText ? "text-base" : "text-sm xl:text-2xl lg:font-[800] truncate"}`}
              title={author?.firstName + " " + author?.lastName}
            >
              {author?.firstName || author?.lastName
                ? `${author.firstName} ${author.lastName}`
                : "Anonymous"}
            </p>
            {author?.isVerified && <img src={VerifiedIcon} alt="" />}
          </div>

          <Button
            className="hidden @lg:!block px-[14px] py-[8px] text-sm !font-[700]"
            variant="primary"
            onClick={() => handleFollowUser(author.id, author.isFollowing)}
            isLoading={isLoading}
            disabled={isLoading}
          >
            {author?.isFollowing ? "Following" : "Follow"}
          </Button>
        </div>
        <div
          className={`flex items-center flex-wrap gap-2 md:gap-[40px] ${isHiddenInfo ? "hidden" : ""}`}
        >
          <p className="text-sm 2xl:text-xl font-[800]">
            {formatNumber(author?.followerNumber)}{" "}
            <span className="font-[400]">
              {author?.followerNumber > 1 ? "followers" : "follower"}
            </span>
          </p>
          <p className="text-sm 2xl:text-xl font-[800]">
            {formatNumber(author?.followingNumber)} <span className="font-[400]">following</span>
          </p>
          <p className="text-sm 2xl:text-xl font-[800]">
            {formatNumber(author?.contributionNumber)}{" "}
            <span className="font-[400]">
              {author?.contributionNumber > 1 ? "articles" : "article"}
            </span>
          </p>
        </div>
        <div
          className={`${isHiddenInfo ? "hidden" : ""} max-h-[64px] overflow-hidden flex items-center flex-wrap gap-2`}
        >
          {author?.topics?.map((elm) => {
            return (
              <p
                className="bg-[#F0EDEC] rounded-[80px] w-fit !text-[#6A4E42] font-semibold text-xs px-2 py-1"
                key={elm.id}
              >
                {elm.name}
              </p>
            );
          })}
        </div>
        <p className={`italic ${isSmallText ? "text-base" : "text-sm xl:text-xl"} line-clamp-2`}>
          {author?.description || "No description"}
        </p>
      </div>
      {/* Mobile view */}
      <Button
        className="block @lg:!hidden px-[14px] py-[8px] text-sm !font-[700] ml-auto"
        variant="primary"
        onClick={() => handleFollowUser(author.id, author.isFollowing)}
        isLoading={isLoading}
        disabled={isLoading}
      >
        {author?.isFollowing ? "Following" : "Follow"}
      </Button>
    </div>
  );
};

export default CardAuthor;
