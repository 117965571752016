import { protectedRoutes } from "../../../../constants/routes";
import TopicLoading from "./topic-loading.component";
import { useNavigate } from "react-router-dom";
import ChevronRightWhiteIcon from "../../../../assets/chevron-right-white.svg";
import { useEffect } from "react";
import { fetchListRecommendedTopics } from "../../../../apis/discovery.api";
import { useState } from "react";
import { useAtomValue } from "jotai";
import { profileAtom } from "../../../Settings/atom";

const RecommendedTopics = () => {
  const navigate = useNavigate();
  const [listRecommendedTopics, setListRecommendedTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const profile = useAtomValue(profileAtom);

  const handleRedirectToRecommendTopics = (id) => {
    navigate(`${protectedRoutes.RECOMMEND_TOPICS}?topicId=${id}`);
  };

  useEffect(() => {
    const getRecommendedTopics = async () => {
      try {
        const result = await fetchListRecommendedTopics({
          page: 1,
          limit: 5,
          userId: profile.id
        });
        setListRecommendedTopics(result);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getRecommendedTopics();
  }, [profile.id]);

  return (
    <div>
      <h1 className="text-xl 2xl:text-2xl !text-[#6A4E42] font-[700] mb-[24px]">
        Recommended Topics
      </h1>
      {loading && <TopicLoading />}
      <div className="w-[100%] 2xl:gap-[16px]">
        {listRecommendedTopics?.map((item) => (
          <div key={item.id} className="mb-[16px] flex items-center">
            <p className="inline text-base 2xl:text-xl !text-[#6A4E42] font-[800]">{item.name}</p>
            <p className="inline text-base 2xl:text-xl !text-[#BAAEA8] font-[600] ml-[4px]">{`(${item.total || 0})`}</p>
            <div
              className="inline-block rounded-full bg-[#BAAEA8] ml-[8px] p-1 cursor-pointer"
              onClick={() => {
                handleRedirectToRecommendTopics(item.id);
              }}
            >
              <img src={ChevronRightWhiteIcon} alt="" />
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default RecommendedTopics;
