import { useState } from "react";
import { Box, forwardRef, Image, Skeleton } from "@chakra-ui/react";

const ImageComponent = forwardRef((props, ref) => {
  const { ...rest } = props;
  const [isLoading, setIsLoading] = useState(true);

  const onLoad = (e) => {
    setIsLoading(false);
    if (rest.onLoad) rest.onLoad(e);
  };

  const onError = (e) => {
    setIsLoading(false);
    if (rest.onError) rest.onError(e);
  };

  return (
    <Box position={"relative"} width={"100%"} height={"100%"} borderRadius={rest.borderRadius}>
      <Box
        width={"100%"}
        height={"100%"}
        opacity={isLoading ? 0 : 1}
        display={"flex"}
        justifyContent={"center"}
        alignItems={"center"}
      >
        <Image
          alt="image"
          fallbackSrc=""
          fallbackStrategy={"onError"}
          {...rest}
          onLoad={onLoad}
          onError={onError}
          ref={ref}
          objectFit="cover"
        />
      </Box>
      {isLoading && (
        <Box
          position={"absolute"}
          top={0}
          left={0}
          width={"100%"}
          height={"100%"}
          rounded={"0.5rem"}
          overflow={"hidden"}
        >
          <Skeleton startColor="white" endColor="light-gray" h={"full"} w={"full"} />
        </Box>
      )}
    </Box>
  );
});

ImageComponent.displayName = "ImageComponent";

export default ImageComponent;
