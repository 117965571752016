import type { SerializedLexicalNode, LexicalNode, NodeKey, EditorConfig } from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const DraggableImageComponent = React.lazy(() => import("./DraggableImage"));

export interface DraggableImagePayload {
  altText: string;
  height: number;
  width: number;
  key?: NodeKey;
  src: string;
  x: number;
  y: number;
}

export type SerializedInlineImageNode = SerializedLexicalNode & DraggableImagePayload;

export class DraggableImageNode extends DecoratorNode<JSX.Element> {
  __src: string;
  __altText: string;
  __width: number;
  __height: number;
  __x: number;
  __y: number;

  constructor(
    src: string,
    altText: string,
    width: number,
    height: number,
    x: number,
    y: number,
    key?: NodeKey
  ) {
    super(key);
    this.__src = src;
    this.__altText = altText;
    this.__width = width;
    this.__height = height;
    this.__x = x;
    this.__y = y;
  }

  static getType(): string {
    return "draggable-image";
  }

  static clone(node: DraggableImageNode): DraggableImageNode {
    return new DraggableImageNode(
      node.__src,
      node.__altText,
      node.__width,
      node.__height,
      node.__x,
      node.__y,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedInlineImageNode): DraggableImageNode {
    const { altText, height, src, width, x, y } = serializedNode;
    const node = $createInlineImageNode({
      altText,
      height,
      src,
      width,
      x,
      y
    });
    return node;
  }

  setDimensions(width: number, height: number): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setPosition(x: number, y: number): void {
    const writable = this.getWritable();
    writable.__x = x;
    writable.__y = y;
  }

  getPosition(): { x: number; y: number } {
    return { x: this.__x, y: this.__y };
  }

  exportJSON(): SerializedInlineImageNode {
    return {
      altText: this.__altText,
      height: this.__height,
      src: this.__src,
      type: "draggable-image",
      version: 1,
      width: this.__width,
      x: this.__x,
      y: this.__y
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span");
    const className = config.theme.inlineImage;
    if (className !== undefined) {
      span.className = className;
    }
    span.style.display = "inline-block";
    span.style.width = "0";
    span.style.height = "0";
    span.style.position = "relative";
    span.style.userSelect = "none";
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <DraggableImageComponent
          src={this.__src}
          altText={this.__altText}
          nodeKey={this.getKey()}
          width={this.__width}
          height={this.__height}
          x={this.__x}
          y={this.__y}
          updateDimensions={this.setDimensions}
        />
      </Suspense>
    );
  }
}

export function $createInlineImageNode({
  altText,
  height,
  src,
  width,
  x,
  y,
  key
}: DraggableImagePayload): DraggableImageNode {
  return new DraggableImageNode(src, altText, width, height, x, y, key);
}

export function $isInlineImageNode(
  node: LexicalNode | null | undefined
): node is DraggableImageNode {
  return node instanceof DraggableImageNode;
}
