const CardArticleLoading = ({ index, isHiddenFeaturedArticles }) => {
  return (
    <div className={`w-full flex items-center gap-[24px] animate-pulse`}>
      <div
        className={`flex flex-col gap-[20px] ${index === 0 && !isHiddenFeaturedArticles ? "w-[40%]" : "w-[60%]"}`}
      >
        <div className="flex items-center gap-[8px]">
          {/* Avatar Placeholder */}
          <div className="w-[32px] h-[32px] bg-gray-300 rounded-full"></div>
          {/* Creator Name Placeholder */}
          <div className="w-[120px] h-[20px] bg-gray-300"></div>
        </div>

        <div className="flex flex-col gap-[8px]">
          <div className="flex gap-2">
            {/* Date and Time Read Placeholder */}
            <div className="w-[100px] h-[16px] bg-gray-300"></div>
            <div className="w-[60px] h-[16px] bg-gray-300 ml-10"></div>
          </div>

          {/* Title Placeholder */}
          <div
            className={`${index === 0 && !isHiddenFeaturedArticles ? "h-[32px]" : "h-[24px]"} w-full bg-gray-300 rounded-md`}
          ></div>

          {/* Description Placeholder */}
          <div className="h-[24px] w-full bg-gray-300 rounded-md"></div>

          {/* Topics Placeholder */}
          <div className="flex gap-2 mt-2">
            <div className="w-[60px] h-[20px] bg-gray-300 rounded-full"></div>
            <div className="w-[80px] h-[20px] bg-gray-300 rounded-full"></div>
          </div>
        </div>

        {/* Reactions, Comments, and Icons Placeholder */}
        <div className="flex items-center mt-2">
          <div className="flex items-center relative">
            <div className="w-[32px] h-[32px] bg-gray-300 rounded-full"></div>
            <div className="w-[32px] h-[32px] bg-gray-300 rounded-full left-5 absolute !z-[-1]"></div>
            <div className="ml-7 w-[50px] h-[20px] bg-gray-300"></div>
          </div>
          <div className="flex items-center gap-2 ml-5">
            <div className="w-[20px] h-[20px] bg-gray-300 rounded-full"></div>
            <div className="w-[30px] h-[20px] bg-gray-300"></div>
          </div>
          <div className="flex items-center ml-auto gap-2">
            <div className="w-[20px] h-[20px] bg-gray-300 rounded-full"></div>
            <div className="w-[20px] h-[20px] bg-gray-300 rounded-full"></div>
            <div className="w-[20px] h-[20px] bg-gray-300 rounded-full"></div>
          </div>
        </div>
      </div>

      <div
        className={`${index === 0 && !isHiddenFeaturedArticles ? "w-[60%]" : "w-[40%]"} relative`}
      >
        {/* Image Placeholder */}
        <div
          className={`${
            index === 0 && !isHiddenFeaturedArticles
              ? "h-[210px] md:h-[250px] 2xl:h-[360px]"
              : "h-[140px] md:h-[200px] 2xl:h-[272px]"
          } bg-gray-300 rounded-[8px] w-full`}
        ></div>
      </div>
    </div>
  );
};

export default CardArticleLoading;
