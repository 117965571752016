import { Helmet } from "react-helmet";
import ResponseEnterMetadataComponent from "../../components/CreateResponse/components/enter-metadata.component";
import UserCard from "../../components/Profile/user-card";
import RequestCard from "../../components/CreateRequest/components/request-card.component";
import { useEffect } from "react";
import { useState } from "react";
import { fetchRequestDetail } from "../../apis/request.api";

const DetailRequest = () => {
  const [request, setRequest] = useState({});

  useEffect(() => {
    const fetchData = async () => {
      try {
        const match = window.location.href.match(/\/request\/([^\/]+)\//);
        const requestId = match ? match[1] : null;

        const requestRes = await fetchRequestDetail(requestId);
        if (requestRes) setRequest(requestRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div>
      <Helmet>
        <title>Detail Request</title>
      </Helmet>
      <div className={`max-w-[592px] mx-auto flex flex-col items-center mb-[150px]`}>
        <div className="bg-[#FAFAFA] px-[17.5px] pt-5 md:pt-10 pb-6 w-full text-left z-10">
          <h1 className="text-[32px] font-[800] mb-4">Content Request Details</h1>
          <UserCard user={request?.user} />
          <div className="my-[8px] md:border-b-[2px] border-solid border-[#E6E7EB]"></div>
          <RequestCard request={request} />
        </div>
        <div className="px-[17.5px] text-left w-full text-[18px] font-[600] mb-[24px]">
          Contribute to the article by filling out the form below:
        </div>
        <div className="px-[17.5px] w-full">
          <ResponseEnterMetadataComponent />
        </div>
      </div>
    </div>
  );
};

export default DetailRequest;
