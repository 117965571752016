import axiosInstance from "./axios-instance";

export const fetchListNotification = async (params) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}notification/get-list`,
    {
      params,
    }
  );
  if (result.status === 200) return result.data.data;
};

export const fetchListSettings = async () => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}notification/get-setting/EXPLORE`
  );
  if (result.status === 200) return result.data.data;
};

export const updateSettings = async (params) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}notification/update-setting`,
    {
      typeId: params.typeId,
      option: params.option,
    }
  );
  if (result.status === 200) return result.data.data;
};

export const GET_DISMISS =
  `${process.env.REACT_APP_TWYGS_API_URL}notification/dismiss` || "";
export const GET_MARK_AS_READ =
  `${process.env.REACT_APP_TWYGS_API_URL}notification/mark-as-read` || "";
export const GET_MARK_AS_UNREAD =
  `${process.env.REACT_APP_TWYGS_API_URL}notification/mark-as-unread` || "";
export const GET_MARK_ALL_AS_READ =
  `${process.env.REACT_APP_TWYGS_API_URL}notification/mark-all-as-read` || "";
