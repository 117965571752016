import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Image
} from "@chakra-ui/react";
import CloseModalIcon from "../../../assets/close-modal-icon.svg";
import ShareSuccessImg from "../../../assets/share-success-img.svg";
import Button from "../../Button";

function CreateResponseModal(props) {
  const { isOpen, onSubmit, title, titleBtn, onClose, bgColorOverlay } = props;

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay backgroundColor={bgColorOverlay} />
      <ModalContent p={"32px"} bgColor={"#FAFAFA"} rounded={"12px"}>
        <ModalHeader p={0} w={"100%"} m={0} maxWidth={"unset"}>
          <div className="flex w-full items-center justify-between pb-2 border-b border-[#D1C8C4]">
            <p className="text-xl font-[800]">{title}</p>
            <Image
              className="w-[40px] h-[40px] cursor-pointer"
              src={CloseModalIcon}
              onClick={onSubmit}
              alt=""
            />
          </div>
        </ModalHeader>
        <ModalBody p={0}>
          <div className="flex flex-col items-center mt-6 mb-4">
            <img className="w-[200px] h-[200px]" src={ShareSuccessImg} alt="" />
            <p className="text-[18px] font-[600] mt-3 text-center">
              Your response has been successfully submitted!
            </p>
          </div>
        </ModalBody>
        <ModalFooter w="100%" p="0">
          <Button onClick={onSubmit} className="w-full h-[56px] text-lg">
            {titleBtn}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default CreateResponseModal;
