export const ButtonTheme = {
  baseStyle: {
    ":focus:not(:focus-visible)": {
      boxShadow: "none"
    },
    colorScheme: "black",
    fontWeight: "600",
    borderRadius: "8px",
    border: "1px solid",
    boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05)"
  },

  sizes: {
    sm: {
      h: "36px",
      fontSize: "14px"
    },
    md: {
      h: "40px",
      fontSize: "14px"
    },

    lg: {
      h: "43px",
      fontSize: "16px"
    },

    xl: {
      h: "47px",
      fontSize: "16px"
    },

    xxl: {
      h: "56",
      fontSize: "18px"
    }
  },

  variants: {
    pink: {
      bg: "#FFB2E0",
      color: "#084F4B",
      border: "1px solid #FFB2E0",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFB2E0",
          borderColor: "#FFB2E0"
        }
      },
      _disabled: {
        opacity: "0.7"
      }
    },
    "growth-green": {
      bg: "#4CAF50",
      color: "#FAFAFA",
      borderRadius: "40px",
      padding: "8px 14px",
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFB2E0",
          borderColor: "#FFB2E0"
        }
      }
    },
    "growth-green-solid": {
      bg: "#FFFFFF",
      color: "#4CAF50",
      border: "2px solid #4CAF50",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFFFFF",
          border: "2px solid #F2F3F7",
          color: "#CCCCCC"
        }
      },
      _disabled: {
        opacity: 1,
        bgColor: "#FFFFFF",
        border: "2px solid #F2F3F7",
        color: "#CCCCCC",
        boxShadow: "0px 1px 2px 0px rgba(51, 51, 51, 0.05)"
      }
    },
    "pink-solid": {
      bg: "#FFFFFF",
      color: "#084F4B",
      border: "2px solid #FFB2E0",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFB2E0",
          border: "#FFB2E0"
        }
      },
      _disabled: {
        opacity: "0.7"
      }
    },
    red: {
      bg: "#DE1414",
      color: "#FFF",
      border: "1px solid #DE1414",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#DE1414",
          border: "1px solid #DE1414"
        }
      },
      _disabled: {
        opacity: "0.7"
      }
    },
    "dark-green": {
      bg: "#084F4B",
      color: "#FFF",
      border: "1px solid #084F4B",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#084F4B",
          border: "1px solid #084F4B"
        }
      },
      _disabled: {
        opacity: 0.7,
        bgColor: "#084F4B",
        border: "1px solid #F2F3F7",
        color: "#CCCCCC"
      },
      _loading: {
        bg: "#084F4B"
      }
    },
    "dark-green-solid": {
      bg: "#FFFFFF",
      color: "#084F4B",
      border: "2px solid #084F4B",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFFFFF",
          border: "2px solid #F2F3F7",
          color: "#CCCCCC"
        }
      },
      _disabled: {
        opacity: 1,
        bgColor: "#FFFFFF",
        border: "2px solid #F2F3F7",
        color: "#CCCCCC",
        boxShadow: "0px 1px 2px 0px rgba(51, 51, 51, 0.05)"
      }
    },
    "light-green": {
      bg: "#DCEED3",
      color: "#084F4B",
      border: "1px solid #DCEED3",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#DCEED3",
          border: "1px solid #DCEED3"
        }
      },
      _disabled: {
        opacity: 0.7,
        bgColor: "#DCEED3",
        border: "1px solid #F2F3F7",
        color: "#CCCCCC"
      },
      _loading: {
        bg: "#DCEED3"
      }
    },
    "error-solid": {
      bg: "#FFFFFF",
      color: "#DE1414",
      border: "2px solid #DE1414",
      fontWeight: 900,
      _hover: {
        opacity: 0.7,
        _disabled: {
          bg: "#FFFFFF",
          border: "1px solid #F2F3F7",
          color: "#CCCCCC"
        }
      },
      _disabled: {
        opacity: 1,
        bgColor: "#FFFFFF",
        border: "1px solid #F2F3F7",
        color: "#CCCCCC"
      }
    },

    primary: {
      bg: "#6C59FF",
      color: "white",
      border: "#6C59FF",
      _hover: {
        bg: "#4658F9",
        border: "#4658F9",
        opacity: 0.7,
        _disabled: {
          bg: "#4658F9",
          border: "1px solid #4658F9"
        }
      },
      _disabled: {
        opacity: "0.5"
      }
    },
    "secondary-gray": {
      bg: "#FFFFFF",
      color: "#344054",
      borderColor: "#6C59FF",
      _hover: {
        bg: "#F9FAFB",
        opacity: 0.7
      },
      _disabled: {
        opacity: "0.5",
        borderColor: "#EAECF0"
      }
    },
    "secondary-color": {
      bg: "#F9F5FF",
      color: "#6C59FF",
      borderColor: "#F9F5FF",
      boxShadow: "none",
      _hover: {
        bg: "#F4EBFF",
        color: "#6C59FF",
        borderColor: "#F4EBFF"
      },
      _active: {
        boxShadow: "0px 1px 2px rgba(16, 24, 40, 0.05), 0px 0px 0px 4px #F4EBFF"
      },
      _disabled: {
        opacity: "0.5",
        color: "#6C59FF"
      }
    },
    tertiary: {
      bg: "#EAECF0",
      color: "#344054",
      borderColor: "#EAECF0",
      boxShadow: "none"
    },
    "tertiary-gray": {
      bg: "#FFFFFF",
      color: "#344054",
      borderColor: "#D0D5DD",
      boxShadow: "none",
      _hover: {
        bg: "#F9FAFB"
      },
      _disabled: {
        opacity: "0.5"
      }
    }

    // "tertiary-color": {
    //   bg: "transparent",
    //   color: "#4658F9",
    //   border: "none",
    //   _hover: {
    //     bg: "#F9F5FF",
    //     color: "#6C59FF"
    //   },
    //   _disabled: {
    //     opacity: "0.5",
    //     color: "#475467"
    //   }
    // },
    // "link-gray": {
    //   bg: "transparent",
    //   color: "#475467",
    //   border: "none",
    //   _hover: {
    //     color: "#344054"
    //   },
    //   _disabled: {
    //     opacity: "0.5"
    //   }
    // },
    // "link-color": {
    //   bg: "transparent",
    //   color: "#4658F9",
    //   border: "none",
    //   _hover: {
    //     color: "#6C59FF"
    //   },
    //   _disabled: {
    //     opacity: "0.5",
    //     color: "#475467"
    //   }
    // },
    // navButton: {
    //   color: "brand.50",
    //   _hover: {
    //     bg: "#8E80FF"
    //   },
    //   _activeLink: {
    //     color: "white",
    //     bg: "#8E80FF"
    //   },
    //   _selected: {
    //     color: "white",
    //     bg: "#8E80FF"
    //   }
    // }
  },

  defaultProps: {
    size: "xxl",
    borderRadius: "24px"
  }
};
