import HeartIcon from "../../../../assets/heart-icon.svg";
import ReactionIcon from "../../../../assets/reaction-icon.svg";
import CommentIcon from "../../../../assets/comment-article-icon.svg";
import ShareIcon from "../../../../assets/share-gray-icon.svg";
import IgnoreIcon from "../../../../assets/ignore-gray-icon.svg";
import SaveIcon from "../../../../assets/save-gray-icon.svg";
import SavedIcon from "../../../../assets/saved-gray-icon.svg";
import DefaultThumbnail from "../../../../assets/default-thumnail.png";
import AvatarDefault from "../../../../assets/ava-icon.svg";
import { convertCountLike } from "../../../../constants";
import { format } from "date-fns";
import { saveArticle, unsaveArticle } from "../../../../apis/article.api";
import { useToast } from "@chakra-ui/react";
import ToastBtnMessage from "../../../ToastBtnMessage";
import { useEffect, useState } from "react";
import ShareContentModal from "../../../ShareContentModal";
import { useNavigate } from "react-router-dom";

const CardArticle = ({ item, index, isHiddenFeaturedArticles, isPreview }) => {
  const [modal, setModal] = useState("");
  const navigate = useNavigate();
  const [isSaved, setIsSaved] = useState(item.isSaved);
  const toast = useToast();

  async function handleSaveArticle(articleId) {
    let message = "Error";
    let isError = false;
    let btnTitle = undefined;
    let btnAction = undefined;

    if (isSaved) {
      await unsaveArticle(articleId)
        .then(() => {
          message = "Article has been removed from your Reading List successfully!";
          btnTitle = "View Reading List";
          btnAction = () => {
            navigate("?tab=myReading");
          };
          setIsSaved(false);
        })
        .catch((error) => {
          message = "Couldn’t remove article from your Reading List!";
          isError = true;
          btnTitle = "retry";
          btnAction = () => {
            handleSaveArticle(articleId);
          };
          console.log(error);
        });
    } else {
      await saveArticle(articleId)
        .then(() => {
          message = "Article has been saved to your Reading List successfully!";
          btnTitle = "View Reading List";
          btnAction = () => {
            navigate("?tab=myReading");
          };
          setIsSaved(true);
        })
        .catch((error) => {
          message = "Couldn’t save article to your Reading List!";
          isError = true;
          btnTitle = "retry";
          btnAction = () => {
            handleSaveArticle(articleId);
          };
          console.log(error);
        });
    }
    toast({
      render: () => (
        <ToastBtnMessage
          title={message}
          isError={isError}
          color="jet-black"
          btnTitle={btnTitle}
          btnAction={btnAction}
        ></ToastBtnMessage>
      ),
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top-right"
    });
  }

  useEffect(() => {
    setIsSaved(item.isSaved);
  }, [item.isSaved]);

  return (
    <div className="flex flex-col gap-4">
      <div className="w-full flex items-center gap-4 md:gap-[24px]">
        <div
          className={`flex flex-col gap-[12px] 2xl:gap-[20px] w-full ${
            index === 0 && !isHiddenFeaturedArticles ? "md:w-[40%]" : "md:w-[60%]"
          }`}
        >
          <div className="flex items-center gap-[8px]">
            <img
              className={`${index === 0 && !isHiddenFeaturedArticles ? "w-[40px] h-[40px]" : "w-[32px] h-[32px]"} rounded-full object-cover`}
              src={item?.imgUrl || AvatarDefault}
              alt=""
            />
            <p className="font-[600] line-clamp-2">{item?.nameCreator || "Anonymous"}</p>
          </div>
          <div className="flex flex-col md:gap-[8px]">
            <div className="flex items-center">
              <p className="font-[600] text-xs md:text-sm 2xl:text-base !text-[#808080]">
                {format(item?.createdAt || new Date(), "dd MMM yyyy")}
              </p>
              <p className="font-[600] ml-10 !text-[#808080] text-xs md:text-sm 2xl:text-base">
                {item?.readLength || "0 min"} read
              </p>
            </div>
            <h1
              className={`${
                index === 0 && !isHiddenFeaturedArticles
                  ? "text-base md:text-2xl 2xl:text-5xl"
                  : "text-base 2xl:text-2xl"
              } font-[800] line-clamp-2`}
              title={item?.title}
            >
              {item?.title}
            </h1>
            <p
              className="leading-[24px] hidden md:!line-clamp-2 text-sm 2xl:text-base "
              title={item?.description}
            >
              {item?.description}
            </p>
            <div className="hidden md:!flex items-center flex-wrap gap-2">
              {item?.topics?.slice(0, 4).map((elm) => (
                <p
                  className="bg-[#F0EDEC] rounded-[80px] !text-[#6A4E42] font-semibold text-xs px-2 py-1 whitespace-nowrap"
                  key={elm.id}
                >
                  {elm?.name}
                </p>
              ))}
            </div>
          </div>
          <div className="hidden md:!flex items-center">
            <div className="flex items-center relative">
              <img
                className="p-1 rounded-full bg-[#E6E7EB] shadow-[0px_8px_12px_0px_#80808026]"
                src={HeartIcon}
                alt=""
              />
              <img
                className="p-1 rounded-full bg-[#E6E7EB] left-5 absolute shadow-[0px_8px_12px_0px_#80808026]"
                src={ReactionIcon}
                alt=""
              />
              <p className="ml-7 font-[500] text-sm 2xl:text-base">
                {convertCountLike(item?.reactionNumber)}
              </p>
            </div>
            <div className="flex items-center gap-2 ml-5">
              <img src={CommentIcon} alt="" />
              <p className="font-[500] text-sm 2xl:text-base">{item?.commentNumber || 0}</p>
            </div>
            <div className="flex items-center ml-auto gap-2">
              <img
                src={ShareIcon}
                alt=""
                className="cursor-pointer"
                onClick={() => {
                  if (!isPreview) {
                    setModal("share-article");
                  }
                }}
              />
              <img
                src={isSaved ? SavedIcon : SaveIcon}
                alt=""
                className="cursor-pointer w-[24px] h-[24px]"
                onClick={() => {
                  if (!isPreview) {
                    handleSaveArticle(item?.id);
                  }
                }}
              />
              <img
                src={IgnoreIcon}
                alt=""
                className="cursor-not-allowed"
                onClick={() => {
                  if (!isPreview) {
                    // handle ignore
                  }
                }}
              />
            </div>
          </div>
        </div>
        <div
          className={`${index === 0 && !isHiddenFeaturedArticles ? "w-[60%]" : "w-[40%]"} relative`}
        >
          <img
            className={`${
              index === 0 && !isHiddenFeaturedArticles
                ? "h-[160px] min-w-[160px] md:h-[250px] 2xl:h-[360px]"
                : "h-[128px] min-w-[128px] md:h-[272px]"
            } rounded-[8px] w-full  object-cover`}
            src={item?.cover || DefaultThumbnail}
            alt=""
          />
          <div
            className={`absolute top-[8px] right-[8px] md:top-[16px] md:right-[16px] bg-[#EDF7EE] rounded-[80px] py-2 px-4 text-xs md:text-sm font-[800] !text-[#204A22] ${
              index === 0 && !isHiddenFeaturedArticles ? "block" : "hidden"
            }`}
          >
            Featured Article
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="md:hidden flex flex-wrap items-center gap-2">
        {item?.topics?.slice(0, 4).map((elm) => {
          return (
            <p
              className="bg-[#F0EDEC] rounded-[80px] !text-[#6A4E42] font-semibold text-xs px-2 py-1 whitespace-nowrap"
              key={elm.id}
            >
              {elm?.name}
            </p>
          );
        })}
      </div>
      <div className="flex md:hidden items-center">
        <div className="flex items-center relative">
          <img
            className="p-1 rounded-full bg-[#E6E7EB] shadow-[0px_8px_12px_0px_#80808026]"
            src={HeartIcon}
            alt=""
          />
          <img
            className="p-1 rounded-full bg-[#E6E7EB] left-5 absolute shadow-[0px_8px_12px_0px_#80808026]"
            src={ReactionIcon}
            alt=""
          />
          <p className="ml-7 font-[500] text-xs md:text-sm 2xl:text-base">
            {convertCountLike(item?.reactionNumber)}
          </p>
        </div>
        <div className="flex items-center gap-2 ml-5">
          <img src={CommentIcon} alt="" />
          <p className="font-[500] text-xs md:text-sm 2xl:text-base">{item?.commentNumber || 0}</p>
        </div>
        <div className="flex items-center ml-auto gap-2">
          <img
            src={ShareIcon}
            alt=""
            className="cursor-pointer"
            onClick={() => {
              if (!isPreview) {
                setModal("share-article");
              }
            }}
          />
          <img
            src={isSaved ? SavedIcon : SaveIcon}
            alt=""
            className="cursor-pointer w-[24px] h-[24px]"
            onClick={() => {
              if (!isPreview) {
                handleSaveArticle(item.id);
              }
            }}
          />
          <img
            src={IgnoreIcon}
            alt=""
            className="cursor-not-allowed"
            onClick={() => {
              if (!isPreview) {
                // handle ignore
              }
            }}
          />
        </div>
      </div>
      {modal === "share-article" ? (
        <ShareContentModal
          isOpen
          title="Share Article"
          shareUrl={`${process.env.REACT_APP_NEWSLETTER_URL}article-detail?id=${item?.id}`}
          media={item?.cover}
          onClick={() => setModal("")}
          titleBtn="Done"
          minW="439px"
        />
      ) : null}
    </div>
  );
};

export default CardArticle;
