import Button from "../../Button";
import ArrowLeftIcon from "../../../assets/arrow-left-bold-green-icon.svg";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { articlePayloadAtom } from "../atom";
import { useAtom } from "jotai";
import CardArticle from "../../DiscoveryComponent/LeftContentComponent/components/card-article.component";

const PreviewComponent = ({ setStep, onClose, metadata }) => {
  const [, setArticlePayload] = useAtom(articlePayloadAtom);

  const handlePreviousStep = () => {
    setStep(2);
  };

  const handleApplyChange = () => {
    setArticlePayload(metadata);
    onClose();
  };

  return (
    <div className="mb-[100px] max-w-[1200px] h-[60vh] mx-auto">
      <div className="mb-8 text-center max-w-[318px] m-auto">
        <p className="text-2xl font-[800]">Article Feed Preview</p>
        <p className="!text-[#808080] mt-1">
          Preview and edit how the article will look like inside Twygs Newsletter feed
        </p>
      </div>
      <CardArticle item={{ ...metadata, topics: metadata.topicIds }} isPreview />
      <div className="bg-[#FAFAFA] flex flex-col md:flex-row gap-3 justify-end border-t-[1px] border-solid border-[#D1C8C4] absolute left-1/2 -translate-x-1/2 bottom-0 pt-2 py-6 w-[calc(100%-48px)] rounded-b-[12px]">
        <Button variant="secondary" className="md:mr-auto" onClick={handlePreviousStep}>
          <img src={ArrowLeftIcon} alt="" className="mr-2" />
          Previous
        </Button>
        <Button onClick={handleApplyChange} className="hidden md:!flex">
          Apply changes
          <img src={ArrowRightIcon} alt="" className="ml-2" />
        </Button>
        <Button onClick={handleApplyChange} className="flex md:!hidden">
          Save
        </Button>
      </div>
    </div>
  );
};

export default PreviewComponent;
