import { Helmet } from "react-helmet";
import RequestEnterMetadataComponent from "../../components/CreateRequest/components/enter-metadata.component";

const CreateRequest = () => {
  return (
    <div>
      <Helmet>
        <title>New Request</title>
      </Helmet>
      <div className={`w-full flex flex-col items-center`}>
        <div className="sticky top-[56px] md:top-[80px] bg-[#FAFAFA] px-[17.5px] pt-5 md:pt-10 pb-6 w-full text-center z-10">
          <h1 className="text-[32px] font-[800] mb-4">Submit New Request</h1>
          <p className="!text-[#808080] mt-1">
            Send a request of the content you need to <br />
            your community
          </p>
        </div>
        <div className="px-[17.5px] w-full">
          <RequestEnterMetadataComponent />
        </div>
      </div>
    </div>
  );
};

export default CreateRequest;
