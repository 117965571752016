import { FormProvider, useForm } from "react-hook-form";
import { InputHookForm } from "../../Common/Form/input-with-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataSchema } from "./schema";
import { Switch, Text, Tooltip } from "@chakra-ui/react";
import { MenuHookForm } from "../../Common/Form/menu-with-hook-form";
import { ReactComponent as DesTooltipIcon } from "../../../assets/des-tooltip-icon.svg";
import {
  FILE_TYPES,
  handleFileNameExtension,
  MAX_FILE_SIZE_1
} from "../../../constants/image.constant";
import Button from "../../Button";
import { useEffect, useRef, useState } from "react";
import { ALLOWED_FILE_TYPES_THUMBNAIL, getContentType } from "../../../constants/image.constant";
import UploadIcon from "../../../assets/upload-green-icon.svg";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { fetchListTopic } from "../../../apis/discovery.api";
import DropzoneTwygsComponent from "../../Common/dropzone-twygs.component";
import { articlePayloadAtom } from "../atom";
import { useAtom } from "jotai";
import { TextAreaCustomHashtag } from "../../Common/Form/textarea-custom-hook-form/textarea-hashtag.component";
import { contributorAtom, hashtagAtom } from "../../Common/Form/textarea-custom-hook-form/atom";
import { profileAtom } from "../../Settings/atom";
import { TextAreaCustomContributor } from "../../Common/Form/textarea-custom-hook-form/textarea-contributor.component";
import { TextAreaHookForm } from "../../Common/Form/textarea-hook-form";
import { postAssetUpload } from "../../../apis/article.api";
import axios from "axios";
import { AI_SUMMARY_SUPPORT, UPLOAD_FILE_METADATA_DESC } from "../../../constants/twygs.constant";
import FormInputCounter from "./InputCountCharacter/form-input-counter.component";

const EnterMetadataComponent = ({ setStep, onClose, setMetadata, metadata }) => {
  const [articlePayload] = useAtom(articlePayloadAtom);
  const [hashtag] = useAtom(hashtagAtom);
  const [profile] = useAtom(profileAtom);
  const [contributors] = useAtom(contributorAtom);

  const [fileImg, setFileImg] = useState(metadata.cover || "");
  const [selectedFile, setSelectedFile] = useState(null);
  const [error, setError] = useState("");
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [listTopic, setListTopic] = useState([]);
  const [loading, setLoading] = useState(false);

  const fileInputRef = useRef();

  const methods = useForm({
    resolver: yupResolver(metadataSchema),
    defaultValues: metadata || articlePayload
  });

  const validateFiles = (file) => {
    let messageError = "";
    if (!ALLOWED_FILE_TYPES_THUMBNAIL.includes(file?.type)) {
      messageError = "Please ensure your file type is PNG, JPG or JPEG";
    }

    if (file?.size > MAX_FILE_SIZE_1) {
      messageError = "Please ensure your file size is less than 1MB";
    }
    return messageError;
  };

  const handleUploadImage = (file) => {
    setFileImg("");
    setError("");
    const newFile = file[0];
    setError(validateFiles(newFile));
    if (newFile && !validateFiles(newFile)) {
      setSelectedFile(newFile);
      const fakeUploadedUrl = URL.createObjectURL(newFile); // Replace with real upload logic
      setFileImg(fakeUploadedUrl);
    }
  };

  const handleClickReUpload = (e) => {
    e.preventDefault();
    fileInputRef.current.click();
  };

  const handleReUpload = (value) => {
    handleUploadImage([value.target.files[0]]);
  };

  const onSubmit = async (formValue) => {
    if (!fileImg) {
      setError("Thumbnail is required"); // Set thumbnail error if not uploaded
      return;
    }
    try {
      let contentKey = [];
      let filenameUpload = handleFileNameExtension(selectedFile?.name, selectedFile?.type);

      if (selectedFile && fileImg) {
        setLoading(true);
        contentKey = await postAssetUpload({
          fileName: [filenameUpload]
        });
        const url = contentKey?.[0]?.url;
        await axios.put(url, selectedFile, {
          headers: {
            "content-disposition": "inline",
            "Content-Type": getContentType(filenameUpload.toLowerCase()),
            "x-amz-acl": "public-read"
          }
        });
      }
      const formState = {
        ...metadata,
        ...formValue,
        cover: fileImg || articlePayload.cover || "",
        hashtags: hashtag,
        imgUrl: profile?.imgUrl,
        coverCloudKey: contentKey[0]?.key || articlePayload.coverCloudKey,
        nameCreator: `${profile?.firstName} ${profile?.lastName}`
      };
      setMetadata(formState);
      setStep(3);
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicsRes = await fetchListTopic();
        if (topicsRes) setListTopic(topicsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="max-w-[592px] mb-[80px] mx-auto">
      <div className="mb-8 m-auto text-center w-full sticky top-0 z-20 bg-[#fafafa]">
        <p className="text-2xl font-[800]">Article meta data</p>
        <p className="!text-[#808080] mt-1 max-w-[318px] mx-auto">
          Edit article key details and improve the discoverability of the article
        </p>
      </div>
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 w-full h-[50vh] overflow-y-auto md:pr-3">
              <FormInputCounter
                name="title"
                placeholder="Name your article"
                label="Article Title"
                maxLength={100}
                component={InputHookForm}
              />
              <FormInputCounter
                component={TextAreaHookForm}
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
                name="description"
                placeholder="Write description"
                label="Description"
                isOptional
                maxLength={2000}
              />
              <FormInputCounter
                name="organization"
                placeholder="Organization title"
                label="Organization"
                isOptional
                maxLength={100}
                component={InputHookForm}
              />
              <div className="relative">
                <MenuHookForm
                  name="topicIds"
                  label="Categories"
                  isOptional
                  isShowCheckBox
                  placeholder="Select categories"
                  data={listTopic || []}
                  defaultValue={metadata?.topicIds || articlePayload?.topicIds}
                />
              </div>
              <TextAreaCustomHashtag
                p={{ base: "12px 12px", "2xl": "17px 24px" }}
                placeholder="Press enter after each tag"
                label="Tags"
                isOptional
                name="hashtags"
                minHeight={"58px"}
                isHiddenBottomContent
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
              />
              <div className="flex items-center">
                <p className="mr-1">Enable AI summaries</p>
                <Tooltip
                  rounded="0.75rem"
                  label={AI_SUMMARY_SUPPORT}
                  placement={"top-start"}
                  zIndex={0}
                  bg="#E6E7EB"
                  p={{ base: "10px", "2xl": "1rem" }}
                  left={{ base: "100px", md: "0" }}
                  fontSize={"xs"}
                  color={"#333333"}
                  isOpen={isTooltipOpen}
                  transform={{
                    base: "translatex(-55%) !important",
                    md: "none !important"
                  }}
                >
                  <DesTooltipIcon
                    cursor="pointer"
                    className="w-[20px] h-[20px] z-0"
                    alt=""
                    onMouseEnter={() => handleMouseEnter("description")}
                    onMouseLeave={() => handleMouseLeave("description")}
                    onClick={() => handleImageClick("description")}
                  />
                </Tooltip>
                <Switch
                  {...methods.register("isEnableAISummaries")}
                  className="ml-auto"
                  variant="light-green"
                  size="lg"
                />
              </div>
              <div>
                <div className="mb-6">
                  <h1>Article thumbnail</h1>
                  <p className="text-sm !text-[#808080]">
                    This image will be used as the article thumbnail, displayed in listings and
                    feeds. It will also appear at the top of your article.
                  </p>
                </div>
                <div>
                  {fileImg && !error ? (
                    <div>
                      <div className="flex items-center gap-2.5 text-sm mb-2">
                        <input
                          type="file"
                          id="file-input"
                          ref={fileInputRef}
                          onChange={handleReUpload}
                          hidden
                        />
                        <Button
                          variant="secondary"
                          className="!px-4"
                          onClick={(e) => handleClickReUpload(e)}
                        >
                          <img src={UploadIcon} className="mr-2" alt="Upload" />
                          Choose another file
                        </Button>
                      </div>

                      <img
                        src={fileImg}
                        className="md:max-w-[572px] md:min-w-[572px] md:max-h-[360px] md:min-h-[360px] rounded-[8px] object-cover"
                        alt="Preview"
                      />
                    </div>
                  ) : (
                    <DropzoneTwygsComponent
                      acceptFileTypes={FILE_TYPES.twygsImage}
                      isError={error}
                      className="!h-[214px] md:!h-[360px]"
                      title={
                        <p className="!text-[#808080] text-sm">
                          <span className="!text-[#4CAF50] font-[900]">Click to upload file</span>{" "}
                          or drag and drop
                        </p>
                      }
                      notMutiple
                      validateFiles={handleUploadImage}
                      desc={UPLOAD_FILE_METADATA_DESC.image.map((item, index) => (
                        <Text key={index} fontWeight="400" color="dim-gray" fontSize="xs">
                          {item}
                        </Text>
                      ))}
                    />
                  )}
                  {error && (
                    <Text className="!text-red-500 mt-2" fontSize={{ base: "xs", "2xl": "sm" }}>
                      {error}
                    </Text>
                  )}
                </div>
              </div>
              <div className="flex place-items-end">
                <TextAreaCustomContributor
                  p={{ base: "12px 12px", "2xl": "17px 24px" }}
                  minHeight={"58px"}
                  name="contributors"
                  label="Contributors"
                  isOptional
                  placeholder="Add names of article contributors"
                  isHiddenBottomContent
                  css={{
                    "&::-webkit-scrollbar": {
                      display: "none"
                    }
                  }}
                />
              </div>
            </div>
            <div className="bg-[#FAFAFA] flex gap-3 justify-end border-t-[1px] z-20 border-solid border-[#D1C8C4] absolute left-1/2 -translate-x-1/2 bottom-0 pt-2 py-6 w-[calc(100%-48px)] rounded-b-[12px]">
              <Button variant="outline" onClick={() => onClose()}>
                Discard
              </Button>
              <Button type="submit" isLoading={loading}>
                Next
                <img src={ArrowRightIcon} alt="" className="ml-2" />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
    </div>
  );
};

export default EnterMetadataComponent;
