import { useNavigate } from "react-router-dom";
import FireIcon from "../../../../assets/fire-icon.svg";
import { useEffect, useState } from "react";
import { fetchBreakingNewArticles } from "../../../../apis/discovery.api";
import CardArticleLoading from "../../LeftContentComponent/components/card-article-loading.component";
import SmallCardArticle from "../../LeftContentComponent/components/card-article-small.component";
import { profileAtom } from "../../../Settings/atom";
import { useAtom } from "jotai";

const BreakingNews = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listBreakingNews, setListBreakingNews] = useState([]);
  const [profile] = useAtom(profileAtom);

  const handleRedirectViewAllBreakingNews = () => {
    navigate("/breaking-news");
  };

  useEffect(() => {
    const getBreakingNews = async () => {
      try {
        const result = await fetchBreakingNewArticles(
          {
            limit: 3,
            page: 1
          },
          profile.id
        );
        if (result) {
          setListBreakingNews(result.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getBreakingNews();
  }, []);

  return (
    <div>
      <div className="flex items-center justify-between mb-[16px] 2xl:mb-[24px]">
        <div className="text-xl 2xl:text-2xl !text-[#6A4E42] font-[700] flex items-center gap-2">
          Breaking News
          <img className="w-[24px]" src={FireIcon} alt="" />
        </div>
        {listBreakingNews?.length > 2 && (
          <p
            onClick={handleRedirectViewAllBreakingNews}
            className="text-base 2xl:text-lg !text-growth-green font-[900] cursor-pointer hover:opacity-80"
          >
            View All
          </p>
        )}
      </div>
      <div className="flex flex-col gap-[24px]">
        {loading && <CardArticleLoading />}
        {listBreakingNews?.map((item) => (
          <SmallCardArticle key={item.id} item={item} />
        ))}
      </div>
    </div>
  );
};

export default BreakingNews;
