import type { SerializedLexicalNode, LexicalNode, NodeKey, EditorConfig } from "lexical";
import { DecoratorNode } from "lexical";
import * as React from "react";
import { Suspense } from "react";

const DraggableTextBubbleComponent = React.lazy(() => import("./DraggableTextBubble"));

export interface DraggableTextBubblePayload {
  text: string;
  height: number;
  width: number;
  key?: NodeKey;
  x: number;
  y: number;
}

export type SerializedTextBubbleNode = SerializedLexicalNode & DraggableTextBubblePayload;

export class DraggableTextBubbleNode extends DecoratorNode<JSX.Element> {
  __text: string;
  __width: number;
  __height: number;
  __x: number;
  __y: number;

  constructor(text: string, width: number, height: number, x: number, y: number, key?: NodeKey) {
    super(key);
    this.__text = text;
    this.__width = width;
    this.__height = height;
    this.__x = x;
    this.__y = y;
  }

  static getType(): string {
    return "draggable-text-bubble";
  }

  static clone(node: DraggableTextBubbleNode): DraggableTextBubbleNode {
    return new DraggableTextBubbleNode(
      node.__text,
      node.__width,
      node.__height,
      node.__x,
      node.__y,
      node.__key
    );
  }

  static importJSON(serializedNode: SerializedTextBubbleNode): DraggableTextBubbleNode {
    const { width, height, x, y, text } = serializedNode;
    const node = $createTextBubbleNode({
      text,
      height,
      width,
      x,
      y
    });
    return node;
  }

  setDimensions(width: number, height: number): void {
    const writable = this.getWritable();
    writable.__width = width;
    writable.__height = height;
  }

  setPosition(x: number, y: number): void {
    const writable = this.getWritable();
    writable.__x = x;
    writable.__y = y;
  }

  getPosition(): { x: number; y: number } {
    return { x: this.__x, y: this.__y };
  }

  exportJSON(): SerializedTextBubbleNode {
    return {
      text: this.__text,
      height: this.__height,
      type: "draggable-text-bubble",
      version: 1,
      width: this.__width,
      x: this.__x,
      y: this.__y
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const span = document.createElement("span");
    const className = config.theme.inlineImage;
    if (className !== undefined) {
      span.className = className;
    }
    span.style.display = "inline-block";
    span.style.width = "0";
    span.style.height = "0";
    span.style.position = "relative";
    span.style.userSelect = "none";
    return span;
  }

  updateDOM(): false {
    return false;
  }

  decorate(): JSX.Element {
    return (
      <Suspense fallback={null}>
        <DraggableTextBubbleComponent
          nodeKey={this.getKey()}
          width={this.__width}
          height={this.__height}
          text={this.__text}
          x={this.__x}
          y={this.__y}
          updateDimensions={this.setDimensions}
        />
      </Suspense>
    );
  }
}

export function $createTextBubbleNode({
  text,
  height,
  width,
  x,
  y,
  key
}: DraggableTextBubblePayload): DraggableTextBubbleNode {
  return new DraggableTextBubbleNode(text, width, height, x, y, key);
}

export function $isTextBubbleNode(
  node: LexicalNode | null | undefined
): node is DraggableTextBubbleNode {
  return node instanceof DraggableTextBubbleNode;
}
