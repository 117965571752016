import { FormProvider, useForm } from "react-hook-form";
import { InputHookForm } from "../../Common/Form/input-with-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { metadataSchema } from "./schema";
import { Tooltip } from "@chakra-ui/react";
import { MenuHookForm } from "../../Common/Form/menu-with-hook-form";
import { ReactComponent as DesTooltipIcon } from "../../../assets/des-tooltip-icon.svg";
import Button from "../../Button";
import { useEffect, useState } from "react";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { TextAreaHookForm } from "../../Common/Form/textarea-hook-form";
import { EQUITY_PERCENTAGE_TOOLTIP } from "../../../constants/twygs.constant";
import FormInputCounter from "./InputCountCharacter/form-input-counter.component";
import { createRequest, fetchRequestContentTypes } from "../../../apis/request.api";
import { useNavigate } from "react-router-dom";
import CreateRequestModal from "./create-request-modal.component";
import { useToast } from "@chakra-ui/react";
import ToastMessage from "../../ToastMessage";

const RequestEnterMetadataComponent = () => {
  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [listContentType, setListContentType] = useState([]);
  const [loading, setLoading] = useState(false);
  const [modal, setModal] = useState("");
  const [createdRequestId, setCreatedRequestId] = useState();
  const toast = useToast();

  const navigate = useNavigate();

  const methods = useForm({
    resolver: yupResolver(metadataSchema)
  });

  const onSubmit = async (formValue) => {
    try {
      await createRequest({
        contentTypeIds: formValue.contentTypeIds.map((contentType) => contentType.id),
        description: formValue.description,
        equityPercentage: Number(formValue.equityPercentage),
        title: formValue.title
      }).then((response) => {
        setCreatedRequestId(response?.id);
        setModal("create-success");
      });
    } catch (error) {
      console.log(error);
      toast({
        render: () => (
          <ToastMessage
            title={"Create request failed"}
            isError={true}
            color="jet-black"
          ></ToastMessage>
        ),
        status: "error",
        duration: 2000,
        isClosable: true,
        position: "top-right"
      });
    } finally {
      setLoading(false);
    }
  };

  const handleImageClick = () => {
    setIsTooltipOpen(!isTooltipOpen);
  };

  const handleMouseEnter = () => {
    setIsTooltipOpen(true);
  };

  const handleMouseLeave = () => {
    setIsTooltipOpen(false);
  };

  const handleBackToRequestScreen = () => {
    navigate("/article/manage?tab=requests");
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const topicsRes = await fetchRequestContentTypes();
        if (topicsRes) setListContentType(topicsRes);
      } catch (error) {
        console.log(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div className="mb-[90px] md:mb-[200px] max-w-[592px] mx-auto">
      <div>
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(onSubmit)}>
            <div className="flex flex-col gap-4 w-full">
              <FormInputCounter
                name="title"
                placeholder="ex.: Photo of a dog"
                label="Request title"
                maxLength={100}
                component={InputHookForm}
              />
              <FormInputCounter
                component={TextAreaHookForm}
                css={{
                  "&::-webkit-scrollbar": {
                    display: "none"
                  }
                }}
                name="description"
                placeholder="Write description"
                label="Description"
                maxLength={2000}
              />
              {/* Equitity Percentage */}
              <div>
                <div className="flex items-center mb-[12px]">
                  <p className="mr-1">Equity percentage</p>
                  <Tooltip
                    rounded="0.75rem"
                    label={EQUITY_PERCENTAGE_TOOLTIP}
                    placement={"top-start"}
                    zIndex={0}
                    bg="#E6E7EB"
                    p={{ base: "10px", "2xl": "1rem" }}
                    left={{ base: "100px", md: "0" }}
                    fontSize={"xs"}
                    color={"#333333"}
                    isOpen={isTooltipOpen}
                    transform={{
                      base: "translatex(-55%) !important",
                      md: "none !important"
                    }}
                  >
                    <DesTooltipIcon
                      cursor="pointer"
                      className="w-[20px] h-[20px] z-0"
                      alt=""
                      onMouseEnter={() => handleMouseEnter("description")}
                      onMouseLeave={() => handleMouseLeave("description")}
                      onClick={() => handleImageClick("description")}
                    />
                  </Tooltip>
                </div>
                <FormInputCounter
                  name="equityPercentage"
                  placeholder="ex: 2%, 5%..."
                  label=""
                  component={InputHookForm}
                  isShowRange={false}
                />
              </div>
              <MenuHookForm
                name="contentTypeIds"
                label="Content Type"
                isShowCheckBox
                placeholder="Image, Video, Text"
                data={listContentType || []}
              />
            </div>
            <div className="bg-white flex flex-col gap-3 md:flex-row justify-end border-t-[1px] border-solid border-[#E6E7EB] fixed left-0 bottom-0 z-20 py-[15px] md:py-[20px] px-[15px] md:px-[40px] w-full">
              <Button variant="outline" onClick={handleBackToRequestScreen}>
                Discard
              </Button>
              <Button type="submit" isLoading={loading}>
                Submit
                <img src={ArrowRightIcon} alt="" className="ml-2" />
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>
      {modal === "create-success" ? (
        <CreateRequestModal
          isOpen
          title="Share Your Request"
          shareUrl={`${process.env.REACT_APP_NEWSLETTER_URL}article-detail?id=${createdRequestId}`}
          // media={item?.cover}
          onClose={handleBackToRequestScreen}
          onSubmit={handleBackToRequestScreen}
          titleBtn="Done"
          minW="439px"
        />
      ) : null}
    </div>
  );
};

export default RequestEnterMetadataComponent;
