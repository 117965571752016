import CardArticle from "../DiscoveryComponent/LeftContentComponent/components/card-article.component";
import GoogleAd from "../GoogleAds";
import CardArticleLoading from "../DiscoveryComponent/LeftContentComponent/components/card-article-loading.component";
import EmptyArticleComponent from "../EmptyArticleComponent";

const ArticleContainer = ({ loading, listArticle, isHiddenFeaturedArticles }) => {
  const renderContentWithAds = () => {
    const contentWithAds = [];
    listArticle.forEach((item, index) => {
      contentWithAds.push(
        <CardArticle
          key={`article-${index}`}
          index={index}
          item={{
            ...item,
            nameCreator: `${item?.creator?.firstName} ${item?.creator?.lastName}`,
            imgUrl: item?.creator?.imgUrl
          }}
          isHiddenFeaturedArticles={isHiddenFeaturedArticles}
        />
      );
      if ((index + 1) % 4 === 0) {
        contentWithAds.push(
          <GoogleAd key={`ad-${index}`} slot="3329737237" className="my-custom-ad !z-10" />
        );
      }
    });
    return contentWithAds;
  };

  return (
    <div className="pb-[30px] w-full">
      <div className="md:px-0 flex flex-col gap-[30px] 2xl:gap-[60px]">
        {renderContentWithAds()}
        {loading &&
          [...Array(3)].map((_, i) => (
            <CardArticleLoading
              key={i}
              index={i}
              isHiddenFeaturedArticles={isHiddenFeaturedArticles}
            />
          ))}

        {listArticle.length === 0 && !loading && <EmptyArticleComponent />}
      </div>
    </div>
  );
};

export default ArticleContainer;
