import axiosInstance from "./axios-instance";

export const fetchListArticle = async (params, userId) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles`, {
    params: {
      ...params,
      userId
    }
  });
  return result.data.data;
};

export const fetchBreakingNewArticles = async (params, userId) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/breaking-news`,
    {
      params: {
        ...params,
        userId
      }
    }
  );
  return result.data.data;
};

export const fetchMyReadingList = async (pagination, userId) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/saved`, {
    params: { ...pagination, userId }
  });
  return result.data.data;
};

export const fetchListForYou = async (params, userId) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/for-you`, {
    params: { ...params, userId }
  });
  return result.data.data;
};

export const fetchListTrending = async (params, userId) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/trending`,
    {
      params: { ...params, userId }
    }
  );
  return result.data.data;
};

export const fetchListFollowing = async (params, userId) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/list-following`,
    {
      params: { ...params, userId }
    }
  );
  return result.data.data;
};

export const fetchListTopic = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}articles/topics`);
  return result.data.data;
};

export const fetchListRecommendedTopics = async (params) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}articles/recommend-topics`,
    {
      params
    }
  );
  return result.data.data;
};

export const fetchListFeaturedVoices = async (pagination, userId) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}user/featured-voices`,
    {
      params: { ...pagination, userId }
    }
  );
  return result.data.data;
};

export const getUserInfo = async () => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}user/get-me`);
  return result.data.data;
};

export const followUser = async (userId) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}user/${userId}/follow`
  );
  return result.data.data;
};

export const unfollowUser = async (userId) => {
  const result = await axiosInstance.post(
    `${process.env.REACT_APP_TWYGS_API_URL}user/${userId}/unfollow`
  );
  return result.data.data;
};
