import GoogleAd from "../../GoogleAds/index.jsx";
import CardArticle from "./components/card-article.component.jsx";
import CardArticleLoading from "./components/card-article-loading.component.jsx";
import EmptyArticleComponent from "../../EmptyArticleComponent/index.jsx";

const LeftContentComponent = ({ selected, listArticle, loading }) => {
  const renderContentWithAds = () => {
    const contentWithAds = [];
    listArticle?.forEach((item, index) => {
      contentWithAds.push(
        <CardArticle
          key={`article-${index}`}
          index={index}
          item={{
            ...item,
            nameCreator: `${item?.creator?.firstName} ${item?.creator?.lastName}`,
            imgUrl: item?.creator?.imgUrl
          }}
          isHiddenFeaturedArticles={selected.type === "following" || selected.type === "myReading"}
        />
      );
      if ((index + 1) % 4 === 0) {
        contentWithAds.push(
          <GoogleAd key={`ad-${index}`} slot="3329737237" className="my-custom-ad !z-10" />
        );
      }
    });
    return contentWithAds;
  };

  return (
    <div className="pb-[30px]">
      <div className="mt-[30px] 2xl:mt-[60px] px-5 md:px-0  md:pr-[20px] 2xl:pr-[30px] flex flex-col gap-[30px] 2xl:gap-[60px]">
        {renderContentWithAds()}
        {loading &&
          [...Array(3)].map((_, i) => (
            <CardArticleLoading
              key={i}
              index={i}
              isHiddenFeaturedArticles={
                selected.type === "following" || selected.type === "myReading"
              }
            />
          ))}

        {listArticle?.length === 0 && !loading && (
          <EmptyArticleComponent categoryType={selected.type} />
        )}
      </div>
    </div>
  );
};

export default LeftContentComponent;
