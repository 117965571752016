import HeartIcon from "../../../../assets/heart-icon.svg";
import ReactionIcon from "../../../../assets/reaction-icon.svg";
import CommentIcon from "../../../../assets/comment-article-icon.svg";
import ShareIcon from "../../../../assets/share-gray-icon.svg";
import SaveIcon from "../../../../assets/save-gray-icon.svg";
import DefaultThumbnail from "../../../../assets/default-thumnail.png";
import SavedIcon from "../../../../assets/saved-gray-icon.svg";
import { convertCountLike } from "../../../../constants";
import { format } from "date-fns";
import { useToast } from "@chakra-ui/react";
import ToastBtnMessage from "../../../ToastBtnMessage";
import { saveArticle, unsaveArticle } from "../../../../apis/article.api";
import { useState } from "react";
import ShareContentModal from "../../../ShareContentModal";
import { useNavigate } from "react-router-dom";

const SmallCardArticle = ({ item }) => {
  const [isSaved, setIsSaved] = useState(item.isSaved);
  const toast = useToast();
  const [modal, setModal] = useState("");
  const navigate = useNavigate();

  async function handleSaveArticle(articleId) {
    let message = "Error";
    let isError = false;
    let btnTitle = undefined;
    let btnAction = undefined;

    if (isSaved) {
      await unsaveArticle(articleId)
        .then(() => {
          message = "Article has been removed from your Reading List successfully!";
          btnTitle = "View Reading List";
          btnAction = () => {
            navigate("?tab=myReading");
          };
          setIsSaved(false);
        })
        .catch((error) => {
          message = "Couldn’t remove article from your Reading List!";
          isError = true;
          btnTitle = "retry";
          btnAction = () => {
            handleSaveArticle(articleId);
          };
          console.log(error);
        });
    } else {
      await saveArticle(articleId)
        .then(() => {
          message = "Article has been saved to your Reading List successfully!";
          btnTitle = "View Reading List";
          btnAction = () => {
            navigate("?tab=myReading");
          };
          setIsSaved(true);
        })
        .catch((error) => {
          message = "Couldn’t save article to your Reading List!";
          isError = true;
          btnTitle = "retry";
          btnAction = () => {
            handleSaveArticle(articleId);
          };
          console.log(error);
        });
    }

    toast({
      render: () => (
        <ToastBtnMessage
          title={message}
          isError={isError}
          color="jet-black"
          btnTitle={btnTitle}
          btnAction={btnAction}
        ></ToastBtnMessage>
      ),
      status: "error",
      duration: 2000,
      isClosable: true,
      position: "top-right"
    });
  }

  return (
    <div className="flex flex-col gap-4 py-4">
      <div className="w-full grid grid-cols-[auto,160px] gap-4 md:gap-[24px]">
        <div className="flex flex-col gap-[12px] 2xl:gap-[20px] w-full">
          <div className="flex flex-col md:gap-[8px]">
            <div className="flex items-center">
              <p className="font-[600] whitespace-nowrap text-xs !text-[#808080]">
                {format(item?.createdAt || new Date(), "dd MMM yyyy")}
              </p>
              <p className="font-[600] whitespace-nowrap ml-auto !text-[#808080] text-xs">
                {item?.readLength || "0 min"} read
              </p>
            </div>
            <h1 className="text-sm font-[800] line-clamp-2 mb-[24px]" title={item?.title}>
              {item?.title}
            </h1>
            <div className="hidden md:!flex items-center flex-wrap gap-2">
              {item?.topics?.slice(0, 4).map((elm) => {
                return (
                  <p
                    className="bg-[#F0EDEC] rounded-[80px] !text-[#6A4E42] font-semibold text-xs px-2 py-1 whitespace-nowrap"
                    key={elm.id}
                  >
                    {elm?.name}
                  </p>
                );
              })}
            </div>
          </div>
        </div>
        <div className="w-[160px] h-[160px] relative z-[-1]">
          <img
            className="h-[160px] w-[160px] rounded-[8px] object-cover"
            src={item?.cover || DefaultThumbnail}
            alt=""
          />
        </div>
        <div className="col-span-2 grid grid-cols-2 md:!flex items-center">
          <div className="flex items-center relative z-[-1]">
            <img
              className="p-1 rounded-full bg-[#E6E7EB] shadow-[0px_8px_12px_0px_#80808026]"
              src={HeartIcon}
              alt=""
            />
            <img
              className="p-1 rounded-full bg-[#E6E7EB] left-5 absolute shadow-[0px_8px_12px_0px_#80808026]"
              src={ReactionIcon}
              alt=""
            />
            <p className="ml-7 font-[600] text-xs">{convertCountLike(item?.reactionNumber)}</p>
          </div>
          <div className="flex items-center gap-2 ml-5">
            <img src={CommentIcon} alt="" />
            <p className="font-[600] text-xs">{item?.commentNumber || 0}</p>
          </div>
          <div className="flex items-center ml-auto gap-2">
            <img
              src={ShareIcon}
              alt=""
              className="cursor-pointer"
              onClick={() => {
                setModal("share-article");
              }}
            />
            <img
              alt=""
              src={isSaved ? SavedIcon : SaveIcon}
              className="cursor-pointer w-[24px] h-[24px]"
              onClick={() => {
                handleSaveArticle(item.id);
              }}
            />
          </div>
        </div>
      </div>
      {modal === "share-article" ? (
        <ShareContentModal
          isOpen
          title="Share Article"
          shareUrl={`${process.env.REACT_APP_NEWSLETTER_URL}article-detail?id=${item?.id}`}
          media={item?.cover}
          onClick={() => setModal("")}
          titleBtn="Done"
          minW="439px"
        />
      ) : null}
    </div>
  );
};

export default SmallCardArticle;
