import { redirect } from "react-router-dom";
import Cookies from "js-cookie";

const staging = process.env.REACT_APP_IS_STAGING;

export const checkAuth = () => {
  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  if (!token) {
    return redirect("/");
  }

  return null;
};
