import React, { useEffect } from "react";

const GoogleAd = ({ slot, className }) => {
  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error("AdSense error:", e);
    }
  }, []);

  return (
    <ins
      className={`adsbygoogle ${className}`}
      data-ad-client="ca-pub-2525703558747216" // Replace with your ca-pub-id
      data-ad-slot={slot}
      data-adtest="on"
      data-ad-format="auto"
      data-full-width-responsive="true"
    />
  );
};

export default GoogleAd;
