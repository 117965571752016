import { useCallback, useEffect, useMemo, useState } from "react";

import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text, Tooltip } from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import PaginationComponent from "../../Pagination";
import NoDataComponent from "../../NoData";
import TableComponent from "../../Table";
import InputSearchComponent from "../../Common/Form/input-search";
import {
  deleteArticle,
  fetchListMyArticles,
  fetchListWrittingType
} from "../../../apis/article.api";
import { formartNumber, formatDate, formatNumber } from "../../../utils/helper.util";
import ImageComponent from "../../Common/Image";
import DefaultThumbnail from "../../../assets/default-thumnail.png";
import ChevronDownIcon from "../../../assets/chevron-down-green-icon.svg";
import EyeGreenIcon from "../../../assets/eye-light-green-icon.svg";
import EditIcon from "../../../assets/edit-icon.svg";
import AnalyticIcon from "../../../assets/analytics-icon.svg";
import ShareIcon from "../../../assets/share-green-icon.svg";
import DeleteIcon from "../../../assets/bin-red-icon.svg";
import SuccessModal from "../../Modal/SuccessModal";
import HelpIcon from "../../../assets/help-green-icon.svg";
import ArrowUpLightGreen from "../../../assets/arrow-up-light-green-icon.svg";
import ShareContentModal from "../../ShareContentModal";
import { fetchListTopic } from "../../../apis/discovery.api";
import { EARNING_TEXT } from "../../../constants/twygs.constant";
import { useNavigate } from "react-router-dom";
import ConfirmModal from "../../Modal/ConfirmModal";
import FailLoadImage from "../../../assets/failed-to-load.svg";

export const ArticleTableComponent = () => {
  const navigate = useNavigate();
  const [dataTable, setDataTable] = useState([]);
  const [modal, setModal] = useState("");
  const [detailData, setDetailData] = useState({});
  const [pagination, setPagination] = useState({
    limit: 3,
    page: 1,
    sortBy: undefined,
    sort: undefined,
    search: undefined
  });
  const [type, setType] = useState({
    id: undefined,
    value: ""
  });
  const [category, setCategory] = useState({
    id: undefined,
    value: ""
  });

  const listAnalytic = [
    {
      id: 1,
      name: "Total Likes",
      value: detailData?.reactionNumber || 0
    },
    {
      id: 2,
      name: "Total Views",
      value: detailData?.viewNumber || 0
    },
    {
      id: 3,
      name: "Total Comments",
      value: detailData?.commentNumber || 0
    },
    {
      id: 4,
      name: "Total Shares",
      value: detailData?.shareNumber || 0
    }
  ];

  const params = {
    ...pagination,
    writingTypeId: type.id,
    topicIds: [category.id]
  };

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined)
  );

  const {
    data: getListArticles,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ["getListArticles", filteredParams],
    queryFn: () => fetchListMyArticles(filteredParams)
  });

  const { data: getListTopic } = useQuery({
    queryKey: ["list-categories"],
    queryFn: fetchListTopic
  });

  const { data: getListWritingType } = useQuery({
    queryKey: ["list-writing-type"],
    queryFn: fetchListWrittingType
  });

  const { mutateAsync: deleteArticleAsync } = useMutation({
    mutationFn: deleteArticle
  });

  const handleChangePage = (page) => {
    setDataTable(() => []);
    setPagination((pre) => {
      return {
        ...pre,
        page
      };
    });
  };

  const handleChangeSearch = (search) => {
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
        search: search
      };
    });
  };

  const handleChangeType = (id, name) => () => {
    setType({ id: id, value: name });
    setPagination((pre) => {
      return {
        ...pre,
        page: 1
      };
    });
  };

  const handleChangeCategory = (id, name) => () => {
    setCategory({ id: id, value: name });
    setPagination((pre) => {
      return {
        ...pre,
        page: 1
      };
    });
  };

  const handleDeleteArticle = async (id) => {
    try {
      await deleteArticleAsync(id);
      setModal("");
      refetch();
    } catch (error) {
      console.error("Error deleting article:", error);
      throw error;
    }
  };

  const handleShowConfirmDeleteModal = (data) => {
    setDetailData(data);
    setModal("remove-item");
  };

  const convertArticles = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box position={"relative"}>
            {row?.cover ? (
              <ImageComponent
                rounded={"0.5rem"}
                minW={"128px"}
                minH={"128px"}
                maxW={"128px"}
                maxH={"128px"}
                src={row?.cover}
                alt="cover"
              />
            ) : (
              <Box
                rounded={"0.5rem"}
                minW={"128px"}
                minH={"128px"}
                maxW={"128px"}
                maxH={"128px"}
                bgColor={"#F2F3F7"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
                fontSize={"sm"}
              >
                No Image
              </Box>
            )}
          </Box>
          <Box ml={"12px"}>
            <Box>
              <Text
                fontWeight={800}
                fontSize={"xl"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.title || "Untitled"}
              </Text>
            </Box>
            <Text
              wordBreak={"break-word"}
              fontSize={"sm"}
              title={row?.description}
              overflow={"hidden"}
              textOverflow={"ellipsis"}
              sx={{
                display: "-webkit-inline-box",
                WebkitLineClamp: "2",
                WebkitBoxOrient: "vertical"
              }}
            >
              {row?.description || "No description"}
            </Text>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertCategory = useCallback((row) => {
    return (
      <Box display={"flex"} flexWrap={"wrap"} gap={"8px"}>
        {row?.topics.length > 0 ? (
          row?.topics?.slice(0, 3)?.map((item) => (
            <Box
              key={item.id}
              px={"8px"}
              py={"4px"}
              rounded={"80px"}
              bgColor={"#F0EDEC"}
              w={"fit-content"}
              whiteSpace={"nowrap"}
            >
              <Text title={item?.name} color="#6A4E42" fontWeight={800} fontSize={"xs"}>
                {item?.name}
              </Text>
            </Box>
          ))
        ) : (
          <Text fontSize={"sm"}>No Categories</Text>
        )}
      </Box>
    );
  }, []);

  const convertStatus = useCallback((row) => {
    return (
      <Box
        p={"12px"}
        rounded={"80px"}
        bgColor={row?.isPublished ? "#4CAF50" : "#EDF7EE"}
        w={"fit-content"}
      >
        <Text
          color={row?.isPublished ? "#FFFFFF" : "#204A22"}
          fontWeight={row?.isPublished ? 800 : 600}
          fontSize={"xs"}
        >
          {row?.isPublished ? "Published" : "Draft"}
        </Text>
      </Box>
    );
  }, []);

  const convertViews = useCallback(
    (row) => (
      <Box display="flex" gap={2} px={3} py="10px" justifyContent="center">
        {row?.isPublished ? (
          <>
            <img src={EyeGreenIcon} alt="Published" />
            <Text
              title={row.viewNumber || "No views"}
              color="#204A22"
              fontWeight={600}
              fontSize="xs"
            >
              {formatNumber(row.viewNumber)}
            </Text>
          </>
        ) : (
          <Text color="#204A22" fontWeight={600} fontSize="xs">
            -
          </Text>
        )}
      </Box>
    ),
    []
  );

  const convertAction = useCallback((row) => {
    return (
      <Box display="flex" gap="12px" alignItems={"center"}>
        <img
          src={EditIcon}
          className="p-[10px] cursor-pointer hover:opacity-80"
          alt="icon"
          onClick={() => navigate(`/article/edit?id=${row?.id}&step=4&type=edit`)}
        />
        <img
          src={AnalyticIcon}
          className={`${row?.isPublished ? "" : "hidden"} p-[10px] cursor-pointer hover:opacity-80`}
          alt="icon"
          onClick={() => {
            setModal("analytic");
            setDetailData(row);
          }}
        />
        <img
          src={ShareIcon}
          className={`${row?.isPublished ? "" : "hidden"} p-[10px] cursor-pointer hover:opacity-80`}
          alt="icon"
          onClick={() => {
            setModal("share-article");
            setDetailData(row);
          }}
        />
        <img
          src={DeleteIcon}
          className="p-[10px] cursor-pointer hover:opacity-80"
          alt="icon"
          onClick={() => handleShowConfirmDeleteModal(row)}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (sortName) => {
    let objSort = {
      sortBy: pagination.sortBy,
      sort: pagination.sort
    };
    if (sortName !== objSort.sortBy) objSort = { sortBy: sortName, sort: "asc" };
    else if (sortName === objSort.sortBy && objSort.sort === "asc")
      objSort = {
        ...objSort,
        sort: "desc"
      };
    else if (sortName === objSort.sortBy && objSort.sort === "desc")
      objSort = {
        ...objSort,
        sort: "asc"
      };
    setPagination({ ...pagination, ...objSort });
  };

  const columns = useMemo(
    () => [
      {
        Header: "Articles",
        accessor: (row) => convertArticles(row),
        id: "title",
        isSortField: true,
        widthCustom: "calc(100% - 35.5rem)"
      },
      {
        Header: "Categories",
        accessor: (row) => convertCategory(row),
        id: "contentCategory",
        widthCustom: "12rem",
        minWidthCustom: "12rem"
      },
      {
        Header: "Status",
        accessor: (row) => convertStatus(row),
        id: "isPublished",
        widthCustom: "7rem",
        minWidthCustom: "7rem"
      },
      {
        Header: "Views",
        accessor: (row) => convertViews(row),
        id: "viewNumber",
        widthCustom: "7rem",
        isSortField: true,
        minWidthCustom: "7rem"
      },
      {
        Header: "Date Created",
        accessor: (row) => <Text fontSize={"sm"}>{formatDate(row?.createdAt, true)}</Text>,
        id: "createdAt",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
        isSortField: true
      },
      {
        Header: "Actions",
        accessor: (row) => convertAction(row),
        widthCustom: "16rem",
        minWidthCustom: "16rem",
        id: "action"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertArticles, convertCategory, convertStatus, convertViews, convertAction, handleSort]
  );

  useEffect(() => {
    if (getListArticles) {
      setDataTable(getListArticles?.data);
    }
  }, [getListArticles, type.id, category.id]);

  return (
    <Box
      w={"full"}
      border={"1px solid"}
      borderColor={"anti-flash-lite"}
      borderRadius={"12px"}
      shadow={"outline"}
    >
      <Box
        bg={"white"}
        p={"16px 24px"}
        display={"flex"}
        alignItems={"start"}
        minH={"72px"}
        borderTopRadius={"12px"}
        justifyContent={"space-between"}
        overflow={"auto"}
      >
        <Box
          display={"flex"}
          gap="12px"
          flexDirection={{ base: "column", md: "row" }}
          width={"full"}
          overflow={"auto"}
        >
          <InputSearchComponent
            setPaginationSearch={handleChangeSearch}
            placeholder={"Search articles"}
            setLoading={() => {}}
            width={{ base: "100%", md: "320px" }}
          />
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={
                <div className="ml-[16px]">
                  <img src={ChevronDownIcon} alt="icon" />
                </div>
              }
              height={"42px"}
              w={"fit-content"}
              variant={"growth-green-solid"}
              px={"18px"}
            >
              <Text
                color={"#4CAF50"}
                fontWeight={900}
                textAlign={"start"}
                fontSize={{ base: "sm", "2xl": "md" }}
              >
                {category.value === "" ? "Category" : category.value}
              </Text>
            </MenuButton>
            <MenuList
              p={"0.5rem"}
              borderRadius={"0.5rem"}
              minW={"130px"}
              shadow={"md"}
              border={"1px solid"}
              borderColor={"light-gray"}
              maxH={"300px"}
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#e6e7eb",
                  borderRadius: "30px"
                }
              }}
            >
              {[{ id: undefined, name: "All Categories", desc: "" }, ...(getListTopic || [])]?.map(
                (item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeCategory(item.id, item.name)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block"
                        }
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={
                <div className="ml-[16px]">
                  <img src={ChevronDownIcon} alt="icon" />
                </div>
              }
              w={"fit-content"}
              height={"42px"}
              variant={"growth-green-solid"}
              px={"18px"}
            >
              <Text
                color={"#4CAF50"}
                fontWeight={900}
                fontSize={{ base: "sm", "2xl": "md" }}
                textAlign={"start"}
              >
                {type.value === "" ? "Type" : type.value}
              </Text>
            </MenuButton>
            <MenuList
              p={"0.5rem"}
              borderRadius={"0.5rem"}
              minW={"130px"}
              maxH={"300px"}
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#e6e7eb",
                  borderRadius: "30px"
                }
              }}
              shadow={"md"}
              border={"1px solid"}
              borderColor={"light-gray"}
            >
              {[{ id: undefined, name: "All Types", desc: "" }, ...(getListWritingType || [])]?.map(
                (item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeType(item.id, item.name)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block"
                        }
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Box
        bg={"white"}
        rounded={"0 0 0.75rem 0.75rem"}
        display={"flex"}
        minH={"550px"}
        flexFlow={"column"}
        overflow={{ base: "auto", md: "hidden" }}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#e6e7eb",
            borderRadius: "30px"
          }
        }}
      >
        <TableComponent
          isLoading={isFetching}
          columns={columns}
          data={dataTable ?? []}
          headerHeight={"46px"}
          fontsizeTable={{ base: "sm", "2xl": "md" }}
          handleSort={handleSort}
          currentSort={{ sortBy: pagination.sortBy, sort: pagination.sort }}
          haveFooter={false}
        />
        {dataTable?.length === 0 && !isFetching ? (
          <Box minH={"500px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <NoDataComponent isFetching={isFetching} />
          </Box>
        ) : null}

        {getListArticles?.totalPage > 0 ? (
          <Box
            borderBottomRadius={"12px"}
            p={"1.25rem 1.5rem"}
            mt={"auto"}
            borderTop="1px solid"
            borderColor={"anti-flash-lite"}
            background={"light.100"}
          >
            <PaginationComponent
              currentPage={pagination.page}
              totalPages={getListArticles?.totalPage}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : null}
      </Box>
      {modal === "analytic" ? (
        <SuccessModal
          isOpen
          title="Article Analytics"
          onClick={() => setModal("")}
          hideFooter
          minW="726px"
        >
          <div className="flex flex-wrap gap-[31px]">
            {listAnalytic?.map((item) => {
              return (
                <div
                  key={item.id}
                  className="bg-white p-6 rounded-[12px] border border-[#F2F3F7] shadow-[0px_4px_4px_0px_#F2F3F780] min-w-[200px]"
                >
                  <p>{item?.name}</p>
                  <p className="font-[800] text-[32px] mt-3">{item?.value}</p>
                </div>
              );
            })}
            <div className="bg-white p-6 rounded-[12px] border border-[#F2F3F7] shadow-[0px_4px_4px_0px_#F2F3F780]">
              <div className="flex items-center gap-2">
                <p className="font-[800]">Article Earnings</p>
                <Tooltip
                  rounded={"0.75rem"}
                  label={EARNING_TEXT?.map((item, index) => (
                    <p key={index} className="text-[10px]">
                      {item}
                    </p>
                  ))}
                  placement={"top-start"}
                  bg={"anti-flash-white"}
                  p={{ base: "10px", md: "1rem" }}
                  cursor={"pointer"}
                  color={"jet-black"}
                  fontSize={"10px"}
                  maxW={"321px"}
                >
                  <img src={HelpIcon} alt="" />
                </Tooltip>
              </div>
              <div className="flex items-center justify-between gap-[18px] mt-2">
                <p className="text-[#204A22] text-[28px] font-[800]">
                  ${formartNumber(detailData?.earnings || 0, 2)}
                </p>
                <div
                  className="flex gap-2 items-center hover:opacity-80 cursor-pointer"
                  onClick={() =>
                    window.open(`${process.env.REACT_APP_CREATOR_URL}earnings/my-earnings`)
                  }
                >
                  <p className="text-[#4CAF50] text-sm font-[900]">For more details</p>
                  <img className="fill-[#4CAF50]" src={ArrowUpLightGreen} alt="" />
                </div>
              </div>
            </div>
          </div>
        </SuccessModal>
      ) : null}
      {modal === "share-article" ? (
        <ShareContentModal
          isOpen
          title="Share Article"
          shareUrl={`${process.env.REACT_APP_NEWSLETTER_URL}article-detail?id=${detailData?.id}`}
          media={detailData?.cover}
          onClick={() => setModal("")}
          titleBtn="Done"
          minW="439px"
        />
      ) : null}
      {modal === "remove-item" ? (
        <ConfirmModal
          isOpen={true}
          Icon={FailLoadImage}
          title={"Delete Article?"}
          desc={"This action will permanently delete your article"}
          titleBtn={"Delete"}
          onClick={() => {
            handleDeleteArticle(detailData?.id);
          }}
          isLoading={false}
          onClose={() => {
            setModal("");
          }}
        />
      ) : null}
    </Box>
  );
};
