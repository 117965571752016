import { Helmet } from "react-helmet";
import WriterEditor from "../../routes/WriterEditor";
import { useEffect, useState } from "react";
import { useAtom } from "jotai";
import { articlePayloadAtom } from "../../components/CreateArticle/atom";
import { useSearchParams } from "react-router-dom";
import { useQuery } from "@tanstack/react-query";
import { fetchArticleDetail } from "../../apis/article.api";
import {
  contributorAtom,
  hashtagAtom
} from "../../components/Common/Form/textarea-custom-hook-form/atom";

const EditArticle = () => {
  const [searchParams] = useSearchParams();
  const [, setArticlePayload] = useAtom(articlePayloadAtom);
  const { data: getArticleDetail, isFetching } = useQuery({
    queryKey: ["article-detail", searchParams.get("id")],
    queryFn: () => fetchArticleDetail(searchParams.get("id"), true),
    cacheTime: 0
  });
  const [, setHashtag] = useAtom(hashtagAtom);
  const [, setContributors] = useAtom(contributorAtom);

  const [step, setStep] = useState(Number(searchParams.get("step")) || 1);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const header = document.getElementById("writer-header");
    if (step === 4) {
      header.style.display = "none";
    } else {
      header.style.display = "flex";
    }
    return () => {
      header.style.display = "flex";
    };
  }, [step]);

  useEffect(() => {
    if (getArticleDetail) {
      const payload = {
        articleId: searchParams.get("id"),
        writingTypeId: getArticleDetail.writingTypeId,
        title: getArticleDetail.title,
        subTitle: getArticleDetail.subTitle,
        description: getArticleDetail.description,
        content: getArticleDetail.content,
        topicIds: getArticleDetail.topics || [],
        isEnableAISummaries: getArticleDetail.isEnableAISummaries,
        cover: getArticleDetail.cover,
        hashtags: getArticleDetail.hashtags.map((item) => item.name) || [],
        organization: getArticleDetail.organization,
        contributors: getArticleDetail.contributors,
        isPublished: getArticleDetail.isPublished
      };
      setHashtag(payload.hashtags);
      setContributors(
        payload.contributors.map((user) => {
          return {
            user: {
              id: user.id,
              imgUrl: user.imgUrl,
              firstName: user.firstName,
              lastName: user.lastName
            },
            role: {
              name: user.role,
              value: user.role
            }
          };
        })
      );
      setArticlePayload(payload);
    }
    !isFetching && setLoading(false);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isFetching, getArticleDetail]);

  return (
    <div>
      <Helmet>
        <title>Edit Article</title>
      </Helmet>
      {!loading && <>{step === 4 && <WriterEditor setStep={setStep} />}</>}
    </div>
  );
};

export default EditArticle;
