import { Outlet } from "react-router-dom";
import HeaderWriterComponent from "../../HeaderWriter";

const WriterLayout = () => {
  return (
    <div className="writer-layout max-w-[1920px] mx-auto mt-[56px] md:mt-[80px] bg-[#FAFAFA] min-h-[calc(100vh-80px)] pb-[10px]">
      <HeaderWriterComponent />
      <Outlet />
    </div>
  );
};

export default WriterLayout;
