import * as React from "react";
import AvaIcon from "../../../../assets/ava-icon.svg";
import { useAtom } from "jotai";
import { profileAtom } from "../../../../components/Settings/atom";
import TwygLogo from "../../../../assets/twyg-logo-icon.svg";
import EyeIcon from "../../../../assets/eye-icon.svg";
import EditSquareIcon from "../../../../assets/edit-square-icon.svg";
import CollapseIcon from "../../../../assets/collapse-icon.svg";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { useNavigate } from "react-router-dom";
import { collapseAtom } from "../../plugins/TableOfContentsPlugin/atom";

const TopBarMobileComponent = () => {
  const [profile] = useAtom(profileAtom);
  const [collapse, setCollapse] = useAtom(collapseAtom);
  const [editor] = useLexicalComposerContext();
  const [isEditable, setIsEditable] = React.useState(() => editor.isEditable());
  const navigate = useNavigate();

  return (
    <div className="w-full flex md:!hidden items-center justify-between bg-white z-10 py-[10px] px-[15px] border-b border-[#EAECF0]">
      <div className="flex">
        <div className="p-[10px] hover:opacity-80 cursor-pointer">
          <img
            src={CollapseIcon}
            alt="collapse"
            onClick={() => {
              setCollapse(!collapse);
            }}
          />
        </div>
        <div
          className="flex items-center gap-2 hover:opacity-80 cursor-pointer"
          onClick={() => navigate("/article/manage")}
        >
          <img src={TwygLogo} alt="logo" className="h-8 2xl:h-12 " />
          <p className="text-sm !text-growth-green font-[800] whitespace-nowrap">Dashboard</p>
        </div>
      </div>

      <div className="flex items-center gap-2">
        <div className="bg-[#F2F3F7] rounded-full h-10 w-10 flex items-center justify-center cursor-pointer hover:opacity-80">
          <img
            src={isEditable ? EyeIcon : EditSquareIcon}
            onClick={() => {
              editor.setEditable(!editor.isEditable());
              setIsEditable(!isEditable);
            }}
            alt=""
          />
        </div>
        <img
          src={profile.imgUrl || AvaIcon}
          alt="avatar"
          className="min-w-[40px] min-h-[40px] max-w-[40px] max-h-[40px] rounded-full border border-solid border-[#E6E7EB] cursor-pointer hover:opacity-80"
        />
      </div>
    </div>
  );
};

export default TopBarMobileComponent;
