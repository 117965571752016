import axiosInstance from "./axios-instance";

export const createRequest = async (payload) => {
  const result = await axiosInstance.post(`${process.env.REACT_APP_TWYGS_API_URL}requests`, {
    ...payload
  });
  return result.data.data;
};

export const fetchListRequests = async (pagination) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}requests`, {
    params: pagination
  });
  return result.data.data;
};

export const fetchRequestDetail = async (id) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}requests/${id}/detail`
  );
  return result.data.data;
};

export const fetchRequestContentTypes = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}requests/content-types`,
    {
      params: pagination
    }
  );
  return result.data.data;
};

export const deleteRequest = async (id) => {
  try {
    const result = await axiosInstance.delete(`/requests/${id}`);
    return result.data.data;
  } catch (error) {
    console.error("Error deleting requests:", error);
    throw error;
  }
};
