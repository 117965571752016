import { Box } from "@chakra-ui/react";

import { Field } from "../../field";
import ErrorMessageComponent from "../../../../ErrorMessage";

const FieldHookForm = (props) => {
  const {
    children,
    name,
    label,
    description,
    fontSizeError,
    isHiddenBottomContent,
    bottomContentProps,
    isHiddenError,
    isOptional,
    bottomRightContent,
    colorDes
  } = props;

  return (
    <Field label={label} description={description} colorDes={colorDes} isOptional={isOptional}>
      {children}
      {!isHiddenBottomContent && (
        <Box mt={"0.25rem"} {...bottomContentProps}>
          {!isHiddenError && <ErrorMessageComponent name={name} fontSizeError={fontSizeError} />}
          {bottomRightContent}
        </Box>
      )}
    </Field>
  );
};

export { FieldHookForm };
