const CardAuthorSkeleton = () => {
  return (
    <div className="flex flex-col gap-4">
      {Array.from({ length: 3 }).map((_, index) => (
        <div key={index} className="flex items-start gap-4 animate-pulse">
          {/* Avatar Skeleton */}
          <div className="w-[40px] h-[40px] 2xl:w-[80px] 2xl:h-[80px] rounded-full bg-gray-300" />

          {/* Content Skeleton */}
          <div className="flex flex-col gap-2 w-full">
            {/* Name and Button Skeleton */}
            <div className="flex items-center gap-4">
              <div className="w-24 h-6 2xl:w-32 2xl:h-8 bg-gray-300 rounded" />
              <div className="hidden md:block w-20 h-8 bg-blue-300 rounded" />
            </div>

            {/* Description Skeleton */}
            <div className="w-full h-6 bg-gray-300 rounded" />
            <div className="w-3/4 h-6 bg-gray-300 rounded" />
          </div>

          {/* Mobile Button Skeleton */}
          <div className="block md:hidden w-20 h-8 bg-blue-300 rounded" />
        </div>
      ))}
    </div>
  );
};

export default CardAuthorSkeleton;
