import { useNavigate } from "react-router-dom";
import CardAuthor from "./card-author.component";
import CardAuthorSkeleton from "./card-author-loading.component";
import { fetchListFeaturedVoices, followUser, unfollowUser } from "../../../../apis/discovery.api";
import { useState } from "react";
import { useEffect } from "react";
import { useAtomValue } from "jotai";
import { profileAtom } from "../../../Settings/atom";

const FeaturedVoices = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [listFeaturedVoices, setListFeaturedVoices] = useState([]);
  const [loadingAuthorId, setLoadingAuthorId] = useState(null);

  const profile = useAtomValue(profileAtom);

  const handleFollowUser = async (id, isCurrentlyFollowing) => {
    try {
      setLoadingAuthorId(id);
      if (isCurrentlyFollowing) {
        await unfollowUser(id);
      } else {
        await followUser(id);
      }
      setListFeaturedVoices((prevResults) =>
        prevResults.map((user) =>
          user.id === id ? { ...user, isFollowing: !isCurrentlyFollowing } : user
        )
      );
    } catch (error) {
      console.error("Error updating follow status:", error);
    } finally {
      setLoadingAuthorId(null);
    }
  };

  useEffect(() => {
    const getFeaturedVoices = async () => {
      try {
        const result = await fetchListFeaturedVoices({}, profile.id);
        if (result) {
          setListFeaturedVoices(result?.data);
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getFeaturedVoices();
  }, [profile.id]);

  return (
    <div>
      <div className="flex items-center justify-between mb-[16px] 2xl:mb-[24px]">
        <h1 className="text-xl 2xl:text-2xl !text-[#6A4E42] font-[700]">Featured Voices</h1>
        {listFeaturedVoices?.length > 4 && (
          <p
            className="text-base 2xl:text-lg !text-growth-green font-[900] cursor-pointer hover:opacity-80"
            onClick={() => navigate("/featured-voices")}
          >
            View All
          </p>
        )}
      </div>
      <div className="flex flex-col gap-[24px]">
        {loading && <CardAuthorSkeleton />}
        {listFeaturedVoices?.slice(0, 4).map((item) => (
          <CardAuthor
            key={item.id}
            author={item}
            handleFollowUser={handleFollowUser}
            isHiddenInfo
            isSmallText
            isLoading={loadingAuthorId === item.id}
          />
        ))}
      </div>
    </div>
  );
};

export default FeaturedVoices;
