import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../assets/arrow-left-white-icon.svg";
import { useEffect, useState } from "react";
import { fetchListFeaturedVoices, followUser, unfollowUser } from "../../apis/discovery.api";
import CardAuthor from "../../components/DiscoveryComponent/RightContentComponent/components/card-author.component";
import { useAtomValue } from "jotai";
import { profileAtom } from "../../components/Settings/atom";

const FeaturedVoicesPage = () => {
  const navigate = useNavigate();
  const [listFeaturedVoices, setListFeaturedVoices] = useState([]);
  const [loadingAuthorId, setLoadingAuthorId] = useState(null);
  const [pagination, setPagination] = useState({
    page: 1,
    limit: 10,
    totalPage: 0
  });
  const [loading, setLoading] = useState(true);

  const profile = useAtomValue(profileAtom);

  const handleLoadMore = () => {
    const scrollable = document.documentElement.scrollHeight - window.innerHeight;
    const scrolled = window.scrollY;
    if (scrolled >= scrollable - 300 && !loading && pagination.page < pagination.totalPage) {
      setLoading(true);
      setPagination((prevPagination) => ({
        ...prevPagination,
        page: prevPagination.page + 1
      }));
    }
  };

  const handleFollowUser = async (id, isCurrentlyFollowing) => {
    try {
      setLoadingAuthorId(id);
      if (isCurrentlyFollowing) {
        await unfollowUser(id);
      } else {
        await followUser(id);
      }
      setListFeaturedVoices((prevResults) =>
        prevResults.map((user) =>
          user.id === id ? { ...user, isFollowing: !isCurrentlyFollowing } : user
        )
      );
    } catch (error) {
      console.error("Error updating follow status:", error);
    } finally {
      setLoadingAuthorId(null);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleLoadMore);
    return () => {
      window.removeEventListener("scroll", handleLoadMore);
    };
  });

  useEffect(() => {
    const getFeaturedVoices = async () => {
      setLoading(true);
      try {
        const result = await fetchListFeaturedVoices(pagination, profile.id);
        if (result) {
          setListFeaturedVoices((prevList) =>
            pagination.page === 1 ? result.data : [...prevList, ...result.data]
          );
          setPagination((prevPagination) => ({
            ...prevPagination,
            totalPage: result.totalPage
          }));
        }
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    };
    getFeaturedVoices();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pagination.page, profile.id]);

  return (
    <div>
      <Helmet>
        <title>Featured Voices</title>
      </Helmet>
      <div className="flex flex-start flex-col px-4 md:px-0">
        <div className="flex sticky top-[54px] md:top-[80px] pt-2 md:pt-10 z-20 bg-white">
          <div
            className="p-1 mt-1 2xl:p-2 rounded-[80px] h-fit bg-[#4CAF50] cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              className="max-w-5 max-h-5 min-w-5 min-h-5 xl:max-w-10 xl:max-h-10 xl:min-w-10 xl:min-h-10"
              src={ArrowLeftIcon}
              alt=""
            />
          </div>
          <p className="text-[24px] xl:text-[48px] font-[800] w-full mx-auto pl-8 2xl:pl-0 max-w-[1090px] text-[#4CAF50]">
            Featured Voices
          </p>
        </div>

        <div className="w-full max-w-[1072px] mx-auto px-0 md:px-5 mt-6 md:mt-10 flex flex-col gap-5 md:gap-10">
          {listFeaturedVoices?.map((item) => (
            <CardAuthor
              key={item.id}
              author={item}
              isHiddenInfo
              handleFollowUser={handleFollowUser}
              isLoading={loadingAuthorId === item.id}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default FeaturedVoicesPage;
