import IntroComponent from "../components/intro.components";
import { RequestTableComponent } from "../components/request-table.component";

const RequestComponent = () => {
  return (
    <div className="flex flex-col gap-6">
      <IntroComponent
        title="Community sourcing requests"
        des="Manage your community sourcing requests"
      />
      <RequestTableComponent></RequestTableComponent>
    </div>
  );
};

export default RequestComponent;
