import BreakingNews from "./components/breaking-news.component";
import FeaturedVoices from "./components/featured-voices.component";
import RecommendedTopics from "./components/recommended-topics.component";

const RightContentComponent = () => {
  return (
    <div className="w-full">
      <div className="mt-[30px] 2xl:mt-[60px] pl-[20px] 2xl:pl-[30px] flex flex-col gap-[20px] w-full 2xl:gap-[30px]">
        <RecommendedTopics />
        <BreakingNews />
        <FeaturedVoices />
      </div>
    </div>
  );
};

export default RightContentComponent;
