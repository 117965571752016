import { useMemo, useRef, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useAtom } from "jotai";

import LogoTwygs from "../../assets/logo-twygs.svg";
import AvatarIcon from "../../assets/ava-icon.svg";

import LogOutIcon from "../../assets/logout-icon.svg";
import WriteIcon from "../../assets/write-icon.svg";
import WriteGreenIcon from "../../assets/write-green-icon.svg";
import { useClickOutside } from "../../hooks/useOutsideClick";
import { removeAllCookies } from "../../constants";
import { profileAtom } from "../Settings/atom";
import NotificationComponent from "../Header/Notification/notification.component";

const HeaderWriterComponent = () => {
  const [profile, setProfile] = useAtom(profileAtom);

  const [open, setOpen] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();

  const listItem = useMemo(
    () => [
      // {
      //   name: "Twygs Ads",
      //   icon: AdvertiserIcon,
      //   onClick: () => {
      //     window.open(`${process.env.REACT_APP_ADVERTISER_URL}`, "_blank");
      //   },
      // },
      // {
      //   name: "Creator Studio",
      //   icon: CreatorIcon,
      //   onClick: () => {
      //     window.open(`${process.env.REACT_APP_CREATOR_URL}`, "_blank");
      //   },
      // },
      // {
      //   name: "Account Settings",
      //   icon: SettingIcon,
      //   onClick: () => {
      //     navigate("/account-settings");
      //   },
      // },
      {
        name: "Log Out",
        icon: LogOutIcon,
        onClick: () => {
          removeAllCookies();
          localStorage.clear();
          setProfile({});
          navigate(0);
        }
      }
    ],
    [navigate]
  );

  useClickOutside(dropdownRef, () => {
    setOpen(false);
  });

  const handleToggleProfileModal = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  return (
    <div className={`flex flex-col fixed top-0 left-0 w-full z-50`} id="writer-header">
      <div className={`bg-white w-full md:border-b-[2px] border-solid border-[#E6E7EB]`}>
        <div className="m-auto px-[20px] md:px-[50px] 2xl:px-[100px] w-full py-2 md:py-4 flex items-center">
          <div className="flex items-center">
            <img
              src={LogoTwygs}
              alt="logo"
              className="h-8 md:h-12 cursor-pointer"
              onClick={() => navigate("/")}
            />
            <p
              className="text-[40px] ml-6 font-extrabold hidden md:!block tracking-tighter text-[#4CAF50] cursor-pointer"
              onClick={() => navigate("/article/manage")}
            >
              Dashboard
            </p>
          </div>
          <div className="flex items-center gap-2 md:gap-[20px] relative ml-auto" ref={dropdownRef}>
            <div
              onClick={() => navigate("/article/create")}
              className="cursor-pointer hidden md:!flex items-center gap-2 bg-growth-green px-[20px] py-[12px] text-center !rounded-[8px] !text-white text-sm 2xl:text-base font-black hover:opacity-80"
            >
              Write
              <img src={WriteIcon} alt="write" />
            </div>
            <div
              onClick={() => navigate("/article/create")}
              className="bg-[#F2F3F7] flex md:hidden rounded-full h-10 w-10 items-center justify-center cursor-pointer"
            >
              <img src={WriteGreenIcon} alt="" />
            </div>
            <NotificationComponent avatar={profile?.imgUrl || AvatarIcon} />
            <img
              onClick={handleToggleProfileModal}
              src={profile?.imgUrl || AvatarIcon}
              alt="avatar"
              className="min-w-[35px] min-h-[35px] max-w-[35px] max-h-[35px] 2xl:min-w-[40px] 2xl:min-h-[40px] 2xl:max-w-[40px] 2xl:max-h-[40px] rounded-full border border-solid border-[#E6E7EB] cursor-pointer"
            />
            <div
              className={`${
                open ? "flex" : "hidden"
              } absolute z-20 top-[50px] right-0 p-[18px] flex flex-col gap-[8px] bg-white w-[270px] rounded-[12px] border border-solid border-[#F2F3F7] shadow-[0px_8px_12px_0px_#80808026]`}
            >
              {listItem?.map((item, index) => (
                <div
                  onClick={() => {
                    item.onClick();
                    setOpen(false);
                  }}
                  key={index}
                  className="hover:bg-[#A6D38D] hover:rounded-[8px] cursor-pointer flex items-center gap-[12px] px-[12px] py-[8px]"
                >
                  <img src={item.icon} alt={item.icon} />
                  <p className="font-extrabold text-sm 2xl:text-base">{item.name}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderWriterComponent;
