const IntroComponent = (props) => {
  const { title, des } = props;

  return (
    <div className="ml-[7px]">
      <p className="text-[24px] font-[800]">{title}</p>
      <p className="text-[#808080]">{des}</p>
    </div>
  );
};

export default IntroComponent;
