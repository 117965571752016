import React from "react";
import {
  Image,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from "@chakra-ui/react";
import Button from "../../Button";
import CloseModalIcon from "../../../assets/close-modal-icon.svg";

function SuccessModal(props) {
  const {
    isOpen,
    onClick,
    title,
    titleBtn,
    bgColorOverlay,
    isLoading,
    hideFooter = false,
    children,
    ...rest
  } = props;

  return (
    <Modal isOpen={isOpen} isCentered>
      <ModalOverlay backgroundColor={bgColorOverlay} />
      <ModalContent p={"32px"} {...rest} bgColor={"#FAFAFA"} rounded={"12px"}>
        <ModalHeader p={0} w={"100%"} m={0} maxWidth={"unset"}>
          <div className="flex w-full items-center justify-between pb-2 border-b border-[#D1C8C4]">
            <p className="text-xl font-[800]">{title}</p>
            <Image
              className="w-[40px] h-[40px] cursor-pointer"
              src={CloseModalIcon}
              onClick={onClick}
              alt=""
            />
          </div>
        </ModalHeader>
        <ModalBody mt={"24px"} p="0 !important">
          {children}
        </ModalBody>
        <ModalFooter
          w="100%"
          p="0"
          display={hideFooter ? "none" : "flex"}
          justifyContent={"center"}
        >
          <Button type="submit" w="100%" onClick={onClick} variant={""} isLoading={isLoading}>
            {titleBtn}
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default SuccessModal;
