import { ReactComponent as AddFileGreenIcon } from "../../assets/add-file-green-icon.svg";

import { Box, Center, Input, Spinner, Text, VStack } from "@chakra-ui/react";
import Dropzone from "react-dropzone";

const DropzoneTwygsComponent = ({
  validateFiles,
  title,
  desc,
  acceptFileTypes,
  isLoading,
  isSingle,
  notMutiple,
  hideContent,
  isError,
  name,
  label,
  isOptional,
  ...rest
}) => {
  return isLoading ? (
    <Center h={"140px"}>
      <Spinner
        thickness="4px"
        speed="0.65s"
        emptyColor="gray.200"
        color="dark-green"
        size={{ base: "lg", "2xl": "xl" }}
      />
    </Center>
  ) : (
    <Dropzone
      onDrop={(acceptedFiles) => {
        validateFiles([...acceptedFiles]);
      }}
      multiple={!notMutiple}
    >
      {({ getRootProps, getInputProps }) => {
        const inputProps = getInputProps();
        inputProps.multiple = true;
        inputProps.accept = acceptFileTypes;
        if (isSingle) inputProps.multiple = false;
        return (
          <VStack
            {...getRootProps()}
            w={"full"}
            backgroundColor="white"
            borderRadius="12px"
            position={"relative"}
            spacing={"1rem"}
            cursor={"pointer"}
            gap="16px"
            h={"auto"}
            justifyContent={"center"}
            css={{
              backgroundImage: `url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke=${isError ? "'red'" : "'%23A6D38D'"} stroke-width='3' stroke-dasharray='6%2c 14' stroke-dashoffset='39' stroke-linecap='square'/%3e%3c/svg%3e")`,
              borderRadius: "12px"
            }}
            p={"20px"}
            {...rest}
          >
            <AddFileGreenIcon style={{ borderRadius: "50%" }} />
            <Box display={hideContent ? "none" : "flex"} flexFlow={"column"} gap="16px">
              {title}
              {desc && typeof desc === "string" ? (
                <Text fontWeight="400" color="dim-gray" fontSize="xs" mt={"0.25rem"}>
                  {desc}
                </Text>
              ) : (
                <Box>{desc}</Box>
              )}
            </Box>
            <Input
              accept={acceptFileTypes}
              w="full"
              id="fileInput"
              type="file"
              position="absolute"
              inset={0}
              opacity={0}
              cursor="pointer"
              zIndex={10}
              // onChange={onChange}
              {...inputProps}
            />
          </VStack>
        );
      }}
    </Dropzone>
  );
};

export default DropzoneTwygsComponent;
