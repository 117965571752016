const Footer = () => {
  return (
    <div
      className={`bg-black flex justify-between 2xl:px-[100px] py-3 px-3 md:px-[50px] md:py-4 items-center fixed w-full left-0 bottom-0 z-30`}
    >
      <p className={`text-white text-xs md:text-sm font-[600]`}>
        © 2024 Twygs. All rights reserved.
      </p>
      <div
        className={`flex gap-[20px] md:gap-[40px] 2xl:gap-[80px] text-white text-xs md:text-sm font-[600]`}
      >
        <a href={`${process.env.REACT_APP_MAIN_URL}cookie-policy`} target="_blank" rel="noreferrer">
          Cookie Policy
        </a>
        <a
          href={`${process.env.REACT_APP_MAIN_URL}privacy-policy`}
          target="_blank"
          rel="noreferrer"
        >
          Privacy Policy
        </a>
        <a href={`${process.env.REACT_APP_MAIN_URL}terms-of-use`} target="_blank" rel="noreferrer">
          Terms of Use
        </a>
      </div>
    </div>
  );
};

export default Footer;
