import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import {
  Box,
  Button,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useMutation, useQuery } from "@tanstack/react-query";
import PaginationComponent from "../../Pagination";
import NoDataComponent from "../../NoData";
import TableComponent from "../../Table";
import InputSearchComponent from "../../Common/Form/input-search";
import { formatDate } from "../../../utils/helper.util";
import ChevronDownIcon from "../../../assets/chevron-down-green-icon.svg";
import EyeGreenIcon from "../../../assets/eye-light-green-icon.svg";
import RedirectIcon from "../../../assets/redirect-icon-upper.svg";
import DeleteIcon from "../../../assets/bin-red-icon.svg";
import {
  deleteResponse,
  fetchListResponses,
  fetchResponseContentTypes
} from "../../../apis/response.api";
import { useDebounce } from "use-debounce";
import ViewResponseDetailModal from "./modal/view-response-detail.component";
import ConfirmModal from "../../Modal/ConfirmModal";
import FailLoadImage from "../../../assets/failed-to-load.svg";

export const ResponseTableComponent = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const menuRef = useRef();
  const [modals, setModals] = useState([]);
  const [selectedResponse, setSelectedResponse] = useState({});

  const MODAL = {
    VIEW_DETAIL: "view-detail",
    DELETE: "delete"
  };

  // custom range values
  const [minEquityPercentage, setMinEquityPercentage] = useState(undefined);
  const [maxEquityPercentage, setMaxEquityPercentage] = useState(undefined);
  const [debouncedMinEquity] = useDebounce(minEquityPercentage, 500);
  const [debouncedMaxEquity] = useDebounce(maxEquityPercentage, 500);

  const [dataTable, setDataTable] = useState([]);
  const [pagination, setPagination] = useState({
    limit: 4,
    page: 1,
    sortBy: undefined,
    sort: undefined,
    search: undefined,
    userId: JSON.parse(localStorage.getItem("user"))?.id
  });
  const [equityPercentage, setEquityPercentage] = useState({
    id: undefined,
    name: undefined,
    from: undefined,
    to: undefined
  });
  const [type, setType] = useState({
    id: undefined,
    name: undefined
  });
  const listEquityPercentage = [
    { id: undefined, name: "Equity percentage", from: undefined, to: undefined },
    { id: 1, name: "0-5%", from: 0, to: 5 },
    { id: 2, name: "5-15%", from: 5, to: 15 },
    { id: 3, name: "10-15%", from: 10, to: 15 },
    { id: 4, name: "15-20%", from: 15, to: 20 },
    { id: 5, name: ">20%", from: 20, to: undefined },
    { id: 6, name: "Custom range", from: undefined, to: undefined }
  ];

  // list requests params
  const params = {
    ...pagination,
    contentTypeIds: [type.id],
    equityPercentageFrom: equityPercentage.from,
    equityPercentageTo: equityPercentage.to
  };

  const filteredParams = Object.fromEntries(
    Object.entries(params).filter(([, value]) => value !== undefined)
  );

  const {
    data: getListResponses,
    isFetching,
    refetch
  } = useQuery({
    queryKey: ["getListResponses", filteredParams],
    queryFn: () => fetchListResponses(filteredParams)
  });

  const { data: getListTopic } = useQuery({
    queryFn: fetchResponseContentTypes
  });

  const { mutateAsync: deleteResponseAsync } = useMutation({
    mutationFn: deleteResponse
  });

  useEffect(() => {
    if (getListResponses) {
      setDataTable(getListResponses?.data);
    }
  }, [getListResponses, type.id]);

  // Close the menu when clicking outside
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        onClose();
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    if (minEquityPercentage === undefined && maxEquityPercentage === undefined) return;
    setEquityPercentage({
      ...equityPercentage,
      from: minEquityPercentage || undefined,
      to: maxEquityPercentage || undefined
    });
  }, [debouncedMinEquity, debouncedMaxEquity]);

  const handleChangePage = (page) => {
    setDataTable(() => []);
    setPagination((pre) => {
      return {
        ...pre,
        page
      };
    });
  };

  const handleChangeSearch = (search) => {
    setPagination((pre) => {
      return {
        ...pre,
        page: 1,
        search: search
      };
    });
  };

  const handleChangeType = (item) => () => {
    setType(item);
    setPagination((pre) => {
      return {
        ...pre,
        page: 1
      };
    });
  };

  const handleChangeEquityPercentage = (item) => () => {
    setMinEquityPercentage(undefined);
    setMaxEquityPercentage(undefined);
    setEquityPercentage(item);
    setPagination((pre) => {
      return {
        ...pre,
        page: 1
      };
    });

    // Prevent the menu from closing
    onOpen();
  };

  const handleDeleteResponse = async (id) => {
    try {
      await deleteResponseAsync(id);
      setModals([]);
      refetch();
    } catch (error) {
      console.error("Error deleting response:", error);
      throw error;
    }
  };

  const handleCloseModal = (modalName) => {
    setModals(modals.filter((modal) => modal !== modalName));
  };

  const handleShowConfirmDeleteModal = (data) => {
    setSelectedResponse(data);
    setModals([...modals, MODAL.DELETE]);
  };

  const convertSubmissions = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box ml={"12px"}>
            <Box>
              <Text
                fontWeight={400}
                fontSize={"14px"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.title}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertNotes = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box>
            <Box>
              <Text
                fontWeight={400}
                fontSize={"14px"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.note}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertRequester = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box>
            <Box className="flex items-center">
              <img
                className="h-[24px] w-[24px] rounded-full mr-[8px]"
                src={row?.user?.imgUrl}
                alt=""
              />
              <Text
                fontWeight={400}
                fontSize={"14px"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.user?.firstName} {row?.user?.lastName}
              </Text>
            </Box>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertEquityPercentage = useCallback(
    (row) => {
      return (
        <Box
          display={"flex"}
          alignItems={"center"}
          width={{ base: "calc(100vw - 128px)", md: "unset" }}
        >
          <Box ml={"12px"}>
            <Box>
              <Text
                fontWeight={400}
                fontSize={"14px"}
                title={row?.title}
                overflow={"hidden"}
                textOverflow={"ellipsis"}
                wordBreak={"break-word"}
                sx={{
                  display: "-webkit-inline-box",
                  WebkitLineClamp: "1",
                  WebkitBoxOrient: "vertical"
                }}
              >
                {row?.equityPercentage}%
              </Text>
            </Box>
          </Box>
        </Box>
      );
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  );

  const convertRequestLink = useCallback((row) => {
    return (
      <Box className="grid grid-cols-[auto,auto] gap-[5px]">
        <a
          href={`${process.env.REACT_APP_NEWSLETTER_URL}request/${row.requestId}/detail`}
          target="_blank"
          rel="noreferrer"
          className="text-[#4CAF50] font-black whitespace-nowrap"
        >
          Open link
        </a>
        <div className="flex items-end w-[20px]">
          <img src={RedirectIcon} alt="" className="" />
        </div>
      </Box>
    );
  }, []);

  const convertType = useCallback((row) => {
    return (
      <Box>
        {row?.contentTypes?.map((item) => (
          <Box
            key={item.id}
            px={"8px"}
            py={"4px"}
            rounded={"80px"}
            bgColor={"#F0EDEC"}
            w={"fit-content"}
            whiteSpace={"nowrap"}
            marginBottom={"6px"}
          >
            <Text title={item?.name} color="#6A4E42" fontWeight={800} fontSize={"xs"}>
              {item?.name}
            </Text>
          </Box>
        ))}
      </Box>
    );
  }, []);

  const convertStatus = useCallback((row) => {
    let text = "";
    let textColor = "";
    let bgColor = "";
    let fontWeight = 0;
    switch (row.status) {
      case "rejected": {
        text = "Rejected";
        textColor = "#FFFFFF";
        bgColor = "#DE1414";
        fontWeight = 800;
        break;
      }
      case "approved": {
        text = "Approved";
        textColor = "#FFFFFF";
        bgColor = "#4CAF50";
        fontWeight = 800;
        break;
      }
      case "pending": {
        text = "Pending";
        textColor = "#204A22";
        bgColor = "#EDF7EE";
        fontWeight = 600;
        break;
      }
      default: {
      }
    }
    return (
      <Box p={"12px"} rounded={"80px"} bgColor={bgColor} w={"fit-content"}>
        <Text color={textColor} fontSize={"xs"} fontWeight={fontWeight}>
          {text}
        </Text>
      </Box>
    );
  }, []);

  const convertAction = useCallback((row) => {
    return (
      <Box display="flex" gap="12px" alignItems={"center"}>
        <img
          src={EyeGreenIcon}
          className="cursor-pointer p-[10px] hover:opacity-80"
          alt="icon"
          onClick={() => {
            setSelectedResponse(row);
            setModals([MODAL.VIEW_DETAIL]);
          }}
        />
        <img
          src={DeleteIcon}
          className="p-[10px] cursor-pointer hover:opacity-80"
          hidden={row.status !== "pending" ? true : false}
          alt="icon"
          onClick={() => {
            handleShowConfirmDeleteModal(row);
          }}
        />
      </Box>
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSort = (sortName) => {
    let objSort = {
      sortBy: pagination.sortBy,
      sort: pagination.sort
    };
    if (sortName !== objSort.sortBy) objSort = { sortBy: sortName, sort: "asc" };
    else if (sortName === objSort.sortBy && objSort.sort === "asc")
      objSort = {
        ...objSort,
        sort: "desc"
      };
    else if (sortName === objSort.sortBy && objSort.sort === "desc")
      objSort = {
        ...objSort,
        sort: "asc"
      };
    setPagination({ ...pagination, ...objSort });
  };

  useEffect(() => {
    console.log(modals);
  }, [modals]);

  const columns = useMemo(
    () => [
      {
        Header: "Submissions",
        accessor: (row) => convertSubmissions(row),
        id: "title",
        isSortField: true,
        widthCustom: "210px"
      },
      {
        Header: "Notes",
        accessor: (row) => convertNotes(row),
        id: "note",
        isSortField: false,
        widthCustom: "250px"
      },
      {
        Header: "Requester",
        accessor: (row) => convertRequester(row),
        id: "requester",
        isSortField: false,
        widthCustom: "calc(100% - 35.5rem)"
      },
      {
        Header: "Request link",
        accessor: (row) => convertRequestLink(row),
        id: "requestLink",
        widthCustom: "5rem",
        isSortField: false,
        minWidthCustom: "5rem"
      },
      {
        Header: "Type",
        accessor: (row) => convertType(row),
        id: "requestTypes",
        widthCustom: "fit-content",
        minWidthCustom: "fit-content"
      },
      {
        Header: "Proposed equity",
        accessor: (row) => convertEquityPercentage(row),
        id: "equityPercentage",
        isSortField: true,
        widthCustom: "calc(100% - 35.5rem)"
      },
      {
        Header: "Status",
        accessor: (row) => convertStatus(row),
        id: "status",
        widthCustom: "7rem",
        minWidthCustom: "7rem"
      },
      {
        Header: "Date Sent",
        accessor: (row) => <Text fontSize={"sm"}>{formatDate(row?.createdAt, true)}</Text>,
        id: "createdAt",
        widthCustom: "10rem",
        minWidthCustom: "10rem",
        isSortField: true
      },
      {
        Header: "Actions",
        accessor: (row) => convertAction(row),
        widthCustom: "16rem",
        minWidthCustom: "16rem",
        id: "action"
      }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [convertSubmissions, convertType, convertStatus, convertRequestLink, convertAction, handleSort]
  );

  return (
    <Box
      w={"full"}
      border={"1px solid"}
      borderColor={"anti-flash-lite"}
      borderRadius={"12px"}
      shadow={"outline"}
    >
      <Box
        bg={"white"}
        p={"16px 24px"}
        display={"flex"}
        alignItems={"start"}
        minH={"72px"}
        borderTopRadius={"12px"}
        justifyContent={"space-between"}
        overflow={"auto"}
      >
        <Box
          display={"flex"}
          gap="12px"
          flexDirection={{ base: "column", md: "row" }}
          width={"full"}
          overflow={"auto"}
        >
          <InputSearchComponent
            setPaginationSearch={handleChangeSearch}
            placeholder={"Search responses"}
            setLoading={() => {}}
            width={{ base: "100%", md: "320px" }}
          />
          {/* Equity Percentage Menu List */}
          <div ref={menuRef}>
            <Menu isOpen={isOpen} onClose={() => {}}>
              <MenuButton
                as={Button}
                onClick={onOpen}
                rightIcon={
                  <div className="ml-[16px]">
                    <img src={ChevronDownIcon} alt="icon" />
                  </div>
                }
                height={"42px"}
                w={"fit-content"}
                variant={"growth-green-solid"}
                px={"18px"}
              >
                <Text
                  color={"#4CAF50"}
                  fontWeight={900}
                  textAlign={"start"}
                  fontSize={{ base: "sm", "2xl": "md" }}
                >
                  {equityPercentage.name || "Equity percentage"}
                </Text>
              </MenuButton>
              <MenuList
                p={"0.5rem"}
                borderRadius={"0.5rem"}
                minW={"205px"}
                shadow={"md"}
                border={"1px solid"}
                borderColor={"light-gray"}
                maxH={"300px"}
                overflow={"auto"}
                css={{
                  "&::-webkit-scrollbar": {
                    width: "6px"
                  },
                  "&::-webkit-scrollbar-thumb": {
                    background: "#e6e7eb",
                    borderRadius: "30px"
                  }
                }}
              >
                {listEquityPercentage?.map((item, index) => {
                  return (
                    <>
                      <MenuItem
                        key={index}
                        minW={"114px"}
                        onClick={handleChangeEquityPercentage(item)}
                        p={"8px 8px 8px 10px"}
                        _focus={{ bgColor: "white" }}
                        _hover={{
                          bgColor: "anti-flash-white",
                          "& .child-box": {
                            display: "block"
                          }
                        }}
                        rounded={"0.5rem"}
                        fontSize={"14px"}
                        lineHeight={"20px"}
                        fontWeight={"800"}
                        hidden={item.id === undefined ? true : false}
                        display={"block"}
                      >
                        <div className="flex items-center">
                          <input
                            type="radio"
                            className="w-5 h-5 mr-2"
                            checked={equityPercentage?.id === item.id}
                            onChange={(e) => e.stopPropagation()}
                          />
                          <p>{item.name}</p>
                        </div>
                      </MenuItem>
                      {item.id === 6 ? (
                        <div
                          hidden={equityPercentage.id !== 6 ? true : false}
                          id="custom-range-field"
                          className="grid grid-cols-2 ml-[28px] gap-x-[16px]"
                        >
                          <p className="font-[400]">Min</p>
                          <p className="font-[400]">Max</p>
                          <Input
                            placeholder={"Enter %"}
                            padding={"8px"}
                            height={"41px"}
                            width={"63.5px"}
                            type="number"
                            sx={{
                              "::placeholder": {
                                fontSize: "12px",
                                color: "gray.500"
                              }
                            }}
                            onInput={(e) => {
                              setMinEquityPercentage(e.target.value);
                            }}
                            value={minEquityPercentage === undefined ? "" : minEquityPercentage}
                          />
                          <Input
                            placeholder={"Enter %"}
                            padding={"8px"}
                            height={"41px"}
                            width={"63.5px"}
                            type="number"
                            sx={{
                              "::placeholder": {
                                fontSize: "12px",
                                color: "gray.500"
                              }
                            }}
                            onInput={(e) => {
                              setMaxEquityPercentage(e.target.value);
                            }}
                            value={maxEquityPercentage === undefined ? "" : maxEquityPercentage}
                          />
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  );
                })}
              </MenuList>
            </Menu>
          </div>

          {/* Type Menu List */}
          <Menu>
            <MenuButton
              as={Button}
              rightIcon={
                <div className="ml-[16px]">
                  <img src={ChevronDownIcon} alt="icon" />
                </div>
              }
              height={"42px"}
              w={"fit-content"}
              variant={"growth-green-solid"}
              px={"18px"}
            >
              <Text
                color={"#4CAF50"}
                fontWeight={900}
                textAlign={"start"}
                fontSize={{ base: "sm", "2xl": "md" }}
              >
                {type.name || "Type"}
              </Text>
            </MenuButton>
            <MenuList
              p={"0.5rem"}
              borderRadius={"0.5rem"}
              minW={"130px"}
              shadow={"md"}
              border={"1px solid"}
              borderColor={"light-gray"}
              maxH={"300px"}
              overflow={"auto"}
              css={{
                "&::-webkit-scrollbar": {
                  width: "6px"
                },
                "&::-webkit-scrollbar-thumb": {
                  background: "#e6e7eb",
                  borderRadius: "30px"
                }
              }}
            >
              {[{ id: undefined, name: "All Types" }, ...(getListTopic || [])]?.map(
                (item, index) => {
                  return (
                    <MenuItem
                      key={index}
                      minW={"114px"}
                      onClick={handleChangeType(item)}
                      p={"8px 8px 8px 10px"}
                      _focus={{ bgColor: "white" }}
                      _hover={{
                        bgColor: "anti-flash-white",
                        "& .child-box": {
                          display: "block"
                        }
                      }}
                      rounded={"0.5rem"}
                      fontSize={"14px"}
                      lineHeight={"20px"}
                      fontWeight={"800"}
                    >
                      {item.name}
                    </MenuItem>
                  );
                }
              )}
            </MenuList>
          </Menu>
        </Box>
      </Box>
      <Box
        bg={"white"}
        rounded={"0 0 0.75rem 0.75rem"}
        display={"flex"}
        minH={"550px"}
        flexFlow={"column"}
        overflow={{ base: "auto", md: "hidden" }}
        css={{
          "&::-webkit-scrollbar": {
            height: "8px"
          },
          "&::-webkit-scrollbar-thumb": {
            background: "#e6e7eb",
            borderRadius: "30px"
          }
        }}
      >
        <TableComponent
          isLoading={isFetching}
          columns={columns}
          data={dataTable ?? []}
          headerHeight={"46px"}
          fontsizeTable={{ base: "sm", "2xl": "md" }}
          handleSort={handleSort}
          currentSort={{ sortBy: pagination.sortBy, sort: pagination.sort }}
          haveFooter={false}
        />
        {dataTable?.length === 0 && !isFetching ? (
          <Box minH={"500px"} display={"flex"} alignItems={"center"} justifyContent={"center"}>
            <NoDataComponent isFetching={isFetching} />
          </Box>
        ) : null}

        {getListResponses?.totalPage > 0 ? (
          <Box
            borderBottomRadius={"12px"}
            p={"1.25rem 1.5rem"}
            mt={"auto"}
            borderTop="1px solid"
            borderColor={"anti-flash-lite"}
            background={"light.100"}
          >
            <PaginationComponent
              currentPage={pagination.page}
              totalPages={getListResponses?.totalPage}
              onChangePage={handleChangePage}
            />
          </Box>
        ) : null}
      </Box>
      {modals.find((modal) => modal === MODAL.VIEW_DETAIL) ? (
        <ViewResponseDetailModal
          isOpen
          onClose={() => {
            handleCloseModal(MODAL.VIEW_DETAIL);
          }}
          onSubmit={() => {
            handleCloseModal(MODAL.VIEW_DETAIL);
          }}
          onDelete={() => {
            handleShowConfirmDeleteModal(selectedResponse);
          }}
          minW="733px"
          maxH="838px"
          data={selectedResponse}
        />
      ) : null}
      {modals.find((modal) => modal === MODAL.DELETE) ? (
        <ConfirmModal
          isOpen={true}
          Icon={FailLoadImage}
          title={"Delete Submission?"}
          desc={"This action will permanently delete your submission"}
          titleBtn={"Delete"}
          onClick={() => {
            handleDeleteResponse(selectedResponse?.id);
          }}
          isLoading={false}
          onClose={() => {
            handleCloseModal(MODAL.DELETE);
          }}
        />
      ) : null}
    </Box>
  );
};
