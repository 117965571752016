import { Box, Text } from "@chakra-ui/react";

const Field = (props) => {
  const { children, label, description, colorDes, isOptional } = props;

  return (
    <Box w={"full"}>
      {label && (
        <Text mb={"12px"}>
          {label}{" "}
          {isOptional && (
            <Text as={"span"} color={"dim-gray"}>
              (optional)
            </Text>
          )}
        </Text>
      )}

      {description && (
        <Text mb={"12px"} fontSize={"1rem"} color={colorDes ?? "dim-gray"}>
          {description}
        </Text>
      )}
      {children}
    </Box>
  );
};

export { Field };
