import { Helmet } from "react-helmet";
import { useNavigate } from "react-router-dom";
import ArrowLeftIcon from "../../assets/arrow-left-white-icon.svg";
import FireIcon from "../../assets/fire-icon.svg";
import ArticleContainer from "../../components/ArticleContainer";
import { useGetListArticleByFilter } from "../../hooks/useGetListArticleByFilter";

const BreakingNewsPage = () => {
  const navigate = useNavigate();

  const getTotalTopic = (amount) => {
    return amount;
  };

  const { loading, listArticle, total } = useGetListArticleByFilter({ type: "breaking" });

  return (
    <div>
      <Helmet>
        <title>Breaking News</title>
      </Helmet>
      <div className="w-full flex flex-start flex-col px-4 md:px-0 relative">
        <div className="flex sticky top-[54px] md:top-[80px] pt-2 md:pt-10 z-20 bg-white">
          <div
            className="p-1 md:p-2 rounded-[80px] h-fit bg-[#4CAF50] mt-1 cursor-pointer"
            onClick={() => navigate("/")}
          >
            <img
              className="max-w-5 max-h-5 min-w-5 min-h-5 md:max-w-10 md:max-h-10 md:min-w-10 md:min-h-10"
              src={ArrowLeftIcon}
              alt=""
            />
          </div>
          <div className="w-full mr-[28px] md:mr-[56px]">
            <p className="text-center text-[24px] gap-2 md:text-[40px] font-[800] text-[#4CAF50] flex justify-center">
              Breaking News
              <img src={FireIcon} alt=""></img>
            </p>
            <p className="text-sm md:text-[24px] text-center font-[700] text-[#808080] mt-2 md:mt-[18px]">
              {getTotalTopic(total)} Stories
            </p>
          </div>
        </div>
        <div className="max-w-[1072px] w-full mx-auto px-0 md:px-5 flex mt-[24px] md:mt-[60px]">
          <ArticleContainer loading={loading} listArticle={listArticle} isHiddenFeaturedArticles />
        </div>
      </div>
    </div>
  );
};

export default BreakingNewsPage;
