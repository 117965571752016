import { useEffect, useState } from "react";
import { CATEGORY_LIST_ICON } from "../../../constants/article.constant";
import Button from "../../Button";
import ArrowRightIcon from "../../../assets/arrow-right-white-icon.svg";
import { useAtom } from "jotai";
import { articlePayloadAtom } from "../atom";
import { fetchListWrittingType } from "../../../apis/article.api";
import { Spinner } from "@chakra-ui/react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { contributorAtom, hashtagAtom } from "../../Common/Form/textarea-custom-hook-form/atom";

const ChooseTemplateComponent = ({ setStep }) => {
  const [articlePayload, setArticlePayload] = useAtom(articlePayloadAtom);
  const [, setContributors] = useAtom(contributorAtom);
  const [, setHashtag] = useAtom(hashtagAtom);
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();

  const [selectedType, setSelectedType] = useState(articlePayload?.writingTypeId || 1);
  const [listWringType, setListWritingtype] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const handleNextStep = (e) => {
    e.preventDefault();
    setArticlePayload({
      ...articlePayload,
      writingTypeId: searchParams.get("id") ? articlePayload?.writingTypeId : selectedType
    });
    setStep(4);
  };

  const handleDiscard = () => {
    navigate("/article/manage");
  };

  useEffect(() => {
    const fetchListWritingType = async () => {
      setIsLoading(true);
      try {
        const res = await fetchListWrittingType();
        if (res) {
          setListWritingtype(res);
          setIsLoading(false);
        }
      } catch (error) {
        setIsLoading(false);
        console.log(error);
      }
    };
    fetchListWritingType();
  }, []);

  useEffect(() => {
    if (selectedType !== articlePayload.writingTypeId) {
      setArticlePayload({});
      setContributors([]);
      setHashtag([]);
    }
  }, [selectedType]);

  return (
    <div className="mb-[120px] max-w-[775px] mx-auto">
      <div className="mb-8 text-center">
        <p className="text-2xl font-[800]">Get started with a template</p>
        <p className="!text-[#808080] mt-1">Select a template to bring your ideas to life</p>
      </div>
      {isLoading ? (
        <div className="flex gap-3 md:gap-6 mt-20 justify-center w-full overflow-hidden">
          <Spinner
            thickness="4px"
            speed="0.65s"
            emptyColor="gray.200"
            color="dark-green"
            size="lg"
          />
        </div>
      ) : (
        <div className="flex flex-wrap justify-center gap-3 md:gap-6">
          {listWringType.map((item, index) => (
            <div
              key={item.id}
              className={`${
                (articlePayload?.writingTypeId || selectedType) === item.id
                  ? "bg-[#EDF7EE]"
                  : "bg-none"
              } px-4 py-[30px] flex flex-col gap-3 items-center rounded-[16px] w-[167px] border-[2px] border-solid border-[#2D211C] text-center cursor-pointer transition duration-150`}
              onClick={() => {
                setSelectedType(item.id);
              }}
            >
              <img src={CATEGORY_LIST_ICON[index]?.icon} alt="" />
              <p className="text-lg font-[800]">{item.name}</p>
              <p className="text-xs font-[600]">{item.description}</p>
            </div>
          ))}
        </div>
      )}

      <div className="bg-white flex justify-end border-t-[1px] w-full border-solid border-[#E6E7EB] fixed left-0 bottom-0 py-[20px] px-[40px]">
        <Button variant="outline" className={`mr-4`} onClick={handleDiscard}>
          Discard
        </Button>

        <Button onClick={(e) => handleNextStep(e)}>
          Next
          <img src={ArrowRightIcon} alt="" className="ml-2" />
        </Button>
      </div>
    </div>
  );
};

export default ChooseTemplateComponent;
