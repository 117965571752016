import { useFormContext, useWatch } from "react-hook-form";
import { Text } from "@chakra-ui/react";

const FormInputCounter = (props) => {
  const { control } = useFormContext();
  const value = useWatch({ name: props.name, control });

  // Destructure props for clarity
  const { component: Component, ...restProps } = props;

  return (
    <>
      {/* Render the custom component */}
      <Component
        p={{ base: "12px 12px", "2xl": "17px 24px" }}
        name={props.name}
        placeholder={props.placeholder}
        label={props.label}
        bottomContentProps={{
          mt: "12px",
          display: "flex"
        }}
        isOptional={props.isOptional}
        bottomRightContent={
          <Text marginLeft={"auto"} fontSize={{ base: "xs", "2xl": "sm" }} color={"dim-gray"}>
            {value?.length ?? 0}/{props.maxLength.toLocaleString()}
          </Text>
        }
        {...restProps} // Spread the remaining props to the component
      />
    </>
  );
};

export default FormInputCounter;
