import React from "react";
import {
  Modal,
  ModalOverlay,
  ModalBody,
  ModalContent,
  ModalHeader,
  ModalFooter,
  Image,
  Box,
  Text
} from "@chakra-ui/react";
import CloseModalIcon from "../../../../assets/close-modal-icon.svg";
import Button from "../../../Button";
import UserCard from "../../../Profile/user-card";
import RequestCard from "../../../CreateRequest/components/request-card.component";
import InformIcon from "../../../../assets/inform-icon.svg";
import RenderMedia from "../../../MediaContainer/media.component";
import DeleteIcon from "../../../../assets/bin-red-icon.svg";

function ViewResponseDetailModal(props) {
  const { isOpen, onSubmit, onClose, bgColorOverlay, data, minW, maxH, onDelete } = props;

  const convertStatus = (row) => {
    let text = "";
    let textColor = "";
    let bgColor = "";
    let fontWeight = 0;
    switch (row.status) {
      case "rejected": {
        text = "Rejected";
        textColor = "#FFFFFF";
        bgColor = "#DE1414";
        fontWeight = 800;
        break;
      }
      case "approved": {
        text = "Approved";
        textColor = "#FFFFFF";
        bgColor = "#4CAF50";
        fontWeight = 800;
        break;
      }
      case "pending": {
        text = "Pending";
        textColor = "#204A22";
        bgColor = "#EDF7EE";
        fontWeight = 600;
        break;
      }
      default: {
      }
    }
    return (
      <Box px={"12px"} rounded={"80px"} bgColor={bgColor} w={"fit-content"}>
        <Text color={textColor} fontSize={"xs"} fontWeight={fontWeight}>
          {text}
        </Text>
      </Box>
    );
  };

  return (
    <Modal isOpen={isOpen} isCentered onClose={onClose}>
      <ModalOverlay backgroundColor={bgColorOverlay} />
      <ModalContent minW={minW} maxH={maxH} p={"32px"} bgColor={"#FAFAFA"} rounded={"12px"}>
        <ModalHeader p={0} w={"100%"} m={0} maxWidth={"unset"}>
          <div className="flex w-full items-center justify-between pb-2 border-b border-[#D1C8C4]">
            <p className="text-xl font-[800]">My response details</p>
            <Image
              className="w-[40px] h-[40px] cursor-pointer"
              src={CloseModalIcon}
              onClick={onSubmit}
              alt=""
            />
          </div>
        </ModalHeader>
        <ModalBody px={"10px"} overflow={"scroll"}>
          <div className="mt-6 mb-4">
            <p className="font-[800]">Requester:</p>
            <div className="mt-[8px] p-[10px] border border-green-500 rounded-[10px]">
              <UserCard user={data?.user} />
              <div className="my-[8px] md:border-b-[2px] border-solid border-[#E6E7EB]"></div>
              <RequestCard request={data?.request} showStatus={true} />
            </div>
          </div>
          <div className="mb-[10px] md:border-b-[2px] border-solid border-[#E6E7EB]"></div>
          <div className="">
            <p className="font-[800] text-[16px]">Your response:</p>
            <div className="my-[8px]">
              <div className="grid grid-rows-[auto,auto,auto] gap-[10px]">
                <div className="flex items-center gap-[5px]">
                  <span className="text-[#4CAF50] text-[14px] flex items-center gap-[5px]">
                    Proposed Equity
                    <img className="w-[15px] h-[15px]" src={InformIcon} alt="" />:
                  </span>
                  <span className="text-[14px]">{data?.equityPercentage}%</span>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="text-[#4CAF50] text-[14px] flex items-center gap-[5px]">
                    Response status:
                  </span>
                  {convertStatus(data)}
                </div>
                <div className="text-[14px]">
                  <p className="font-[800] inline text-[16px]">Title: </p>
                  {data?.title}
                </div>
                <div className="text-[16px] text-justify">
                  <p className="font-[800] text-[16px]">Note: </p>
                  <p className="text-[14px]">{data?.note}</p>
                </div>
                <div className="flex items-center gap-[5px]">
                  <span className="font-[800] inline text-[16px]">Content:</span>
                  <Box px={"12px"} rounded={"80px"} bgColor={"#F0EDEC"} w={"fit-content"}>
                    <Text color={"#6A4E42"} fontWeight={800} fontSize={"xs"}>
                      {data?.contentTypes[0]?.name}
                    </Text>
                  </Box>
                </div>
                <RenderMedia type={data?.contentTypes[0]?.name} url={data?.contentUrl} />
              </div>
            </div>
          </div>
        </ModalBody>
        <ModalFooter w="100%" py="3">
          {data?.status !== "pending" ? (
            <Button onClick={onSubmit} className="w-full h-[42px] text-[16px]">
              Done
            </Button>
          ) : (
            <>
              <Button
                onClick={() => {
                  onDelete(data?.id);
                }}
                variant="red-outline"
                className="w-[175px] h-[42px] text-[16px] whitespace-nowrap"
              >
                Delete response
                <img
                  src={DeleteIcon}
                  className="ml-[5px] cursor-pointer"
                  alt="icon"
                  onClick={() => {}}
                />
              </Button>
              <Button onClick={onSubmit} className="ml-[10px] w-[146px] h-[42px] text-[16px]">
                Done
              </Button>
            </>
          )}
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
}

export default ViewResponseDetailModal;
