import React from "react";

function RenderMedia({ type, url }) {
  switch (type) {
    case "Image":
      return <img src={url} alt="Dynamic Content" style={{ minWidth: "100%" }} />;
    case "Video":
      return (
        <video controls style={{ minWidth: "100%" }}>
          <source src={url} type="video/mp4" />
          Your browser does not support the video tag.
        </video>
      );
    case "PDF":
      return <iframe src={url} style={{ minWidth: "100%", height: "500px" }} title="PDF Viewer" />;
    default:
      return <p>Unsupported file type</p>;
  }
}

export default RenderMedia;
