import axiosInstance from "./axios-instance";

export const createResponse = async (payload) => {
  const result = await axiosInstance.post(`${process.env.REACT_APP_TWYGS_API_URL}responses`, {
    ...payload
  });
  return result.data.data;
};

export const fetchListResponses = async (pagination) => {
  const result = await axiosInstance.get(`${process.env.REACT_APP_TWYGS_API_URL}responses/me`, {
    params: pagination
  });
  return result.data.data;
};

export const fetchResponseContentTypes = async (pagination) => {
  const result = await axiosInstance.get(
    `${process.env.REACT_APP_TWYGS_API_URL}responses/content-types`,
    {
      params: pagination
    }
  );
  return result.data.data;
};

export const deleteResponse = async (id) => {
  try {
    const result = await axiosInstance.delete(`/responses/${id}`);
    return result.data.data;
  } catch (error) {
    console.error("Error deleting responses:", error);
    throw error;
  }
};
