import { Box, Center, Image, Text, Button } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import Background404 from "../../assets/404-background.svg";

const PageNotFound = () => {
  const navigate = useNavigate();

  return (
    <>
      <Center h="100vh" backgroundColor={"light.200"}>
        <Box maxW={"fit-content"} display={"flex"} flexFlow={"column"} alignItems={"center"}>
          <Image src={Background404} alt="" />
          <Text textAlign={"center"} mt={"0.75rem"} w={"full"} fontSize={"28px"} fontWeight={"600"}>
            Oops! It seems this page is missing from our digital path.
          </Text>
          <Text textAlign={"center"} mt={"0.75rem"} w={"full"} fontSize={"28px"} fontWeight={"400"}>
            Try reloading or head back to the homepage.
          </Text>
          <Button
            mt={"1.5rem"}
            bg={"#4CAF50"}
            color={"white"}
            fontWeight={"900"}
            h={"56px"}
            w={"233px"}
            marginY={"16px"}
            marginX={"20px"}
            onClick={() => {
              navigate("/");
            }}
            _hover={{ opacity: 0.8 }}
          >
            Go back to Homepage
          </Button>
        </Box>
      </Center>
    </>
  );
};

export default PageNotFound;
