import React, { useRef } from "react";
import { useEffect } from "react";
import { useState } from "react";
import ChevronRightIcon from "../../assets/chevron-right.svg";
import ChevronLeftIcon from "../../assets/chevron-left.svg";

const TopicContainerCustom = ({ topicLabels, selected, setSelected, setPagination }) => {
  // Reference to the scrollable container

  const scrollContainerRef = useRef(null);
  const [showLeftArrow, setShowLeftArrow] = useState(false);
  const [showRightArrow, setShowRightArrow] = useState(true);

  const handleScroll = () => {
    if (scrollContainerRef.current) {
      const { scrollLeft, scrollWidth, clientWidth } = scrollContainerRef.current;

      setShowLeftArrow(scrollLeft > 0);
      setShowRightArrow(scrollLeft + clientWidth < scrollWidth);
    }
  };

  const scrollLeft = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: -200, behavior: "smooth" });
    }
  };

  const scrollRight = () => {
    if (scrollContainerRef.current) {
      scrollContainerRef.current.scrollBy({ left: 200, behavior: "smooth" });
    }
  };

  const handleTopicSelected = (value) => {
    setSelected({ ...selected, topic: value });
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1
    }));
  };
  const updateButtonVisibility = () => {
    if (scrollContainerRef.current) {
      const { scrollWidth, clientWidth } = scrollContainerRef.current;
      setShowRightArrow(scrollWidth > clientWidth);
    }
  };

  // Add scroll event listener to check scroll position
  useEffect(() => {
    const container = scrollContainerRef.current;
    if (container) {
      container.addEventListener("scroll", handleScroll);

      handleScroll();

      return () => {
        container.removeEventListener("scroll", handleScroll);
      };
    }
  }, []);

  // Check initial overflow state and on window resize
  useEffect(() => {
    updateButtonVisibility();

    window.addEventListener("resize", updateButtonVisibility);
    return () => {
      window.removeEventListener("resize", updateButtonVisibility);
    };
  }, [topicLabels]);

  return (
    <div className="relative">
      {showLeftArrow && (
        <button
          onClick={scrollLeft}
          className="absolute font-bold bg-white top-0 left-[-1px] z-10 h-[40px] pr-[8px]"
        >
          <img src={ChevronLeftIcon} alt="" />
        </button>
      )}

      {/* Scrollable Topic Container */}
      <div className="overflow-x-auto scrollbar-hide" ref={scrollContainerRef}>
        <div className="flex items-center gap-1 md:gap-4">
          {topicLabels?.map((item, index) => (
            <p
              key={index}
              className={`rounded-[80px] cursor-pointer font-[800] whitespace-nowrap text-sm px-[14px] py-[8.5px] ${
                selected.topic === item.id
                  ? "bg-[#204A22] text-[#EDF7EE]"
                  : "bg-[#C8E6C9] text-[#204A22]"
              }`}
              onClick={() => {
                handleTopicSelected(item.id);
              }}
            >
              {item.name}
            </p>
          ))}
        </div>
      </div>

      {showRightArrow && (
        <button
          onClick={scrollRight}
          className="absolute font-bold bg-white top-[-1px] right-[-2px] z-10 h-[40px] pl-[8px]"
        >
          <img src={ChevronRightIcon} alt="" />
        </button>
      )}
    </div>
  );
};

export default TopicContainerCustom;
