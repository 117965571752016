import IntroComponent from "../components/intro.components";
import { ResponseTableComponent } from "../components/response-table.component";

const ResponseComponent = () => {
  return (
    <div className="flex flex-col gap-6">
      <IntroComponent
        title="Content submissions"
        des="Manage your submissions to people’s requests"
      />
      <ResponseTableComponent></ResponseTableComponent>
    </div>
  );
};

export default ResponseComponent;
