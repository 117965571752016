import { Box, Text } from "@chakra-ui/react";
import { useState } from "react";
import CloseGreenIcon from "../../assets/close-green-icon.svg";
import CloseRedIcon from "../../assets/close-red-icon.svg";
import TickIcon from "../../assets/tick-edit-icon.svg";
import InformRedIcon from "../../assets/inform-red-icon.svg";
import { TOAST_FAIL_BG, TOAST_SUCCESS_BG } from "../../constants/toast.constant";

const ToastBtnMessage = (props) => {
  const { color, title, isError, btnTitle, btnAction } = props;
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Box
      borderRadius={"8px"}
      py="10px"
      px="16px"
      bg={isError ? TOAST_FAIL_BG : TOAST_SUCCESS_BG}
      display={isOpen ? "none" : ""}
    >
      <div className="grid grid-rows-[auto,20px]">
        <div className="grid grid-cols-[24px,auto,24px] gap-[4px]">
          <img src={isError ? InformRedIcon : TickIcon} width={"24px"} alt="" />
          <Text color={isError ? "#DE1414" : color} fontWeight={600}>
            {title}
          </Text>
          <img
            src={isError ? CloseRedIcon : CloseGreenIcon}
            onClick={() => setIsOpen(!isOpen)}
            className="m-auto cursor-pointer"
            alt=""
            width={"24px"}
          />
        </div>
        <div className="grid grid-cols-[24px,auto,24px] gap-[4px]">
          <span></span>
          <Text
            _hover={{ cursor: "pointer" }}
            color={"#4CAF50"}
            fontWeight={800}
            onClick={btnAction}
          >
            {btnTitle}
          </Text>
          <span></span>
        </div>
      </div>
    </Box>
  );
};

export default ToastBtnMessage;
