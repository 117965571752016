import { useEffect } from "react";
import { fetchListTopic } from "../../../../apis/discovery.api";
import { useState } from "react";
import TopicContainerCustom from "../../../TopicContainerCustom";
import Cookies from "js-cookie";

const staging = process.env.REACT_APP_IS_STAGING;

const ListFilter = ({ selected, setSelected, setPagination }) => {
  const [listTopic, setListTopic] = useState([]);
  const token = Cookies.get(`${staging ? "staging" : ""}token`);

  const filterListItem = [
    {
      name: "For You",
      value: "forYou",
      isHidden: !token
    },
    {
      name: "Trending",
      value: "trending"
    },
    {
      name: "Breaking News",
      value: "breaking"
    },
    {
      name: "Following",
      value: "following",
      isHidden: !token
    },
    {
      name: "My Reading List",
      value: "myReading",
      isHidden: !token
    }
  ];

  const handleSelect = (value) => {
    if (selected.type === "myReading") {
      setSelected({ ...selected, type: value, topic: 1 });
    } else {
      setSelected({ ...selected, type: value });
    }
    setPagination((prevPagination) => ({
      ...prevPagination,
      page: 1
    }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await fetchListTopic();
        if (result) {
          setListTopic(result);
        }
      } catch (error) {
        console.error(error);
      }
    };
    fetchData();
  }, []);

  return (
    <div
      className={`sticky top-[52px] md:top-[92px] w-full px-5 md:px-0 flex flex-col gap-3 justify-end bg-white z-20`}
    >
      <div className="flex gap-[20px] pb-3 md:pb-0 overflow-x-auto w-full pt-5 md:pt-10">
        {filterListItem?.map((item, index) => (
          <div
            key={index}
            onClick={() => handleSelect(item.value)}
            className={`${item.isHidden ? "hidden" : ""} px-[12px] py-[4px] md:p-[12px] ${
              selected.type === item.value ? "bg-[#4CAF50] !text-white" : "!text-[#4CAF50]"
            } cursor-pointer border-[2px] border-solid border-[#4CAF50] rounded-[8px] text-sm font-extrabold whitespace-nowrap`}
          >
            {item.name}
          </div>
        ))}
      </div>
      {selected.type === "myReading" && (
        <TopicContainerCustom
          topicLabels={listTopic}
          selected={selected}
          setSelected={setSelected}
          setPagination={setPagination}
        />
      )}
    </div>
  );
};

export default ListFilter;
