import { createBrowserRouter, RouterProvider } from "react-router-dom";

import "./App.css";
import { Helmet } from "react-helmet";
import { odeleLightTheme } from "./theme";
import { ChakraProvider } from "@chakra-ui/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import NewsletterLayout from "./components/Layouts/NewsletterLayout";
import DiscoveryPage from "./routes/Discovery";
import Settings from "./routes/Settings";
import NotificationSettings from "./routes/NotificationSettings";
import WriterLayout from "./components/Layouts/WriterLayout";
import CreateArticle from "./routes/CreateArticle";
import PageNotFound from "./routes/NotFound";
import SearchResult from "./routes/SearchResult";
import ManageArticle from "./routes/ManageArticles";
import RecommendTopicsPage from "./routes/RecommendTopics";
import EditArticle from "./routes/EditArticle";
import BreakingNewsPage from "./routes/BreakingNews";
import FeaturedVoicesPage from "./routes/FeaturedVoices";
import CreateRequest from "./routes/CreateRequest";
import DetailRequest from "./routes/DetailRequest";
import { checkAuth } from "./components/Auth/checkAuth";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false // default: true
    }
  }
});

const router = createBrowserRouter([
  {
    element: <NewsletterLayout />, // Nested layout for newsletter-related routes
    children: [
      { path: "/", element: <DiscoveryPage /> },
      { path: "/recommended-topics", element: <RecommendTopicsPage /> },
      { path: "/breaking-news", element: <BreakingNewsPage /> },
      { path: "/featured-voices", element: <FeaturedVoicesPage /> },
      { path: "/account-settings", element: <Settings />, loader: checkAuth },
      { path: "/notification-settings", element: <NotificationSettings />, loader: checkAuth },
      { path: "/search", element: <SearchResult /> }
    ]
  },
  {
    element: <WriterLayout />, // Nested layout for writer-related routes
    children: [
      { path: "/article/create", element: <CreateArticle />, loader: checkAuth },
      { path: "/article/edit", element: <EditArticle />, loader: checkAuth },
      { path: "/article/manage", element: <ManageArticle />, loader: checkAuth }
    ]
  },
  {
    element: <WriterLayout />, // Nested layout for writer-related routes
    children: [
      { path: "/request/create", element: <CreateRequest />, loader: checkAuth },
      { path: "/request/:id/detail", element: <DetailRequest />, loader: checkAuth }
    ]
  },
  { path: "*", element: <PageNotFound /> } // Fallback route for 404
]);

function App() {
  return (
    <>
      <Helmet>
        <link rel="icon" href="/favicon.png" />
        <meta http-equiv="Accept-CH" content="DPR, Viewport-Width, Width" />
      </Helmet>

      <QueryClientProvider client={queryClient}>
        <ChakraProvider theme={odeleLightTheme}>
          {/* This will handle all routing, layouts, and components */}
          <RouterProvider router={router} />
        </ChakraProvider>
      </QueryClientProvider>
    </>
  );
}

export default App;
