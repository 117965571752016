import { useEffect, useRef, useState } from "react";
import {
  Box,
  Button,
  Checkbox,
  Image,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Text,
  useDisclosure
} from "@chakra-ui/react";
import { useFormContext } from "react-hook-form";
import { CircleFlag } from "react-circle-flags";
import { FieldHookForm } from "../hook-form/field-hook-form";
import { ReactComponent as ArrowDownIcon } from "../../../../assets/arrow-down-icon.svg";

const MenuHookForm = (props) => {
  const {
    data,
    description,
    label,
    name,
    isOnKeyword,
    defaultValue,
    isOptional,
    isFetching,
    isShowIcon,
    isShowFlag,
    isShowCheckBox,
    nameDisplay,
    nameValue,
    isDisabled,
    minH,
    iconMenuButton,
    placeholder,
    colorDes,
    ...rest
  } = props;

  const {
    setValue,
    formState: { errors },
    register,
    clearErrors
  } = useFormContext();

  const [menuData, setMenuData] = useState(isShowCheckBox ? [] : null);
  const [menuListWidth, setMenuListWidth] = useState(0);
  const menuButtonRef = useRef();
  const [isHiddenError, setHiddenError] = useState(false);

  const { isOpen, onToggle, onClose } = useDisclosure();

  const onChangeValue = (event, value) => {
    if (event.target.tagName === "INPUT") return;
    if (isShowCheckBox) {
      const isSelected = menuData.some((item) => item.id === value.id);
      const updatedMenuData = isSelected
        ? menuData.filter((item) => item.id !== value.id)
        : [...menuData, value];
      setHiddenError(updatedMenuData?.length > 0 ? true : false);
      setMenuData(updatedMenuData);
      setValue(name, updatedMenuData);
    } else {
      setMenuData(value);
      setValue(name, nameValue ? value[nameValue] : value.id);
      onClose();
    }
  };

  useEffect(() => {
    const buttonWidth = menuButtonRef.current.offsetWidth;
    setMenuListWidth(buttonWidth);
  }, []);

  useEffect(() => {
    if (defaultValue) {
      if (isShowCheckBox && Array.isArray(defaultValue)) {
        setMenuData(defaultValue);
        setValue(name, defaultValue);
      } else {
        setMenuData(defaultValue);
        setValue(name, nameValue ? defaultValue[nameValue] : defaultValue.id);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue]);

  return (
    <Box w={"100%"}>
      <FieldHookForm
        name={name}
        description={description}
        label={label}
        colorDes={colorDes}
        isOptional={isOptional}
        isHiddenError={isHiddenError}
      >
        <Menu
          isOpen={isOpen}
          onClose={onClose}
          closeOnSelect={false}
          {...(!isOnKeyword
            ? register(name, {
                onChange: () => (errors[name] ? clearErrors(name) : null)
              })
            : null)}
        >
          <MenuButton
            onClick={onToggle}
            ref={menuButtonRef}
            as={Button}
            rightIcon={
              <ArrowDownIcon
                className={`w-[20px] h-[20px] transition duration-300 ${isOpen ? "transform rotate-180" : "transform rotate-0"}`}
                alt=""
              />
            }
            fontWeight={"400"}
            w={"full"}
            maxHeight={"fit-content"}
            textAlign={"start"}
            _active={{ bgColor: "gray.200" }}
            _hover={{ bgColor: "light.200" }}
            p={{ base: "12px", "2xl": "17px 24px" }}
            rounded={"0.75rem"}
            bg="unset"
            borderWidth={"1px"}
            borderColor={errors?.[name] && !isHiddenError ? "other.200" : "light-gray"}
            fontSize={{ base: "sm", "2xl": "md" }}
            lineHeight={"1.25rem"}
            whiteSpace={"normal"}
            overflowWrap={"break-word"}
            {...rest}
          >
            <Box
              display="flex"
              flexDirection="column"
              gap="10px"
              fontSize={{ base: "sm", "2xl": "md" }}
            >
              {iconMenuButton && <Image src={iconMenuButton} alt="" mr={"0.5rem"} />}
              {isShowIcon && !isShowCheckBox && <Image src={menuData?.icon.src} alt="" />}

              {menuData && isShowCheckBox ? (
                menuData.length > 0 ? (
                  menuData.map((item) => item[nameDisplay] ?? item.name).join(", ")
                ) : (
                  <Text color="dim-gray">{placeholder ?? ""}</Text>
                )
              ) : menuData ? (
                nameDisplay ? (
                  menuData[nameDisplay]
                ) : (
                  menuData.name
                )
              ) : (
                <Text color="dim-gray">{placeholder ?? ""}</Text>
              )}
            </Box>
          </MenuButton>
          <MenuList
            p={"0.625rem"}
            shadow={"md"}
            border={"1px solid light-gray"}
            borderRadius={"8px"}
            display={"flex"}
            flexDir={"column"}
            gap={"4px"}
            minW={menuListWidth}
            w={"auto"}
            maxH={{ base: "250px", "2xl": "300px" }}
            overflow={"auto"}
            css={{
              "&::-webkit-scrollbar": { width: "6px" },
              "&::-webkit-scrollbar-track": { width: "6px" },
              "&::-webkit-scrollbar-thumb": { background: "#c6ceda", borderRadius: "20px" }
            }}
          >
            {data?.map((elm, index) => {
              const isSelected = menuData.some((item) => item.id === elm.id);
              return (
                <MenuItem
                  isDisabled={isDisabled}
                  key={index}
                  onClick={(event) => onChangeValue(event, elm)}
                  _hover={{ bgColor: "anti-flash-white" }}
                  rounded={"0.5rem"}
                  py={"8px"}
                  px={"10px"}
                  _focus={{ bgColor: "anti-flash-white" }}
                  fontSize={"0.875rem"}
                  lineHeight={"1.25rem"}
                  fontWeight={"800"}
                >
                  <Box display="flex" gap="10px" fontSize={"sm"}>
                    {isShowIcon && <Image src={elm.icon.src} alt="" />}
                    {isShowFlag && (
                      <CircleFlag
                        countryCode={elm.countryCode.toLowerCase()}
                        width="20px"
                        height="20px"
                      />
                    )}
                    {isShowCheckBox && (
                      <Checkbox size={{ base: "sm", md: "md" }} isChecked={isSelected} />
                    )}
                    {elm ? (nameDisplay ? elm[nameDisplay] : elm.name) : ""}
                  </Box>
                </MenuItem>
              );
            })}
          </MenuList>
        </Menu>
      </FieldHookForm>
    </Box>
  );
};

MenuHookForm.displayName = "MenuHookForm";

export { MenuHookForm };
