import Button from "../../Button";
import IntroComponent from "../components/intro.components";
import ArrowUpDarkGreen from "../../../assets/arrow-up-dark-green-icon.svg";
import ArrowUpLightGreen from "../../../assets/arrow-up-light-green-icon.svg";
import HelpIcon from "../../../assets/help-green-icon.svg";
import { ArticleTableComponent } from "../components/table.component";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@chakra-ui/react";
import { EARNING_TEXT } from "../../../constants/twygs.constant";
import { useQuery } from "@tanstack/react-query";
import { fetchListMyArticles } from "../../../apis/article.api";
import { formartNumber } from "../../../utils/helper.util";

const ArticlesComponent = () => {
  const navigate = useNavigate();
  const params = {
    limit: 1,
    page: 1,
    status: "draft"
  };

  const { data: getRecentArticle } = useQuery({
    queryKey: ["recent-article"],
    queryFn: () => fetchListMyArticles(params)
  });

  const handleContinueWriting = () => {
    navigate(`/article/edit?id=${getRecentArticle?.data[0]?.id}&step=4`);
  };

  return (
    <div className="flex flex-col gap-6">
      <IntroComponent
        title="My Articles"
        des="Manage, edit, and share your articles for engaging online presence"
      />
      <div className="flex items-center gap-5">
        <div className="bg-white p-6 rounded-[12px] border border-[#F2F3F7] shadow-[0px_4px_4px_0px_#F2F3F780]">
          <p className="text-[#204A22]">Recently worked on</p>
          <div className="flex items-center justify-between gap-[18px] mt-2">
            <p
              className="font-[800] max-w-[182px] truncate"
              title={getRecentArticle?.data[0]?.title}
            >
              {getRecentArticle?.data?.length > 0
                ? getRecentArticle?.data[0]?.title
                : "No article yet"}
            </p>
            <Button
              variant="secondary"
              onClick={handleContinueWriting}
              disabled={getRecentArticle?.data?.length === 0}
            >
              Continue writing
              <img className="ml-2" src={ArrowUpDarkGreen} alt="" />
            </Button>
          </div>
        </div>
        <div className="bg-white p-6 rounded-[12px] border border-[#F2F3F7] shadow-[0px_4px_4px_0px_#F2F3F780]">
          <div className="flex items-center gap-2">
            <p className="font-[800]">Earnings</p>
            <Tooltip
              rounded={"0.75rem"}
              label={EARNING_TEXT?.map((item, index) => (
                <p key={index} className="text-[10px]">
                  {item}
                </p>
              ))}
              placement={"top-start"}
              bg={"anti-flash-white"}
              p={{ base: "10px", md: "1rem" }}
              color={"jet-black"}
              fontSize={"10px"}
              maxW={"321px"}
            >
              <img src={HelpIcon} alt="" />
            </Tooltip>
          </div>
          <div className="flex items-center justify-between gap-[18px] mt-2">
            <p className="text-[#204A22] text-[28px] font-[800]">${formartNumber(0, 2)}</p>
            <div
              className="flex gap-2 items-center hover:opacity-80 cursor-pointer"
              onClick={() =>
                window.open(`${process.env.REACT_APP_CREATOR_URL}earnings/my-earnings`)
              }
            >
              <p className="text-[#4CAF50] text-sm font-[900]">For more details</p>
              <img className="fill-[#4CAF50]" src={ArrowUpLightGreen} alt="" />
            </div>
          </div>
        </div>
      </div>
      <ArticleTableComponent />
    </div>
  );
};

export default ArticlesComponent;
