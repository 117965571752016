import moment from "moment-timezone";
import { compile } from "path-to-regexp";
import { classifyDevice } from "../constants";
const staging = process.env.REACT_APP_IS_STAGING;

const CHARACTERS = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

export function generateId(length, prefix) {
  let result = "";

  for (let i = 0; i < length; i++) {
    result += CHARACTERS.charAt(Math.floor(Math.random() * CHARACTERS.length));
  }

  return prefix ? `${prefix}_${result}` : result;
}

export function isInt(n) {
  return Number(n) === n && n % 1 === 0;
}

export function isFloat(n) {
  return Number(n) === n && n % 1 !== 0;
}

export const typeOfValue = (value) =>
  Object.prototype.toString.call(value).slice(8, -1).toLowerCase();

export const convertToAbbreviatedName = (name) => {
  const matches = name.match(/\b(\w)/g);
  let acronym = "";

  if (matches) {
    if (matches) {
      if (matches.length >= 3) {
        acronym = matches[0] + matches[1];
      } else {
        acronym = matches.join("");
      }
    }
  }

  return acronym;
};

export const pathToUrl = (path, params) => compile(path)(params);

export const options = [
  {
    id: 0,
    value: "Too weak",
    minDiversity: 0,
    minLength: 0
  },
  {
    id: 1,
    value: "Weak",
    minDiversity: 2,
    minLength: 6
  },
  {
    id: 2,
    value: "Medium",
    minDiversity: 4,
    minLength: 8
  },
  {
    id: 3,
    value: "Strong",
    minDiversity: 4,
    minLength: 10
  }
];

export const checkPasswordStrength = (password) => {
  // Define regex patterns
  const upperCaseRegex = /(?=.*?[A-Z])(?=.*?[a-z])/;
  const numberRegex = /[0-9]/;
  const specialCharRegex = /[!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?]/;
  const commonWordsRegex = /password|123456|qwerty|admin|letmein|welcome/;

  // Define password requirements
  const requirements = [
    { regex: upperCaseRegex, message: "at least one uppercase letter" },
    // { regex: lowerCaseRegex, message: "at least one lowercase letter" },
    { regex: numberRegex, message: "at least one number" },
    { regex: specialCharRegex, message: "at least one special character" },
    { regex: /^.{8,}$/i, message: "at least 8 characters" }
  ];

  // Check password strength
  let strength = 0;
  let messages = [];
  requirements.forEach((requirement) => {
    if (requirement.regex.test(password)) {
      strength++;
    }
  });

  if (commonWordsRegex.test(password)) {
    messages.push("Password is too common");
  }
  if (password.length === 0) {
    strength = 0;
  }

  return strength;
};

export const listStrength = ["is weak", "is medium", "is good", "is strong"];

export const changeFormatDate = (dateString) => {
  const dateObj = new Date(dateString);
  if (dateObj.toString() !== "Invalid Date") {
    return moment(dateObj).format("Y-MM-DD");
  }
};

export const formatDate = (dateString, convertToday) => {
  if (convertToday && moment(dateString).isSame(moment(), "day")) {
    return "Today " + moment(dateString).format("h:mmA");
  } else {
    return moment(dateString).format("MMM DD, YYYY");
  }
};

export const handle60DayAgo = () => {
  const currentDate = new Date();
  const previous60Days = new Date(currentDate);
  previous60Days.setDate(currentDate.getDate() - 60);
  return previous60Days;
};

export const removeAllCookies = () => {
  // Get the domain from the environment variable
  const domain = process.env.NEXT_PUBLIC_TWYGS_HOST;

  // Get all cookies
  const cookies = document.cookie.split(";");

  // Loop through all cookies and delete them with the specified domain
  for (let i = 0; i < cookies.length; i++) {
    const cookie = cookies[i];
    const eqPos = cookie.indexOf("=");
    const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;

    // Include the domain when deleting the cookie
    document.cookie = `${
      staging ? "staging" : ""
    }${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/;domain=.${domain};`;
  }
};

export const setToken = (res, token) => {
  res.set(`${staging ? "staging" : ""}token`, token, {
    expires:
      (process.env.NEXT_PUBLIC_MAX_AGE_IN_SECONDS
        ? parseInt(process.env.NEXT_PUBLIC_MAX_AGE_IN_SECONDS)
        : 60 * 60 * 24) /
      (60 * 60 * 24),
    domain: process.env.NEXT_PUBLIC_TWYGS_HOST
  });
};

export const getDatesBetween = (rangeDate) => {
  const dates = [];
  const startDate = moment(rangeDate.startDate);
  const endDate = moment(rangeDate.endDate);
  for (let currentDate = moment(startDate); currentDate <= endDate; currentDate.add(1, "days")) {
    dates.push(currentDate.format("MMM DD, Y"));
  }
  return dates;
};

// chain

export const CHAIN_SUPPORT_NAME = JSON.parse(process.env.NEXT_PUBLIC_CHAIN_SUPPORT_NAME ?? "{}");
export const validateChain = JSON.parse(process.env.NEXT_PUBLIC_VALIDATE_CHAIN ?? "[]");
export const CHAIN_SUPPORT_SYMBOL = JSON.parse(
  process.env.NEXT_PUBLIC_CHAIN_SUPPORT_SYMBOL ?? "{}"
);
export const CONTRACT_SUPPORT = JSON.parse(process.env.NEXT_PUBLIC_CONTRACT_SUPPORT ?? "{}");

//
export const formartNumber = (number, minNum, maxNum) => {
  let formattedNumber = number;
  if (typeof number === "string") {
    formattedNumber = parseFloat(number);
  }
  return formattedNumber?.toLocaleString("en-US", {
    style: "decimal",
    useGrouping: true,
    minimumFractionDigits: minNum ?? 0,
    maximumFractionDigits: maxNum ?? 2
  });
};

export const formatNumber = (num, precision = 2) => {
  if (num || num === 0) {
    const map = [
      { suffix: "B", threshold: 1e9 },
      { suffix: "M", threshold: 1e6 },
      { suffix: "K", threshold: 1e3 },
      { suffix: "", threshold: 1 }
    ];

    const found = map.find((x) => Math.abs(num) >= x.threshold);
    if (found) {
      const formatPrecision = found.threshold === 1 || num % found.threshold === 0 ? 0 : precision;

      let formatted;
      if (formatPrecision > 0) {
        formatted =
          (num / found.threshold).toFixed(formatPrecision).replace(/\.?0+$/, "") + found.suffix;
      } else {
        formatted = Math.round(num / found.threshold) + found.suffix;
      }

      return formatted;
    }
    return num.toString();
  } else {
    return 0;
  }
};

export const formatGMT = (date, format) => {
  let fortmattedDate = moment(date).tz("America/New_York");
  if (format) {
    fortmattedDate = fortmattedDate.format(format);
  }
  return fortmattedDate;
};

export const isValidEmail = (value) => {
  if (!value?.trim()) return true;
  const emailPattern = /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return emailPattern.test(value.trim());
};

export const isValidURL = (value) => {
  if (!value) return true;
  const linkPattern = /^(?:(https?:\/\/)?(?:www\.)?)?[\w-]+\.\w{2,}(?:\.\w{2})?$/i;
  return linkPattern.test(value);
};

export const isValidPassword = (value) => {
  if (!value) return false;
  const passwordPattern =
    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[a-zA-Z])(?=.*[!@#$%^&*()_+}{"':;?/>.<,])(?!.*\s).{8,64}$/g;
  return passwordPattern.test(value);
};

export const isValidUsername = (value) => {
  if (!value) return false;
  // allow space in username
  const usernamePattern = /^[a-zA-Z ]*$/;
  return usernamePattern.test(value);
};

export const downloadCSV = (response) => {
  const csvData = response?.data;
  const csvContent = `data:text/csv;charset=utf-8,${encodeURI(csvData)}`;

  // Get the filename from the response headers
  const contentDisposition = response.headers["content-disposition"];
  const filenameMatch = contentDisposition.match(/filename=(.+)$/);
  let filename = "file.csv"; // Default filename if not found in headers
  if (filenameMatch) {
    filename = filenameMatch[1];
  }
  const tempLink = document.createElement("a");
  tempLink.setAttribute("href", csvContent);
  tempLink.setAttribute("download", filename);
  tempLink.click();
};

export const isServer = () => typeof window === "undefined";

export const isValidInputNumber = (value) => {
  return /^\d{0,12}(\.\d{0,12})?$/.test(value);
};

export const getTimeZoneOffset = () => {
  const offset = new Date().getTimezoneOffset();
  const sign = offset > 0 ? "-" : "";
  return `${sign}${Math.abs(offset / 60)}`;
};

export const formatTextWithHashtags = (text) => {
  // Regular expression to match hashtags
  const hashtagRegex = /#(\w+)/g;

  // Split text into an array of strings and matches
  const parts = text.split(hashtagRegex);

  return parts.map((part, index) => {
    // Check if the part is a hashtag by matching the index
    if (index % 2 === 1) {
      return (
        // replace span by a tag
        <a
          key={index}
          className="!text-[#012bc2] font-bold !font-montserrat"
          href={`/?search=${encodeURIComponent(`#${part}`)}`}
        >
          #{part}
        </a>
      );
    }
    // Return the non-hashtag text as-is
    return part;
  });
};

export const isMobile = () => classifyDevice() === "Mobile";
export const isDesktop = () => classifyDevice() === "Desktop";
