import { useEffect, useRef, useState } from "react";
import { Controller, useFormContext, useWatch } from "react-hook-form";
import { Box, Spinner, Textarea } from "@chakra-ui/react";
import styled from "@emotion/styled";
import { useDebounce } from "use-debounce";
import { useAtom } from "jotai";
import { hashtagAtom } from "./atom";
import Cookies from "js-cookie";
import { FieldHookForm } from "../hook-form/field-hook-form";

const Wrapper = styled.div`
  position: relative;
  width: 100%;
`;

const Dropdown = styled.div`
  position: absolute;
  background-color: white;
  border-radius: 12px;
  width: 100%;
  max-height: 296px;
  ::-webkit-scrollbar {
    width: 8px;
  }
  ::-webkit-scrollbar-track {
    background: none;
  }
  ::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background: #e6e7eb;
  }
  ::-webkit-scrollbar-thumb:hover {
    opacity: 0.8;
  }
  overflow: auto;
  z-index: 2;
  padding: 10px;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 12px 0px #80808026;
`;

const DropdownItem = styled.div`
  border-radius: 8px;
  padding: 8px;
  font-size: 14px;
  font-weight: 800;
  cursor: pointer;
  background-color: ${({ active }) => (active ? "#e6e7eb" : "transparent")};

  &:hover {
    background-color: #e6e7eb;
  }
`;

export const GET_HASHTAG = `${process.env.REACT_APP_TWYGS_API_URL}articles/hashtags` || "";

const TextAreaCustomHashtag = (props) => {
  const {
    description,
    label,
    name,
    fontSizeError,
    isHiddenBottomContent,
    bottomContentProps,
    isHiddenError,
    bottomRightContent,
    isOptional,
    colorDes,
    placeholder,
    ...rest
  } = props;

  const {
    formState: { errors },
    control,
    setValue: setFormValue
  } = useFormContext();

  const [hashtag, setHashtag] = useAtom(hashtagAtom);

  const __watch_value = useWatch({
    name,
    control,
    defaultValue: hashtag.join(" ")
  });
  const [debouncedValue] = useDebounce(__watch_value, 500);
  const [loading, setLoading] = useState(false);
  const [suggestions, setSuggestions] = useState([]);
  const [activeSuggestionIndex, setActiveSuggestionIndex] = useState(-1);
  const textareaRef = useRef(null);
  const dropdownRef = useRef(null);
  const suggestionRefs = useRef([]);

  useEffect(() => {
    const extractHashtags = (text) => {
      if (!text) return [];
      return text
        .trim()
        .split(" ")
        .map((word) => (word.startsWith("#") ? word.slice(1) : word)); // Remove the leading # if it exists
    };

    setHashtag(extractHashtags(__watch_value));
  }, [debouncedValue, setHashtag]);

  const adjustHeight = () => {
    if (textareaRef.current) {
      textareaRef.current.style.height = "";
      const newHeight = Math.min(textareaRef.current.scrollHeight + 1, 300);
      textareaRef.current.style.height = `${newHeight}px`;
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);
    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    const searchForHashtags = async () => {
      const lastPart = debouncedValue.split(/\s+/).pop();
      const isTypingHashtag = lastPart && !/\s/.test(lastPart);
      if (isTypingHashtag) {
        setLoading(true);
        try {
          const apiUrl = `${GET_HASHTAG}?page=1&limit=1000&search=${encodeURIComponent(lastPart)}`;
          const response = await fetch(apiUrl, {
            headers: {
              Authorization: `Bearer ${Cookies.get(
                `${process.env.NEXT_PUBLIC_IS_STAGING ? "staging" : ""}token`
              )}`,
              "Content-Type": "application/json"
            }
          });
          const data = await response.json();
          setSuggestions(data?.data?.hashtags);
          setActiveSuggestionIndex(-1);
          setLoading(false);
        } catch (error) {
          setLoading(false);
          console.error("Error searching for hashtags:", error);
        }
      } else {
        setLoading(false);
        setSuggestions([]);
        setActiveSuggestionIndex(-1);
      }
    };

    searchForHashtags();
  }, [debouncedValue]);

  const scrollToActiveSuggestion = () => {
    const activeSuggestion = suggestionRefs.current[activeSuggestionIndex];
    const dropdown = dropdownRef.current;

    if (activeSuggestion && dropdown) {
      const dropdownRect = dropdown.getBoundingClientRect();
      const suggestionRect = activeSuggestion.getBoundingClientRect();

      if (suggestionRect.bottom > dropdownRect.bottom) {
        dropdown.scrollTop += suggestionRect.bottom - dropdownRect.bottom;
      } else if (suggestionRect.top < dropdownRect.top) {
        dropdown.scrollTop -= dropdownRect.top - suggestionRect.top;
      }
    }
  };

  const handleSuggestionClick = (suggestion) => {
    const parts = __watch_value.split(/\s+/); // Split the input into words
    parts.pop(); // Remove the last word (the one currently being typed)

    parts.push(`#${suggestion}`); // Insert the selected suggestion in place of the last word
    // Join the parts back into a single string and add a space after the suggestion
    const newValue = parts.join(" ").trim() + " ";

    // Update the form value with the new string, validate, and reset suggestions
    setFormValue(name, newValue);
    setSuggestions([]);
    setActiveSuggestionIndex(-1);
    adjustHeight(); // Adjust the textarea height after updating the content
  };

  const handleKeyDown = (e) => {
    if (suggestions.length === 0) return;
    if (e.key === "ArrowDown") {
      e.preventDefault();
      setActiveSuggestionIndex((prevIndex) => {
        const newIndex = prevIndex < suggestions.length - 1 ? prevIndex + 1 : 0;
        scrollToActiveSuggestion();
        return newIndex;
      });
    } else if (e.key === "ArrowUp") {
      e.preventDefault();
      setActiveSuggestionIndex((prevIndex) => {
        const newIndex = prevIndex > 0 ? prevIndex - 1 : suggestions.length - 1;
        scrollToActiveSuggestion();
        return newIndex;
      });
    } else if (e.key === "Enter") {
      e.preventDefault();
      if (activeSuggestionIndex >= 0 && activeSuggestionIndex < suggestions.length) {
        handleSuggestionClick(suggestions[activeSuggestionIndex].name);
      }
    } else if (e.key === "Escape") {
      setSuggestions([]);
      setActiveSuggestionIndex(-1);
    }
  };

  const handleInputChange = (e) => {
    const newValue = e.target.value;
    setFormValue(name, newValue);
    adjustHeight();
  };

  return (
    <Controller
      name={name}
      control={control}
      render={({ field }) => (
        <FieldHookForm
          name={name}
          description={description}
          label={label}
          fontSizeError={fontSizeError}
          isHiddenBottomContent={isHiddenBottomContent}
          bottomContentProps={bottomContentProps}
          isHiddenError={isHiddenError}
          isOptional={isOptional}
          bottomRightContent={bottomRightContent}
          colorDes={colorDes}
        >
          <Wrapper>
            <Textarea
              {...field}
              ref={(e) => {
                textareaRef.current = e;
                field.ref(e);
              }}
              minHeight={"unset"}
              value={__watch_value}
              onChange={handleInputChange}
              onKeyDown={handleKeyDown}
              maxHeight={{ base: "200px", "2xl": "300px" }}
              p={"1.125rem 1.5rem"}
              rounded={"0.75rem"}
              resize={"none"}
              borderWidth={"1px"}
              borderColor={errors?.[name] ? "other.200" : "light-gray"}
              color={"jet-black"}
              bg={"unset"}
              placeholder={placeholder}
              _placeholder={{
                color: "dim-gray",
                fontSize: { base: "sm", "2xl": "md" }
              }}
              fontSize={{ base: "sm", "2xl": "md" }}
              lineHeight={"unset"}
              name={name}
              _hover={{
                borderColor: errors?.[name] ? "other.200 " : "inherit"
              }}
              _focus={{
                boxShadow: "none"
              }}
              _focusVisible={{
                borderColor: errors?.[name] ? "other.200" : "twygs-green",
                borderWidth: "1.5px"
              }}
              {...rest}
            />
            {suggestions?.length > 0 && !loading && (
              <Dropdown ref={dropdownRef}>
                {suggestions.map((suggestion, index) => (
                  <DropdownItem
                    key={suggestion.id}
                    active={index === activeSuggestionIndex}
                    onClick={() => handleSuggestionClick(suggestion.name)}
                    ref={(el) => (suggestionRefs.current[index] = el)}
                  >
                    {`#${suggestion.name}`}
                  </DropdownItem>
                ))}
              </Dropdown>
            )}
            {loading && (
              <Box
                position={"absolute"}
                bgColor={"white"}
                borderRadius={"0.75rem"}
                w={"100%"}
                h={"100px"}
                zIndex={2}
                padding={"10px"}
                border={"1px solid #f2f3f7"}
                boxShadow={"0px 8px 12px 0px #80808026"}
                display={"flex"}
                justifyContent={"center"}
                alignItems={"center"}
              >
                <Spinner size="sm" />
              </Box>
            )}
          </Wrapper>
        </FieldHookForm>
      )}
    ></Controller>
  );
};

TextAreaCustomHashtag.displayName = "TextAreaCustomHashtag";

export { TextAreaCustomHashtag };
